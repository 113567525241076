"use strict";
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMergedClasses = exports.mergeClasses = void 0;
const objectKeys_1 = require("./tools/objectKeys");
const getDependencyArrayRef_1 = require("./tools/getDependencyArrayRef");
const cssAndCx_1 = require("./cssAndCx");
const react_1 = require("react");
function mergeClasses(classesFromUseStyles, classesFromProps, cx) {
    //NOTE: We use this test to be resilient in case classesFromProps is not of the expected type.
    if (!(classesFromProps instanceof Object)) {
        return classesFromUseStyles;
    }
    const out = {};
    (0, objectKeys_1.objectKeys)(classesFromUseStyles).forEach(ruleName => (out[ruleName] = cx(classesFromUseStyles[ruleName], classesFromProps[ruleName])));
    (0, objectKeys_1.objectKeys)(classesFromProps).forEach(ruleName => {
        if (ruleName in classesFromUseStyles) {
            return;
        }
        const className = classesFromProps[ruleName];
        //...Same here, that why we don't do className === undefined
        if (typeof className !== "string") {
            return;
        }
        out[ruleName] = className;
    });
    return out;
}
exports.mergeClasses = mergeClasses;
function useMergedClasses(classes, classesOv) {
    const { cx } = (0, cssAndCx_1.useCssAndCx)();
    return (0, react_1.useMemo)(() => mergeClasses(classes, classesOv, cx), [classes, (0, getDependencyArrayRef_1.getDependencyArrayRef)(classesOv), cx]);
}
exports.useMergedClasses = useMergedClasses;
