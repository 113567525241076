"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PoolList = exports.getPool = exports.getPoolList = void 0;
// Copyright © 2022 LIFINITY FOUNDATION All Rights Reserved.
function getPoolList() {
    return exports.PoolList;
}
exports.getPoolList = getPoolList;
;
function getPool(fromMint, toMint) {
    const pools = Object.values(exports.PoolList).filter(pool => ((pool.poolCoinMint === fromMint && pool.poolPcMint === toMint) || (pool.poolCoinMint === toMint && pool.poolPcMint === fromMint)));
    if (pools.length === 1) {
        return pools[0];
    }
    else {
        return null;
    }
}
exports.getPool = getPool;
exports.PoolList = {
    "SOL-USDC": {
        "amm": "amgK1WE8Cvae4mVdj4AhXSsknWsjaGgo1coYicasBnM",
        "poolMint": "3WzrkFYq4SayCrhBw8BgsPiTVKTDjyV6wRqP7HL9Eyyw",
        "feeAccount": "AD5DFr1AXMB9h6fw5KFtkEfwf7kYSAiaSueeu4NGrLKY",
        "configAccount": "2iT9h99mhDqetoZGNj7KKrqBnoDmFvAytGrnFYuR7MwN",
        "pythAccount": "H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG",
        "pythPcAccount": "H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG",
        "poolCoinTokenAccount": "2uySTNgvGT2kwqpfgLiSgeBLR3wQyye1i1A2iQWoPiFr",
        "poolCoinMint": "So11111111111111111111111111111111111111112",
        "poolPcTokenAccount": "32SjGNjesiCZgmZb4YxAGgjnym6jAvTWbqihR4CvvXkZ",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 9,
        "poolPcDecimal": 6,
        "poolMintDecimal": 9,
        "pythBaseDecimal": 11,
    },
    "SOL-USDT": {
        "amm": "2x8Bmv9wj2a4LxADBWKiLyGRgAosr8yJXuZyvS8adirK",
        "poolMint": "BRchiwrv9yCr4jAi6xF4epQdtNtmJH93rrerpHpMhK1Z",
        "feeAccount": "GFj8cNTP4mzWG7ywyJ35Ls2V8CbqDk3p4xNT1pAawoCh",
        "configAccount": "Hor7j9oYfNH6EJgmnXQRiQSahduR5p4bfKyCZaQUqNKd",
        "pythAccount": "H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG",
        "pythPcAccount": "3vxLXJqLqF3JG5TCbYycbKWRBbCJQLxQmBGCkyqEEefL",
        "poolCoinTokenAccount": "5pH2DBMZg7y5bN4J3oLKRETGXyVYPJpeaCH6AkdAcxqp",
        "poolCoinMint": "So11111111111111111111111111111111111111112",
        "poolPcTokenAccount": "7Cct2MJUwruQef5vQrP2bxYCNyVajJ3SiC1GYUmwmjUm",
        "poolPcMint": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
        "poolCoinDecimal": 9,
        "poolPcDecimal": 6,
        "poolMintDecimal": 9,
        "pythBaseDecimal": 11,
    },
    "SOL-UST": {
        "amm": "65E23qmpbcq3EynyxzSAk5PmAXv4NYmRb2jJiYDDRCaB",
        "poolMint": "4aUVFHRR3c3Zyzi4yeT4U38x1hsPWE8VstKWAXkpgE7g",
        "feeAccount": "Dyr7rzzrZbyHwHmx5YC4xcfzRJpuxDPmWu8BmwNzkAL4",
        "configAccount": "5CmmN7dYxsmP88dE1eATYJc1SjKQXhZFs1FeDSdq5s2C",
        "pythAccount": "H6ARHf6YXhGYeQfUzQNGk6rDNnLBQKrenN712K4AQJEG",
        "pythPcAccount": "H8DvrfSaRfUyP1Ytse1exGf7VSinLWtmKNNaBhA4as9P",
        "poolCoinTokenAccount": "AgiA6x7T6vR2J8ZPeBGizWruwhP6HBbpoW3Hg5jh2f2b",
        "poolCoinMint": "So11111111111111111111111111111111111111112",
        "poolPcTokenAccount": "3eM8HhW5XQeEmshqSvNSmrPmYuBKZpPXVVfzvqgYvJAv",
        "poolPcMint": "9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i",
        "poolCoinDecimal": 9,
        "poolPcDecimal": 6,
        "poolMintDecimal": 9,
        "pythBaseDecimal": 11,
    },
    "BTC-USDC": {
        "amm": "HeH3s7B3a6nynim1rBGS6TRaYECgSNjt7Kp65mhW9P4k",
        "poolMint": "BzuTSoWFHrnRQvn4sr5ErPQyMaRB9g2rsbKCruGtcvMa",
        "feeAccount": "5HpNeHBBpg6x7fzTgbvP9UukQmDmvxbggwqo951BYkba",
        "configAccount": "HuLmRVTfYjNYYGBpPtJEk7JKkosbbPF4zzBHnf3TfyCn",
        "pythAccount": "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU",
        "pythPcAccount": "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU",
        "poolCoinTokenAccount": "FAFShq3gZYXWtk5EkeKPKcwSkz2rjfMDuD1i7KiYwjVM",
        "poolCoinMint": "9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E",
        "poolPcTokenAccount": "3ReY1xscSAEV9Qg1NshkU4KRWQs33nu5JMg8AnoU7duG",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 6,
        "poolPcDecimal": 6,
        "poolMintDecimal": 9,
        "pythBaseDecimal": 8,
    },
    "ETH-USDC": {
        "amm": "E32Z6DYwJELMTrVJVchN8PWbyhSoC3bRorMb7Cw2R9Xz",
        "poolMint": "8FxRyaE8X6ENLmNbaBvgS6vMsN1GJ8J7CmKy8K8uN6wM",
        "feeAccount": "5yXQ399ti5rKMcRMAZvFUqAgKHUP55bvhoYWd9bVrnu9",
        "configAccount": "5JXrQpWAPNrvVN1R6Mz9MhA1EYUB948kceZjCxRzQzf5",
        "pythAccount": "JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB",
        "pythPcAccount": "JBu1AL4obBcCMqKBBxhpWCNUt136ijcuMZLFvTP7iWdB",
        "poolCoinTokenAccount": "BRFwAToCofwzP29jVGzb6VZ4AGpw867AE5VsXfMsmEGk",
        "poolCoinMint": "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
        "poolPcTokenAccount": "FDCjDSbFCVRVBsWkJWfgZ9x3Dizm1MJjtzYw3R2fxXRv",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 8,
        "poolPcDecimal": 6,
        "poolMintDecimal": 9,
        "pythBaseDecimal": 10,
    },
    "RAY-USDC": {
        "amm": "FcxHANr1dguexPZ2PoPGBajgiednXFMYHGGx4YMgedkM",
        "poolMint": "HUpvKUafPCMwhua6QtHXk1V8D6LZYyQmUKYPFZgRiiiX",
        "feeAccount": "DyR91PiiRopbdcizbjdXejodjxEeVSs4uCkyhL7wCvxw",
        "configAccount": "2EXv6K3cYDMXXKFfzGjqnjkbngUymnVwBoC4kwrCKwFy",
        "pythAccount": "AnLf8tVYCM816gmBjiy8n53eXKKEDydT5piYjjQDPgTB",
        "pythPcAccount": "AnLf8tVYCM816gmBjiy8n53eXKKEDydT5piYjjQDPgTB",
        "poolCoinTokenAccount": "BhG9r4CkTBRtpLtxA8Hd72vCkikqyVhiq8pFunZNERV8",
        "poolCoinMint": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
        "poolPcTokenAccount": "8HAVXU7bdS2SEkkrqFBdWPFxFTrWxtu4GTjP46BDzdTc",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 6,
        "poolPcDecimal": 6,
        "poolMintDecimal": 6,
        "pythBaseDecimal": 8,
    },
    "SRM-USDC": {
        "amm": "7RM8pzbWmGEYJLFuyS5uDyrkd4phcazHppn1C7Qim5nT",
        "poolMint": "DKxkNu5PYoBEWiEZzD9hPsbga145AUZFfRCsieJQbGCP",
        "feeAccount": "7xGiGPPFTiroce8ivKeLgH74WvMbinLqHUTMWwdsSXpp",
        "configAccount": "CuPQhoTH29d5tP9TE2KQMrXqhrMD9ygNhaWDke1fuU7d",
        "pythAccount": "3NBReDRTLKMQEKiLD5tGcx4kXbTf88b7f2xLS9UuGjym",
        "pythPcAccount": "3NBReDRTLKMQEKiLD5tGcx4kXbTf88b7f2xLS9UuGjym",
        "poolCoinTokenAccount": "2qAG2xw2sroQZfRUu5RhvneFm35p9NEtcpJizoFYMn2w",
        "poolCoinMint": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
        "poolPcTokenAccount": "DTmnsxurn7cFSqPMERogJDMKR5NbFjhrJTtFPJbgkW7e",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 6,
        "poolPcDecimal": 6,
        "poolMintDecimal": 6,
        "pythBaseDecimal": 8,
    },
    "UXD-USDC": {
        "amm": "5BJUhcBnysAmCpaU6pABof7FUqxx7ZnCZXbctpP48o3C",
        "poolMint": "DM2Grhnear76DwNiRUSfeiFMt6jSj2op9GWinQDc7Yqh",
        "feeAccount": "9pKxj6GTTdJ2biQ6uTyv7CTmVmnjz6cXGCz7rXg7Nm2N",
        "configAccount": "86MM38X9P5mxzRHFVX8ahtB9dCFKSk8AFhb33f5Zz8VW",
        "pythAccount": "3vxLXJqLqF3JG5TCbYycbKWRBbCJQLxQmBGCkyqEEefL",
        "pythPcAccount": "3vxLXJqLqF3JG5TCbYycbKWRBbCJQLxQmBGCkyqEEefL",
        "poolCoinTokenAccount": "5BUkh9e3JF9yUvSw6P3HHqkdMuujRG942hYNSkAEghFs",
        "poolCoinMint": "7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT",
        "poolPcTokenAccount": "BbwCGgAHEUfu7PUEz8hR877aK2snseqorfLbvtcVbjhj",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 6,
        "poolPcDecimal": 6,
        "poolMintDecimal": 6,
        "pythBaseDecimal": 8,
    },
    "LFNTY-USDC": {
        "amm": "5m1fnYcDdF1TMRVEBdGuSPXt5Sw8ueb9LTopuNL8SJYc",
        "poolMint": "AGytAQTdMJ1jYyDFwgqRUJfzuVUfFm4Kjh4ZnraPbTRv",
        "feeAccount": "7Pa7ssUY264ircqLGXARFvVzENLxGwuXL7ptGJkNinzo",
        "configAccount": "BhKTRa1uhYuSAMsY2SwAGuj5BnxpLPaPpJwArKxZvjYK",
        "pythAccount": "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU",
        "pythPcAccount": "GVXRSBjFk6e6J3NbVPXohDJetcTjaeeuykUpbQF8UoMU",
        "poolCoinTokenAccount": "3T9zHCguVtKfgzaUvdBne4V8LReeiAMAzmRbWwt69gwJ",
        "poolCoinMint": "LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp",
        "poolPcTokenAccount": "F3fuSs91bGysoZFgNDbGrPonEtCBaGRDMpHGMwfgxzsP",
        "poolPcMint": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "poolCoinDecimal": 6,
        "poolPcDecimal": 6,
        "poolMintDecimal": 6,
        "pythBaseDecimal": 8,
    },
};
