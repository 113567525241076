import { Connection, TransactionResponse, TransactionSignature } from '@solana/web3.js';
import { TransactionError } from './errors';
import promiseRetry from 'promise-retry';

export async function pollForConfirmedTransaction(
  connection: Connection,
  txid: TransactionSignature,
): Promise<TransactionResponse | null> {
  return promiseRetry(
    async (retry) => {
      const response = await connection.getTransaction(txid, {
        commitment: 'confirmed',
      });
      if (!response) {
        retry(new TransactionError('Transaction was not confirmed', txid));
      }
      return response;
    },
    {
      retries: 30,
      minTimeout: 500,
    },
  ).catch(() => null);
}
