"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.listVestingTreasuryActivity = exports.sleep = exports.createWrapSolInstructions = exports.createAtaCreateInstruction = exports.createAtaCreateInstructionIfNotExists = exports.fundExistingWSolAccountInstructions = exports.getValidTreasuryAllocation = exports.calculateActionFees = exports.listTreasuries = exports.findStreamTemplateAddress = exports.getStreamTemplate = exports.getTreasury = exports.listStreamActivity = exports.listStreamsCached = exports.listStreams = exports.getStreamCached = exports.getStream = exports.createProgram = void 0;
const web3_js_1 = require("@solana/web3.js");
const anchor_1 = require("@project-serum/anchor");
/**
 * MSP
 */
const constants_1 = require("./constants");
const types_1 = require("./types");
const types_2 = require("./types");
const msp_idl_004_1 = require("./msp_idl_004"); // point to the latest IDL
const bytes_1 = require("@project-serum/anchor/dist/cjs/utils/bytes");
const provider_1 = require("@project-serum/anchor/dist/cjs/provider");
const spl_token_1 = require("@solana/spl-token");
const anchor = __importStar(require("@project-serum/anchor"));
String.prototype.toPublicKey = function () {
    return new web3_js_1.PublicKey(this.toString());
};
const defaultStreamActivity = {
    signature: '',
    initializer: '',
    action: '',
    amount: 0,
    mint: '',
    blockTime: 0,
    utcDate: '',
};
const createProgram = (connection, programId) => {
    const opts = {
        preflightCommitment: 'finalized',
        commitment: 'finalized',
    };
    const wallet = {
        publicKey: constants_1.Constants.READONLY_PUBKEY,
        signAllTransactions: (txs) => __awaiter(void 0, void 0, void 0, function* () { return txs; }),
        signTransaction: (tx) => __awaiter(void 0, void 0, void 0, function* () { return tx; }),
    };
    const provider = new provider_1.AnchorProvider(connection, wallet, opts);
    return new anchor_1.Program(msp_idl_004_1.IDL, programId, provider);
};
exports.createProgram = createProgram;
const getStream = (program, address, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const streamEventResponse = yield program.simulate.getStream(constants_1.LATEST_IDL_FILE_VERSION, {
            accounts: {
                stream: address,
            },
        });
        if (!streamEventResponse ||
            !streamEventResponse.events ||
            !streamEventResponse.events.length ||
            !streamEventResponse.events[0].data) {
            return null;
        }
        const event = streamEventResponse.events[0].data;
        const streamInfo = parseGetStreamData(event, address, friendly);
        return streamInfo;
    }
    catch (error) {
        console.log(error);
        return null;
    }
});
exports.getStream = getStream;
const getStreamCached = (streamInfo, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    const timeDiff = streamInfo.lastRetrievedTimeInSeconds - streamInfo.lastRetrievedBlockTime;
    const blocktime = parseInt((Date.now() / 1000).toString()) - timeDiff;
    const parsedStream = parseStreamItemData(streamInfo.data, new web3_js_1.PublicKey(streamInfo.id), blocktime, friendly);
    parsedStream.createdBlockTime = streamInfo.createdBlockTime;
    return parsedStream;
});
exports.getStreamCached = getStreamCached;
const listStreams = (program, treasurer, treasury, beneficiary, friendly = true, category, subCategory) => __awaiter(void 0, void 0, void 0, function* () {
    const streamInfoList = [];
    const accounts = yield getFilteredStreamAccounts(program, treasurer, treasury, beneficiary, category, subCategory);
    const slot = yield program.provider.connection.getSlot('finalized');
    const blockTime = (yield program.provider.connection.getBlockTime(slot));
    for (const item of accounts) {
        if (item.account !== undefined) {
            const parsedStream = parseStreamItemData(item.account, item.publicKey, blockTime, friendly);
            const info = Object.assign({}, parsedStream);
            // let signatures = await program.provider.connection.getConfirmedSignaturesForAddress2(
            //   friendly ? new PublicKey(info.id as string) : (info.id as PublicKey),
            //   { limit: 1 },
            //   'confirmed'
            // );
            // if (signatures.length > 0) {
            //   info.createdBlockTime = signatures[0].blockTime as number;
            // }
            streamInfoList.push(info);
        }
    }
    const orderedStreams = streamInfoList.sort((a, b) => b.createdBlockTime - a.createdBlockTime);
    return orderedStreams;
});
exports.listStreams = listStreams;
const listStreamsCached = (streamInfoList, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    const streamList = [];
    for (const streamInfo of streamInfoList) {
        const timeDiff = streamInfo.lastRetrievedTimeInSeconds - streamInfo.lastRetrievedBlockTime;
        const blockTime = parseInt((Date.now() / 1000).toString()) - timeDiff;
        const parsedStream = parseStreamItemData(streamInfo.data, new web3_js_1.PublicKey(streamInfo.id), blockTime, friendly);
        parsedStream.createdBlockTime = streamInfo.createdBlockTime;
        streamList.push(parsedStream);
    }
    return streamList;
});
exports.listStreamsCached = listStreamsCached;
const listStreamActivity = (program, address, before = '', limit = 10, commitment, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    let activityRaw = [];
    const finality = commitment !== undefined ? commitment : 'finalized';
    const filter = { limit: limit };
    if (before) {
        filter['before'] = before;
    }
    const signatures = yield program.provider.connection.getConfirmedSignaturesForAddress2(address, filter, finality);
    const txs = yield program.provider.connection.getParsedTransactions(signatures.map((s) => s.signature), finality);
    if (txs && txs.length) {
        activityRaw = yield parseStreamTransactions(address, txs, program.programId);
        activityRaw.sort((a, b) => { var _a, _b; return ((_a = b.blockTime) !== null && _a !== void 0 ? _a : 0) - ((_b = a.blockTime) !== null && _b !== void 0 ? _b : 0); });
    }
    if (!friendly)
        return activityRaw;
    const activity = activityRaw.map(i => {
        var _a, _b;
        return {
            signature: i.signature,
            initializer: (_a = i.initializer) === null || _a === void 0 ? void 0 : _a.toBase58(),
            action: i.action,
            amount: i.amount ? parseFloat(i.amount.toNumber().toFixed(9)) : 0,
            mint: (_b = i.mint) === null || _b === void 0 ? void 0 : _b.toBase58(),
            blockTime: i.blockTime,
            utcDate: i.utcDate,
        };
    });
    return activity;
});
exports.listStreamActivity = listStreamActivity;
const getTreasury = (program, address, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    const treasury = yield program.account.treasury.fetch(address);
    const parsedTreasury = parseTreasuryData(treasury, address, friendly);
    return parsedTreasury;
});
exports.getTreasury = getTreasury;
const getStreamTemplate = (program, address, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    const template = yield program.account.streamTemplate.fetch(address);
    return parseStreamTemplateData(template, address, friendly);
});
exports.getStreamTemplate = getStreamTemplate;
const findStreamTemplateAddress = (treasury, programId) => __awaiter(void 0, void 0, void 0, function* () {
    return anchor.web3.PublicKey.findProgramAddress([anchor.utils.bytes.utf8.encode('template'), treasury.toBuffer()], programId);
});
exports.findStreamTemplateAddress = findStreamTemplateAddress;
const listTreasuries = (program, treasurer, friendly = true, excludeAutoClose, category, subCategory) => __awaiter(void 0, void 0, void 0, function* () {
    const treasuries = [];
    const memcmpFilters = [];
    if (treasurer) {
        memcmpFilters.push({
            memcmp: { offset: 8 + 43, bytes: treasurer.toBase58() },
        });
    }
    if (excludeAutoClose) {
        memcmpFilters.push({
            memcmp: { offset: 216, bytes: bytes_1.bs58.encode([0]) },
        });
    }
    if (category !== undefined) {
        memcmpFilters.push({
            memcmp: { offset: 218, bytes: bytes_1.bs58.encode([category]) },
        });
    }
    if (subCategory !== undefined) {
        memcmpFilters.push({
            memcmp: { offset: 219, bytes: bytes_1.bs58.encode([subCategory]) },
        });
    }
    const accounts = yield program.account.treasury.all(memcmpFilters);
    if (accounts.length) {
        for (const item of accounts) {
            if (item.account !== undefined) {
                const parsedTreasury = parseTreasuryData(item.account, item.publicKey, friendly);
                const info = Object.assign({}, parsedTreasury);
                if ((treasurer && treasurer.toBase58() === info.treasurer) ||
                    !treasurer) {
                    treasuries.push(info);
                }
            }
        }
    }
    const sortedTreasuries = treasuries.sort((a, b) => b.slot - a.slot);
    return sortedTreasuries;
});
exports.listTreasuries = listTreasuries;
const calculateActionFees = (connection, action) => __awaiter(void 0, void 0, void 0, function* () {
    const recentBlockhash = yield connection.getRecentBlockhash(connection.commitment), txFees = {
        blockchainFee: 0.0,
        mspFlatFee: 0.0,
        mspPercentFee: 0.0,
    };
    let blockchainFee = 0;
    switch (action) {
        case types_1.MSP_ACTIONS.createTreasury: {
            blockchainFee = 15000000;
            txFees.mspFlatFee = 0.00001;
            break;
        }
        case types_1.MSP_ACTIONS.createStream: {
            blockchainFee = 15000000;
            txFees.mspFlatFee = 0.00001;
            break;
        }
        case types_1.MSP_ACTIONS.createStreamWithFunds: {
            blockchainFee = 20000000;
            txFees.mspFlatFee = 0.000035;
            break;
        }
        case types_1.MSP_ACTIONS.scheduleOneTimePayment: {
            blockchainFee = 15000000;
            txFees.mspFlatFee = 0.000035;
            break;
        }
        case types_1.MSP_ACTIONS.addFunds: {
            txFees.mspFlatFee = 0.000025;
            break;
        }
        case types_1.MSP_ACTIONS.withdraw: {
            blockchainFee = 5000000;
            txFees.mspPercentFee = 0.25;
            break;
        }
        case types_1.MSP_ACTIONS.closeStream: {
            txFees.mspFlatFee = 0.00001;
            txFees.mspPercentFee = 0.25;
            break;
        }
        case types_1.MSP_ACTIONS.closeTreasury: {
            txFees.mspFlatFee = 0.00001;
            break;
        }
        case types_1.MSP_ACTIONS.transferStream: {
            blockchainFee = 5000;
            txFees.mspFlatFee = 0.00001;
            break;
        }
        case types_1.MSP_ACTIONS.treasuryWithdraw: {
            // txFees.mspFlatFee = 0.00001;
            txFees.mspPercentFee = 0.25;
            break;
        }
        default: {
            break;
        }
    }
    txFees.blockchainFee = blockchainFee / web3_js_1.LAMPORTS_PER_SOL;
    return txFees;
});
exports.calculateActionFees = calculateActionFees;
const getValidTreasuryAllocation = (connection, treasury, allocation) => __awaiter(void 0, void 0, void 0, function* () {
    const fees = yield (0, exports.calculateActionFees)(connection, types_1.MSP_ACTIONS.withdraw);
    //
    const BASE_100_TO_BASE_1_MULTIPLIER = 10000;
    const feeNumerator = fees.mspPercentFee * BASE_100_TO_BASE_1_MULTIPLIER;
    const feeDenaminator = 1000000;
    const unallocatedBalance = new anchor_1.BN(treasury.balance).sub(new anchor_1.BN(treasury.allocationAssigned));
    const allocationAmountBn = new anchor_1.BN(allocation).add(unallocatedBalance);
    const badStreamAllocationAmount = allocationAmountBn
        .mul(new anchor_1.BN(feeDenaminator))
        .div(new anchor_1.BN(feeNumerator + feeDenaminator));
    const feeAmount = badStreamAllocationAmount
        .mul(new anchor_1.BN(feeNumerator))
        .div(new anchor_1.BN(feeDenaminator));
    if (unallocatedBalance.gte(feeAmount)) {
        return badStreamAllocationAmount;
    }
    const goodStreamMaxAllocation = allocationAmountBn.sub(feeAmount);
    return goodStreamMaxAllocation;
});
exports.getValidTreasuryAllocation = getValidTreasuryAllocation;
const getFilteredStreamAccounts = (program, treasurer, treasury, beneficiary, category, subCategory) => __awaiter(void 0, void 0, void 0, function* () {
    const accounts = [];
    // category filters
    const categoryFilters = [];
    if (category !== undefined) {
        categoryFilters.push({
            memcmp: { offset: 339, bytes: bytes_1.bs58.encode([category]) },
        });
    }
    if (subCategory !== undefined) {
        categoryFilters.push({
            memcmp: { offset: 340, bytes: bytes_1.bs58.encode([subCategory]) },
        });
    }
    if (treasury) {
        const memcmpFilters = [
            { memcmp: { offset: 8 + 170, bytes: treasury.toBase58() } },
            ...categoryFilters,
        ];
        const accs = yield program.account.stream.all(memcmpFilters);
        if (accs.length) {
            accounts.push(...accs);
        }
    }
    else {
        if (treasurer) {
            const memcmpFilters = [
                { memcmp: { offset: 8 + 34, bytes: treasurer.toBase58() } },
                ...categoryFilters,
            ];
            const accs = yield program.account.stream.all(memcmpFilters);
            if (accs.length) {
                for (const acc of accs) {
                    if (accounts.indexOf(acc) === -1) {
                        accounts.push(acc);
                    }
                }
            }
        }
        if (beneficiary) {
            const memcmpFilters = [
                { memcmp: { offset: 8 + 106, bytes: beneficiary.toBase58() } },
                ...categoryFilters,
            ];
            const accs = yield program.account.stream.all(memcmpFilters);
            if (accs.length) {
                for (const acc of accs) {
                    if (accounts.indexOf(acc) === -1) {
                        accounts.push(acc);
                    }
                }
            }
        }
    }
    return accounts;
});
/**
 * Parses the event returned by the get_stream getter in the mps program.
 * @param event
 * @param address stream address
 * @param friendly
 * @returns Stream
 */
const parseGetStreamData = (event, address, friendly = true) => {
    const nameBuffer = Buffer.from(event.name);
    const createdOnUtcInSeconds = event.createdOnUtc
        ? event.createdOnUtc.toNumber()
        : 0;
    const startUtcInSeconds = event.startUtc.toNumber();
    const effectiveCreatedOnUtcInSeconds = createdOnUtcInSeconds > 0
        ? createdOnUtcInSeconds
        : event.startUtc.toNumber();
    const stream = {
        id: friendly ? address.toBase58() : address,
        version: event.version,
        initialized: event.initialized,
        name: new TextDecoder().decode(nameBuffer),
        startUtc: friendly
            ? new Date(startUtcInSeconds * 1000).toString()
            : new Date(startUtcInSeconds * 1000),
        treasurer: friendly
            ? event.treasurerAddress.toBase58()
            : event.treasurerAddress,
        treasury: friendly
            ? event.treasuryAddress.toBase58()
            : event.treasuryAddress,
        beneficiary: friendly
            ? event.beneficiaryAddress.toBase58()
            : event.beneficiaryAddress,
        associatedToken: friendly
            ? event.beneficiaryAssociatedToken.toBase58()
            : event.beneficiaryAssociatedToken,
        cliffVestAmount: friendly
            ? event.cliffVestAmountUnits.toNumber()
            : event.cliffVestAmountUnits,
        cliffVestPercent: friendly
            ? event.cliffVestPercent.toNumber() / 10000
            : event.cliffVestPercent.div(new anchor_1.BN(10000)),
        allocationAssigned: friendly
            ? event.allocationAssignedUnits.toNumber()
            : event.allocationAssignedUnits,
        // allocationReserved: friendly ? event.allocationReservedUnits.toNumber() : event.allocationReservedUnits,
        secondsSinceStart: friendly
            ? Math.max(0, event.currentBlockTime.toNumber() - event.startUtc.toNumber())
            : event.currentBlockTime.sub(new anchor_1.BN(event.startUtc)),
        estimatedDepletionDate: friendly
            ? new Date(event.estDepletionTime.toNumber() * 1000).toString()
            : new Date(event.estDepletionTime.toNumber() * 1000),
        rateAmount: friendly
            ? event.rateAmountUnits.toNumber()
            : event.rateAmountUnits,
        rateIntervalInSeconds: friendly
            ? event.rateIntervalInSeconds.toNumber()
            : event.rateIntervalInSeconds,
        totalWithdrawalsAmount: friendly
            ? event.totalWithdrawalsUnits.toNumber()
            : event.totalWithdrawalsUnits,
        fundsLeftInStream: friendly
            ? event.fundsLeftInStream.toNumber()
            : event.fundsLeftInStream,
        fundsSentToBeneficiary: friendly
            ? event.fundsSentToBeneficiary.toNumber()
            : new anchor_1.BN(event.fundsSentToBeneficiary),
        remainingAllocationAmount: friendly
            ? event.beneficiaryRemainingAllocation.toNumber()
            : event.beneficiaryRemainingAllocation,
        withdrawableAmount: friendly
            ? event.beneficiaryWithdrawableAmount.toNumber()
            : event.beneficiaryWithdrawableAmount,
        streamUnitsPerSecond: getStreamUnitsPerSecond(event),
        isManuallyPaused: event.isManualPause,
        status: event.status === 'Scheduled' ? 1 : event.status === 'Running' ? 2 : 3,
        lastRetrievedBlockTime: friendly
            ? event.currentBlockTime.toNumber()
            : event.currentBlockTime,
        lastRetrievedTimeInSeconds: friendly
            ? parseInt((Date.now() / 1000).toString())
            : new anchor_1.BN(parseInt((Date.now() / 1000).toString())),
        totalWithdrawals: friendly
            ? event.totalWithdrawalsUnits.toNumber()
            : event.totalWithdrawalsUnits,
        feePayedByTreasurer: event.feePayedByTreasurer,
        createdBlockTime: effectiveCreatedOnUtcInSeconds,
        createdOnUtc: friendly
            ? new Date(effectiveCreatedOnUtcInSeconds * 1000).toString()
            : new Date(effectiveCreatedOnUtcInSeconds * 1000),
        category: event.category,
        subCategory: event.subCategory,
        upgradeRequired: false,
        data: event,
    };
    return stream;
};
const parseStreamItemData = (stream, address, blockTime, friendly = true) => {
    const nameBuffer = Buffer.from(stream.name);
    const createdOnUtcInSeconds = stream.createdOnUtc
        ? stream.createdOnUtc.toNumber()
        : 0;
    const startUtcInSeconds = getStreamStartUtcInSeconds(stream);
    const effectiveCreatedOnUticInSeconds = createdOnUtcInSeconds > 0 ? createdOnUtcInSeconds : startUtcInSeconds;
    const timeDiff = parseInt((Date.now() / 1000).toString()) - blockTime;
    const streamInfo = {
        id: friendly ? address.toBase58() : address,
        version: stream.version,
        initialized: stream.initialized,
        name: new TextDecoder().decode(nameBuffer),
        startUtc: friendly
            ? new Date(startUtcInSeconds * 1000).toString()
            : new Date(startUtcInSeconds * 1000),
        treasurer: friendly
            ? stream.treasurerAddress.toBase58()
            : stream.treasurerAddress,
        treasury: friendly
            ? stream.treasuryAddress.toBase58()
            : stream.treasuryAddress,
        beneficiary: friendly
            ? stream.beneficiaryAddress.toBase58()
            : stream.beneficiaryAddress,
        associatedToken: friendly
            ? stream.beneficiaryAssociatedToken.toBase58()
            : stream.beneficiaryAssociatedToken,
        cliffVestAmount: friendly
            ? stream.cliffVestAmountUnits.toNumber()
            : stream.cliffVestAmountUnits,
        cliffVestPercent: friendly
            ? stream.cliffVestPercent.toNumber() / 10000
            : stream.cliffVestPercent.div(new anchor_1.BN(10000)),
        allocationAssigned: friendly
            ? stream.allocationAssignedUnits.toNumber()
            : stream.allocationAssignedUnits,
        // allocationReserved: friendly ? stream.allocationReservedUnits.toNumber() : stream.allocationReservedUnits,
        secondsSinceStart: friendly
            ? blockTime - getStreamStartUtcInSeconds(stream)
            : new anchor_1.BN(blockTime).sub(new anchor_1.BN(startUtcInSeconds * 1000)),
        estimatedDepletionDate: friendly
            ? getStreamEstDepletionDate(stream).toString()
            : getStreamEstDepletionDate(stream),
        rateAmount: friendly
            ? stream.rateAmountUnits.toNumber()
            : stream.rateAmountUnits,
        rateIntervalInSeconds: friendly
            ? stream.rateIntervalInSeconds.toNumber()
            : stream.rateIntervalInSeconds,
        totalWithdrawalsAmount: friendly
            ? stream.totalWithdrawalsUnits.toNumber()
            : stream.totalWithdrawalsUnits,
        fundsLeftInStream: friendly
            ? getFundsLeftInStream(stream, timeDiff)
            : new anchor_1.BN(getFundsLeftInStream(stream, timeDiff)),
        fundsSentToBeneficiary: friendly
            ? getFundsSentToBeneficiary(stream, timeDiff)
            : new anchor_1.BN(getFundsSentToBeneficiary(stream, timeDiff)),
        remainingAllocationAmount: friendly
            ? getStreamRemainingAllocation(stream)
            : new anchor_1.BN(getStreamRemainingAllocation(stream)),
        withdrawableAmount: friendly
            ? getStreamWithdrawableAmount(stream, timeDiff)
            : new anchor_1.BN(getStreamWithdrawableAmount(stream, timeDiff)),
        streamUnitsPerSecond: getStreamUnitsPerSecond(stream),
        isManuallyPaused: isStreamManuallyPaused(stream),
        status: getStreamStatus(stream, timeDiff),
        lastRetrievedBlockTime: friendly ? blockTime : new anchor_1.BN(blockTime),
        lastRetrievedTimeInSeconds: friendly
            ? parseInt((Date.now() / 1000).toString())
            : new anchor_1.BN(parseInt((Date.now() / 1000).toString())),
        totalWithdrawals: friendly
            ? stream.totalWithdrawalsUnits.toNumber()
            : stream.totalWithdrawalsUnits,
        feePayedByTreasurer: stream.feePayedByTreasurer,
        category: stream.category,
        subCategory: stream.subCategory,
        transactionSignature: '',
        createdBlockTime: createdOnUtcInSeconds > 0 ? createdOnUtcInSeconds : startUtcInSeconds,
        createdOnUtc: friendly
            ? new Date(effectiveCreatedOnUticInSeconds).toString()
            : new Date(effectiveCreatedOnUticInSeconds),
        upgradeRequired: false,
        data: {
            version: stream.version,
            initialized: stream.initialized,
            name: stream.name,
            startUtc: stream.startUtc,
            treasurerAddress: stream.treasurerAddress,
            rateAmountUnits: stream.rateAmountUnits,
            rateIntervalInSeconds: stream.rateIntervalInSeconds,
            cliffVestAmountUnits: stream.cliffVestAmountUnits,
            cliffVestPercent: stream.cliffVestPercent,
            beneficiaryAddress: stream.beneficiaryAddress,
            beneficiaryAssociatedToken: stream.beneficiaryAssociatedToken,
            treasuryAddress: stream.treasuryAddress,
            allocationAssignedUnits: stream.allocationAssignedUnits,
            allocationReservedUnits: stream.allocationReservedUnits,
            totalWithdrawalsUnits: stream.totalWithdrawalsUnits,
            lastWithdrawalUnits: stream.lastWithdrawalUnits,
            lastWithdrawalSlot: stream.lastWithdrawalSlot,
            lastWithdrawalBlockTime: stream.lastWithdrawalBlockTime,
            lastManualStopWithdrawableUnitsSnap: stream.lastManualStopWithdrawableUnitsSnap,
            lastManualStopSlot: stream.lastManualStopSlot,
            lastManualStopBlockTime: stream.lastManualStopBlockTime,
            lastManualResumeRemainingAllocationUnitsSnap: stream.lastManualResumeRemainingAllocationUnitsSnap,
            lastManualResumeSlot: stream.lastManualResumeSlot,
            lastManualResumeBlockTime: stream.lastManualResumeBlockTime,
            lastKnownTotalSecondsInPausedStatus: stream.lastKnownTotalSecondsInPausedStatus,
            lastAutoStopBlockTime: stream.lastAutoStopBlockTime,
            feePayedByTreasurer: stream.feePayedByTreasurer,
            // calculated data
            status: getStreamStatus(stream, timeDiff) === 1
                ? 'Scheduled'
                : getStreamStatus(stream, 0) === 2
                    ? 'Running'
                    : 'Paused',
            isManualPause: isStreamManuallyPaused(stream),
            cliffUnits: new anchor_1.BN(getStreamCliffAmount(stream)),
            currentBlockTime: new anchor_1.BN(blockTime),
            secondsSinceStart: new anchor_1.BN(blockTime).sub(new anchor_1.BN(getStreamStartUtcInSeconds(stream))),
            estDepletionTime: new anchor_1.BN(parseInt((getStreamEstDepletionDate(stream).getTime() / 1000).toString())),
            fundsLeftInStream: new anchor_1.BN(getFundsLeftInStream(stream, timeDiff)),
            fundsSentToBeneficiary: new anchor_1.BN(getFundsSentToBeneficiary(stream, timeDiff)),
            withdrawableUnitsWhilePaused: new anchor_1.BN(getStreamWithdrawableUnitsWhilePaused(stream)),
            nonStopEarningUnits: new anchor_1.BN(getStreamNonStopEarningUnits(stream, timeDiff)),
            missedUnitsWhilePaused: new anchor_1.BN(getStreamMissedEarningUnitsWhilePaused(stream)),
            entitledEarningsUnits: new anchor_1.BN(Math.max(0, getStreamNonStopEarningUnits(stream, timeDiff) -
                getStreamMissedEarningUnitsWhilePaused(stream))),
            withdrawableUnitsWhileRunning: new anchor_1.BN(Math.max(getStreamNonStopEarningUnits(stream, timeDiff) -
                getStreamMissedEarningUnitsWhilePaused(stream)) + stream.totalWithdrawalsUnits.toNumber()),
            beneficiaryRemainingAllocation: new anchor_1.BN(getStreamRemainingAllocation(stream)),
            beneficiaryWithdrawableAmount: new anchor_1.BN(getStreamWithdrawableAmount(stream, 0)),
            lastKnownStopBlockTime: new anchor_1.BN(Math.max(stream.lastAutoStopBlockTime.toNumber(), stream.lastManualStopBlockTime.toNumber())),
        },
    };
    return streamInfo;
};
let idl_legacy_after_1645224519 = null;
let idl_legacy_before_1645224519 = null;
const idlPaths = ['./msp_idl_001', './msp_idl_002', './msp_idl_003'];
const idls = {};
function parseStreamInstructionAfter1645224519(ix, streamAddress, transactionSignature, transactionBlockTimeInSeconds, programId) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return __awaiter(this, void 0, void 0, function* () {
        if (!ix.programId.equals(programId)) {
            return null;
        }
        try {
            if (!idl_legacy_after_1645224519) {
                const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_legacy_after_1645224519')));
                idl_legacy_after_1645224519 = importedIdl.IDL;
            }
            const coder = new anchor_1.BorshInstructionCoder(idl_legacy_after_1645224519);
            const decodedIx = coder.decode(ix.data, 'base58');
            if (!decodedIx)
                return null;
            const ixName = decodedIx.name;
            // console.log(`ixName: ${ixName}`);
            if (['createStream', 'allocate', 'withdraw'].indexOf(ixName) === -1)
                return null;
            const ixAccountMetas = ix.accounts.map(pk => {
                return { pubkey: pk, isSigner: false, isWritable: false };
            });
            const formattedIx = coder.format(decodedIx, ixAccountMetas);
            // console.log(formattedIx);
            // console.table(formattedIx?.accounts.map(a => { return { name: a.name, pk: a.pubkey.toBase58() } }));
            const stream = (_a = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _a === void 0 ? void 0 : _a.pubkey;
            if (!stream || !stream.equals(streamAddress)) {
                return null;
            }
            const blockTime = transactionBlockTimeInSeconds * 1000; // mult by 1000 to add milliseconds
            const action = decodedIx.name === 'createStream' || decodedIx.name === 'allocate'
                ? 'deposited'
                : 'withdrew';
            let initializer;
            let mint;
            let amountBN;
            if (decodedIx.name === 'createStream') {
                initializer = (_b = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _b === void 0 ? void 0 : _b.pubkey;
                mint = (_c = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _c === void 0 ? void 0 : _c.pubkey;
                const parsedAmount = (_d = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'allocationAssignedUnits')) === null || _d === void 0 ? void 0 : _d.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'allocate') {
                initializer = (_e = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _e === void 0 ? void 0 : _e.pubkey;
                mint = (_f = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _f === void 0 ? void 0 : _f.pubkey;
                const parsedAmount = (_g = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _g === void 0 ? void 0 : _g.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'withdraw') {
                initializer = (_h = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _h === void 0 ? void 0 : _h.pubkey;
                mint = (_j = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _j === void 0 ? void 0 : _j.pubkey;
                const parsedAmount = (_k = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _k === void 0 ? void 0 : _k.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            const activity = {
                signature: transactionSignature,
                initializer: initializer,
                blockTime,
                utcDate: new Date(blockTime).toUTCString(),
                action,
                // TODO: Here the 'amount' might not be accurate, we need to emit events instead
                amount: amountBN,
                mint,
            };
            return activity;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    });
}
function parseStreamInstructionBefore1645224519(ix, streamAddress, transactionSignature, transactionBlockTimeInSeconds, programId) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return __awaiter(this, void 0, void 0, function* () {
        if (!ix.programId.equals(programId)) {
            return null;
        }
        try {
            if (!idl_legacy_before_1645224519) {
                idl_legacy_before_1645224519 = yield Promise.resolve().then(() => __importStar(require('./msp_idl_legacy_before_1645224519')));
            }
            const coder = new anchor_1.BorshInstructionCoder(idl_legacy_before_1645224519);
            const decodedIx = coder.decode(ix.data, 'base58');
            if (!decodedIx)
                return null;
            const ixName = decodedIx.name;
            // console.log(`ixName: ${ixName}`);
            if (['createStream', 'addFunds', 'withdraw'].indexOf(ixName) === -1)
                return null;
            const ixAccountMetas = ix.accounts.map(pk => {
                return { pubkey: pk, isSigner: false, isWritable: false };
            });
            const formattedIx = coder.format(decodedIx, ixAccountMetas);
            // console.log(formattedIx);
            // console.table(formattedIx?.accounts.map(a => { return { name: a.name, pk: a.pubkey.toBase58() } }));
            const stream = (_a = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _a === void 0 ? void 0 : _a.pubkey;
            if (!stream || !stream.equals(streamAddress)) {
                return null;
            }
            const blockTime = transactionBlockTimeInSeconds * 1000; // mult by 1000 to add milliseconds
            const action = decodedIx.name === 'createStream' || decodedIx.name === 'addFunds'
                ? 'deposited'
                : 'withdrew';
            let initializer;
            let mint;
            let amountBN;
            if (decodedIx.name === 'createStream') {
                if (ixAccountMetas.length !== 14) {
                    // console.log(`this createStream instruction corresponds to an IDL that is not supported`);
                    return null;
                }
                initializer = (_b = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _b === void 0 ? void 0 : _b.pubkey;
                mint = (_c = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _c === void 0 ? void 0 : _c.pubkey;
                const parsedAmount = (_d = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'allocationAssignedUnits')) === null || _d === void 0 ? void 0 : _d.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'addFunds') {
                if (ixAccountMetas.length !== 14) {
                    // console.log(`this addFunds instruction corresponds to an IDL that is not supported`);
                    return null;
                }
                const allocationType = (_e = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'allocationType')) === null || _e === void 0 ? void 0 : _e.data;
                if (allocationType !== '1') {
                    return null;
                }
                initializer = (_f = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _f === void 0 ? void 0 : _f.pubkey;
                mint = (_g = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _g === void 0 ? void 0 : _g.pubkey;
                const parsedAmount = (_h = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _h === void 0 ? void 0 : _h.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'withdraw') {
                if (ixAccountMetas.length !== 13) {
                    // console.log(`this withdraw instruction corresponds to an IDL that is not supported`);
                    return null;
                }
                initializer = (_j = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _j === void 0 ? void 0 : _j.pubkey;
                mint = (_k = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _k === void 0 ? void 0 : _k.pubkey;
                const parsedAmount = (_l = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _l === void 0 ? void 0 : _l.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            const activity = {
                signature: transactionSignature,
                initializer: initializer,
                blockTime,
                utcDate: new Date(blockTime).toUTCString(),
                action,
                // TODO: Here the 'amount' might not be accurate, we need to emit events instead
                amount: amountBN,
                mint,
            };
            return activity;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    });
}
function parseVersionedStreamInstruction(ix, streamAddress, transactionSignature, transactionBlockTimeInSeconds, idlFileVersion, programId) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return __awaiter(this, void 0, void 0, function* () {
        if (!ix.programId.equals(programId)) {
            return null;
        }
        if (idlFileVersion <= 0 || idlFileVersion > constants_1.LATEST_IDL_FILE_VERSION) {
            return null;
        }
        try {
            if (!idls[idlFileVersion]) {
                if (idlFileVersion === 1) {
                    // TODO: to avoid this if else, find a way to do dynamic imports passign concatenated paths
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_001')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 2) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_002')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 3) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_003')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 4) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_004')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else {
                    return null;
                }
            }
            const coder = new anchor_1.BorshInstructionCoder(idls[idlFileVersion]);
            const decodedIx = coder.decode(ix.data, 'base58');
            if (!decodedIx)
                return null;
            const ixName = decodedIx.name;
            // console.log(`ixName: ${ixName}`);
            if ([
                'createStream',
                'createStreamWithTemplate',
                'allocate',
                'withdraw',
            ].indexOf(ixName) === -1)
                return null;
            const ixAccountMetas = ix.accounts.map(pk => {
                return { pubkey: pk, isSigner: false, isWritable: false };
            });
            const formattedIx = coder.format(decodedIx, ixAccountMetas);
            // console.log(formattedIx);
            // console.table(formattedIx?.accounts.map(a => { return { name: a.name, pk: a.pubkey.toBase58() } }));
            const stream = (_a = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _a === void 0 ? void 0 : _a.pubkey;
            if (!stream || !stream.equals(streamAddress)) {
                return null;
            }
            const blockTime = transactionBlockTimeInSeconds * 1000; // mult by 1000 to add milliseconds
            const action = decodedIx.name === 'createStream' ||
                decodedIx.name === 'createStreamWithTemplate' ||
                decodedIx.name === 'allocate'
                ? 'deposited'
                : 'withdrew';
            let initializer;
            let mint;
            let amountBN;
            if (decodedIx.name === 'createStream' ||
                decodedIx.name === 'createStreamWithTemplate') {
                initializer = (_b = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _b === void 0 ? void 0 : _b.pubkey;
                mint = (_c = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _c === void 0 ? void 0 : _c.pubkey;
                const parsedAmount = (_d = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'allocationAssignedUnits')) === null || _d === void 0 ? void 0 : _d.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'allocate') {
                initializer = (_e = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _e === void 0 ? void 0 : _e.pubkey;
                mint = (_f = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _f === void 0 ? void 0 : _f.pubkey;
                const parsedAmount = (_g = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _g === void 0 ? void 0 : _g.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'withdraw') {
                initializer = (_h = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _h === void 0 ? void 0 : _h.pubkey;
                mint = (_j = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _j === void 0 ? void 0 : _j.pubkey;
                const parsedAmount = (_k = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _k === void 0 ? void 0 : _k.data;
                amountBN = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            const activity = {
                signature: transactionSignature,
                initializer: initializer,
                blockTime,
                utcDate: new Date(blockTime).toUTCString(),
                action,
                // TODO: Here the 'amount' might not be accurate, we need to emit events instead
                amount: amountBN,
                mint,
            };
            return activity;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    });
}
function parseStreamTransactions(streamAddress, transactions, programId) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const parsedActivities = [];
        if (!transactions || transactions.length === 0)
            return [];
        for (let i = 0; i < transactions.length; i++) {
            const tx = transactions[i];
            const signature = tx.transaction.signatures[0];
            for (let j = 0; j < tx.transaction.message.instructions.length; j++) {
                const ix = tx.transaction.message.instructions[j];
                if (!ix || !ix.data)
                    continue;
                const decodedIxData = bytes_1.bs58.decode(ix.data);
                const ixIdlFileVersion = decodedIxData.length >= 9 ? decodedIxData.slice(8, 9)[0] : 0;
                let activity = null;
                if (ixIdlFileVersion > 0 && ixIdlFileVersion <= constants_1.LATEST_IDL_FILE_VERSION) {
                    // TODO: hardcoded
                    activity = yield parseVersionedStreamInstruction(ix, streamAddress, signature, (_a = tx.blockTime) !== null && _a !== void 0 ? _a : 0, ixIdlFileVersion, programId);
                }
                else if (!tx.blockTime || tx.blockTime >= 1645224519) {
                    activity = yield parseStreamInstructionAfter1645224519(ix, streamAddress, signature, (_b = tx.blockTime) !== null && _b !== void 0 ? _b : 0, programId);
                }
                else {
                    activity = yield parseStreamInstructionBefore1645224519(ix, streamAddress, signature, (_c = tx.blockTime) !== null && _c !== void 0 ? _c : 0, programId);
                }
                if (!activity) {
                    continue;
                }
                parsedActivities.push(activity);
            }
        }
        return parsedActivities;
    });
}
const parseTreasuryData = (treasury, address, friendly = true) => {
    const nameBuffer = Buffer.from(treasury.name);
    const treasuryAssocatedTokenMint = friendly
        ? treasury.associatedTokenAddress.equals(web3_js_1.PublicKey.default)
            ? ''
            : treasury.associatedTokenAddress.toBase58()
        : treasury.associatedTokenAddress;
    const treasuryCreatedUtc = treasury.createdOnUtc.toString().length > 10
        ? parseInt(treasury.createdOnUtc.toString().substring(0, 10))
        : treasury.createdOnUtc.toNumber();
    return {
        id: friendly ? address.toBase58() : address,
        version: treasury.version,
        initialized: treasury.initialized,
        name: new TextDecoder().decode(nameBuffer),
        bump: treasury.bump,
        slot: treasury.slot.toNumber(),
        labels: treasury.labels,
        mint: friendly ? treasury.mintAddress.toBase58() : treasury.mintAddress,
        autoClose: treasury.autoClose,
        createdOnUtc: friendly
            ? new Date(treasuryCreatedUtc * 1000).toString()
            : new Date(treasuryCreatedUtc * 1000),
        treasuryType: treasury.treasuryType === 0 ? types_2.TreasuryType.Open : types_2.TreasuryType.Lock,
        treasurer: friendly
            ? treasury.treasurerAddress.toBase58()
            : treasury.treasurerAddress,
        associatedToken: treasuryAssocatedTokenMint,
        balance: treasury.lastKnownBalanceUnits.toNumber(),
        allocationReserved: treasury.allocationReservedUnits.toNumber(),
        allocationAssigned: treasury.allocationAssignedUnits.toNumber(),
        totalWithdrawals: treasury.totalWithdrawalsUnits.toNumber(),
        totalStreams: treasury.totalStreams.toNumber(),
        category: treasury.category,
        subCategory: treasury.subCategory,
        data: treasury,
    };
};
const parseStreamTemplateData = (template, address, friendly = true) => {
    return {
        id: friendly ? address.toBase58() : address,
        version: template.version,
        bump: template.bump,
        durationNumberOfUnits: template.durationNumberOfUnits.toNumber(),
        rateIntervalInSeconds: friendly
            ? template.rateIntervalInSeconds.toNumber()
            : template.rateIntervalInSeconds,
        startUtc: friendly
            ? new Date(template.startUtcInSeconds * 1000).toString()
            : new Date(template.startUtcInSeconds * 1000),
        cliffVestPercent: template.cliffVestPercent,
        feePayedByTreasurer: template.feePayedByTreasurer,
    };
};
const getStreamEstDepletionDate = (stream) => {
    if (stream.rateIntervalInSeconds == 0) {
        return new Date();
    }
    const cliffAmount = getStreamCliffAmount(stream);
    const streamableAmount = Math.max(0, stream.allocationAssignedUnits.toNumber() - cliffAmount);
    const rateAmount = stream.rateIntervalInSeconds.toNumber() === 0
        ? 0
        : stream.rateAmountUnits.toNumber() /
            stream.rateIntervalInSeconds.toNumber();
    const streamableSeconds = streamableAmount / rateAmount;
    const duration = streamableSeconds + stream.lastKnownTotalSecondsInPausedStatus.toNumber();
    const startUtcInSeconds = getStreamStartUtcInSeconds(stream);
    return new Date((startUtcInSeconds + duration) * 1000);
};
const getStreamCliffAmount = (stream) => {
    let cliffAmount = stream.cliffVestAmountUnits.toNumber();
    if (stream.cliffVestPercent > 0) {
        cliffAmount =
            (stream.cliffVestPercent.toNumber() *
                stream.allocationAssignedUnits.toNumber()) /
                constants_1.Constants.CLIFF_PERCENT_DENOMINATOR;
    }
    return parseInt(cliffAmount.toString());
};
const getFundsLeftInStream = (stream, timeDiff = 0) => {
    const withdrawableAmount = getStreamWithdrawableAmount(stream, timeDiff);
    const fundsLeft = stream.allocationAssignedUnits.toNumber() -
        stream.totalWithdrawalsUnits.toNumber() -
        withdrawableAmount;
    return Math.max(0, fundsLeft);
};
const getFundsSentToBeneficiary = (stream, timeDiff = 0) => {
    const withdrawableAmount = getStreamWithdrawableAmount(stream, timeDiff);
    const fundsSent = stream.totalWithdrawalsUnits.toNumber() + withdrawableAmount;
    return fundsSent;
};
const getStreamRemainingAllocation = (stream) => {
    const remainingAlloc = stream.allocationAssignedUnits.toNumber() -
        stream.totalWithdrawalsUnits.toNumber();
    return Math.max(0, remainingAlloc);
};
const getStreamWithdrawableAmount = (stream, timeDiff = 0) => {
    const remainingAllocation = getStreamRemainingAllocation(stream);
    if (remainingAllocation === 0) {
        return 0;
    }
    const status = getStreamStatus(stream, timeDiff);
    // Check if SCHEDULED
    if (status === types_2.STREAM_STATUS.Schedule) {
        return 0;
    }
    // Check if PAUSED
    if (status === types_2.STREAM_STATUS.Paused) {
        const manuallyPaused = isStreamManuallyPaused(stream);
        const withdrawableWhilePausedAmount = manuallyPaused
            ? stream.lastManualStopWithdrawableUnitsSnap.toNumber()
            : stream.allocationAssignedUnits.toNumber() -
                stream.totalWithdrawalsUnits.toNumber();
        return Math.max(0, withdrawableWhilePausedAmount);
    }
    // Check if RUNNING
    if (stream.rateAmountUnits.toNumber() === 0 ||
        stream.rateIntervalInSeconds.toNumber() === 0) {
        return 0;
    }
    const streamedUnitsPerSecond = getStreamUnitsPerSecond(stream);
    const cliffAmount = getStreamCliffAmount(stream);
    const blocktime = parseInt((Date.now() / 1000).toString()) - timeDiff;
    const startUtcInSeconds = getStreamStartUtcInSeconds(stream);
    const timeSinceStart = blocktime - startUtcInSeconds;
    const nonStopEarningUnits = cliffAmount + streamedUnitsPerSecond * timeSinceStart;
    const totalSecondsPaused = stream.lastKnownTotalSecondsInPausedStatus.toNumber().length >= 10
        ? parseInt((stream.lastKnownTotalSecondsInPausedStatus.toNumber() / 1000).toString())
        : stream.lastKnownTotalSecondsInPausedStatus.toNumber();
    const missedEarningUnitsWhilePaused = streamedUnitsPerSecond * totalSecondsPaused;
    let entitledEarnings = nonStopEarningUnits;
    if (nonStopEarningUnits >= missedEarningUnitsWhilePaused) {
        entitledEarnings = nonStopEarningUnits - missedEarningUnitsWhilePaused;
    }
    let withdrawableUnitsWhileRunning = entitledEarnings;
    if (entitledEarnings >= stream.totalWithdrawalsUnits.toNumber()) {
        withdrawableUnitsWhileRunning =
            entitledEarnings - stream.totalWithdrawalsUnits.toNumber();
    }
    const withdrawableAmount = Math.min(remainingAllocation, withdrawableUnitsWhileRunning);
    return Math.max(0, parseInt(withdrawableAmount.toString()));
};
const getStreamStatus = (stream, timeDiff) => {
    const now = parseInt((Date.now() / 1000).toString()) - timeDiff;
    const startUtcInSeconds = getStreamStartUtcInSeconds(stream);
    // Scheduled
    if (startUtcInSeconds > now) {
        return types_2.STREAM_STATUS.Schedule;
    }
    // Manually paused
    const manuallyPaused = isStreamManuallyPaused(stream);
    if (manuallyPaused) {
        return types_2.STREAM_STATUS.Paused;
    }
    // Running or automatically paused (ran out of funds)
    const streamedUnitsPerSecond = getStreamUnitsPerSecond(stream);
    const cliffAmount = getStreamCliffAmount(stream);
    const timeSinceStart = now - startUtcInSeconds;
    const nonStopEarningUnits = cliffAmount + streamedUnitsPerSecond * timeSinceStart;
    const missedEarningUnitsWhilePaused = streamedUnitsPerSecond *
        stream.lastKnownTotalSecondsInPausedStatus.toNumber();
    let entitledEarnings = nonStopEarningUnits;
    if (nonStopEarningUnits >= missedEarningUnitsWhilePaused) {
        entitledEarnings = nonStopEarningUnits - missedEarningUnitsWhilePaused;
    }
    // Running
    if (stream.allocationAssignedUnits.toNumber() > entitledEarnings) {
        return types_2.STREAM_STATUS.Running;
    }
    // Automatically paused (ran out of funds)
    return types_2.STREAM_STATUS.Paused;
};
const isStreamManuallyPaused = (stream) => {
    if (stream.lastManualStopBlockTime.toNumber() === 0) {
        return false;
    }
    return (stream.lastManualStopBlockTime.toNumber() >
        stream.lastManualResumeBlockTime.toNumber());
};
const getStreamUnitsPerSecond = (stream) => {
    if (stream.rateIntervalInSeconds.toNumber() === 0) {
        return 0;
    }
    return (stream.rateAmountUnits.toNumber() / stream.rateIntervalInSeconds.toNumber());
};
const getStreamStartUtcInSeconds = (stream) => {
    let startUtcFixed = 0;
    if (stream.startUtc.toString().length > 10) {
        startUtcFixed = parseInt(stream.startUtc.toString().substr(0, 10));
        return startUtcFixed;
    }
    if (stream.startUtcInSeconds && stream.startUtcInSeconds.toNumber() > 0) {
        return stream.startUtcInSeconds.toNumber();
    }
    return stream.startUtc.toNumber();
};
const getStreamWithdrawableUnitsWhilePaused = (stream) => {
    let withdrawableWhilePaused = 0;
    const isManuallyPaused = isStreamManuallyPaused(stream);
    if (isManuallyPaused) {
        withdrawableWhilePaused =
            stream.lastManualStopWithdrawableUnitsSnap.toNumber();
    }
    else {
        withdrawableWhilePaused = stream.allocationAssignedUnits
            .sub(stream.totalWithdrawalsUnits)
            .toNumber();
    }
    return Math.max(0, withdrawableWhilePaused);
};
const getStreamNonStopEarningUnits = (stream, timeDiff) => {
    const cliffUnits = getStreamCliffAmount(stream);
    const blocktime = parseInt((Date.now() / 1000).toString()) - timeDiff;
    const secondsSinceStart = Math.max(0, blocktime - getStreamStartUtcInSeconds(stream));
    const streamUnitsSinceStarted = (stream.rateIntervalInSeconds.toNumber() * secondsSinceStart) /
        stream.rateAmountUnits.toNumber();
    const nonStopEarningUnits = cliffUnits + parseInt(streamUnitsSinceStarted.toString());
    return parseInt(nonStopEarningUnits.toString());
};
const getStreamMissedEarningUnitsWhilePaused = (stream) => {
    if (stream.rateIntervalInSeconds.toNumber() === 0) {
        return 0;
    }
    const totalSecondsPaused = stream.lastKnownTotalSecondsInPausedStatus.toString().length > 10
        ? parseInt(stream.startUtc.toString().substring(0, 10))
        : stream.lastKnownTotalSecondsInPausedStatus.toNumber();
    const withdrawableWhilePaused = (stream.rateIntervalInSeconds.toNumber() * totalSecondsPaused) /
        stream.rateAmountUnits.toNumber();
    return parseInt(withdrawableWhilePaused.toString());
};
function fundExistingWSolAccountInstructions(connection, owner, ownerWSolTokenAccount, payer, amountToWrapInLamports) {
    return __awaiter(this, void 0, void 0, function* () {
        // Allocate memory for the account
        const minimumAccountBalance = yield spl_token_1.Token.getMinBalanceRentForExemptAccount(connection);
        const newWrapAccount = web3_js_1.Keypair.generate();
        const wrapIxs = [
            web3_js_1.SystemProgram.createAccount({
                fromPubkey: payer,
                newAccountPubkey: newWrapAccount.publicKey,
                lamports: minimumAccountBalance + amountToWrapInLamports,
                space: spl_token_1.AccountLayout.span,
                programId: spl_token_1.TOKEN_PROGRAM_ID,
            }),
            spl_token_1.Token.createInitAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, spl_token_1.NATIVE_MINT, newWrapAccount.publicKey, owner),
            spl_token_1.Token.createTransferInstruction(spl_token_1.TOKEN_PROGRAM_ID, newWrapAccount.publicKey, ownerWSolTokenAccount, owner, [], amountToWrapInLamports),
            spl_token_1.Token.createCloseAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, newWrapAccount.publicKey, payer, owner, []),
        ];
        return [wrapIxs, newWrapAccount];
    });
}
exports.fundExistingWSolAccountInstructions = fundExistingWSolAccountInstructions;
function createAtaCreateInstructionIfNotExists(ataAddress, mintAddress, ownerAccountAddress, payerAddress, connection) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const ata = yield connection.getAccountInfo(ataAddress);
            if (!ata) {
                // console.log("ATA: %s for mint: %s was not found. Generating 'create' instruction...", ataAddress.toBase58(), mintAddress.toBase58());
                const [, createIx] = yield createAtaCreateInstruction(ataAddress, mintAddress, ownerAccountAddress, payerAddress);
                return createIx;
            }
            // console.log("ATA: %s for mint: %s already exists", ataAddress.toBase58(), mintAddress.toBase58());
            return null;
        }
        catch (err) {
            console.log('Unable to find associated account: %s', err);
            throw Error('Unable to find associated account');
        }
    });
}
exports.createAtaCreateInstructionIfNotExists = createAtaCreateInstructionIfNotExists;
function createAtaCreateInstruction(ataAddress, mintAddress, ownerAccountAddress, payerAddress) {
    return __awaiter(this, void 0, void 0, function* () {
        if (ataAddress === null) {
            ataAddress = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mintAddress, ownerAccountAddress);
        }
        const ataCreateInstruction = spl_token_1.Token.createAssociatedTokenAccountInstruction(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mintAddress, ataAddress, ownerAccountAddress, payerAddress);
        return [ataAddress, ataCreateInstruction];
    });
}
exports.createAtaCreateInstruction = createAtaCreateInstruction;
function createWrapSolInstructions(connection, wSolAmountInLamports, owner, ownerWSolTokenAccount, ownerWSolTokenAccountInfo) {
    return __awaiter(this, void 0, void 0, function* () {
        const ixs = [];
        const signers = [];
        const wSolAmountInLamportsBn = new anchor_1.BN(wSolAmountInLamports);
        let ownerWSolAtaBalanceBn = new anchor_1.BN(0);
        if (ownerWSolTokenAccountInfo) {
            const ownerWSolAtaTokenAmount = (yield connection.getTokenAccountBalance(ownerWSolTokenAccount)).value;
            ownerWSolAtaBalanceBn = new anchor_1.BN(ownerWSolAtaTokenAmount.amount);
        }
        else {
            const ownerFromAtaCreateInstruction = yield createAtaCreateInstructionIfNotExists(ownerWSolTokenAccount, spl_token_1.NATIVE_MINT, owner, owner, connection);
            if (ownerFromAtaCreateInstruction)
                ixs.push(ownerFromAtaCreateInstruction);
        }
        if (wSolAmountInLamportsBn.gt(ownerWSolAtaBalanceBn)) {
            const amountToWrapBn = wSolAmountInLamportsBn.sub(ownerWSolAtaBalanceBn);
            const [wrapIxs, newWrapAccount] = yield fundExistingWSolAccountInstructions(connection, owner, ownerWSolTokenAccount, owner, amountToWrapBn.toNumber());
            ixs.push(...wrapIxs);
            signers.push(newWrapAccount);
        }
        return [ixs, signers];
    });
}
exports.createWrapSolInstructions = createWrapSolInstructions;
// export async function createWrappedSolTokenAccountInstructions(
//   connection: Connection,
//   amountToWrapInLamports: number,
//   owner: PublicKey,
//   ownerWSolTokenAccount: PublicKey,
// ): Promise<[TransactionInstruction[], Keypair]> {
//   // REF: https://github.com/solana-labs/solana-program-library/blob/3eccf25ece1c373a117fc9f6e6cbeb2216d86f03/token/ts/src/instructions/syncNative.ts#L28
//   const wrapIxs = [
//     Token.createAssociatedTokenAccountInstruction(
//         payer.publicKey,
//         associatedToken,
//         owner,
//         NATIVE_MINT,
//         programId,
//         ASSOCIATED_TOKEN_PROGRAM_ID
//     ),
//     SystemProgram.transfer({
//         fromPubkey: payer.publicKey,
//         toPubkey: associatedToken,
//         lamports: amount,
//     }),
//     createSyncNativeInstruction(associatedToken, programId)
//   ];
//   return [wrapIxs, newWSolAccount];
// }
function sleep(ms) {
    console.log('Sleeping for', ms / 1000, 'seconds');
    return new Promise(resolve => setTimeout(resolve, ms));
}
exports.sleep = sleep;
const listVestingTreasuryActivity = (program, address, before = '', limit = 10, commitment, friendly = true) => __awaiter(void 0, void 0, void 0, function* () {
    let activityRaw = [];
    const finality = commitment !== undefined ? commitment : 'finalized';
    const filter = { limit: limit };
    if (before) {
        filter['before'] = before;
    }
    const signatures = yield program.provider.connection.getConfirmedSignaturesForAddress2(address, filter, finality);
    const txs = yield program.provider.connection.getParsedTransactions(signatures.map((s) => s.signature), finality);
    if (txs && txs.length) {
        activityRaw = yield parseVestingTreasuryTransactions(address, txs, program.programId);
        activityRaw.sort((a, b) => { var _a, _b; return ((_a = b.blockTime) !== null && _a !== void 0 ? _a : 0) - ((_b = a.blockTime) !== null && _b !== void 0 ? _b : 0); });
        // prioritize treasury create activity
        const createVestingTreasuryActivity = activityRaw.find(a => a.action === types_1.VestingTreasuryActivityAction.TreasuryCreate);
        if (createVestingTreasuryActivity) {
            activityRaw = activityRaw.filter(a => a.action !== types_1.VestingTreasuryActivityAction.TreasuryCreate);
            activityRaw.push(createVestingTreasuryActivity);
        }
    }
    if (!friendly)
        return activityRaw;
    const activity = activityRaw.map(i => {
        var _a, _b, _c, _d, _e, _f, _g;
        return {
            signature: i.signature,
            action: i.action,
            initializer: (_a = i.initializer) === null || _a === void 0 ? void 0 : _a.toBase58(),
            mint: (_b = i.mint) === null || _b === void 0 ? void 0 : _b.toBase58(),
            blockTime: i.blockTime,
            amount: i.amount ? parseFloat(i.amount.toNumber().toFixed(9)) : 0,
            beneficiary: (_c = i.beneficiary) === null || _c === void 0 ? void 0 : _c.toBase58(),
            destination: (_d = i.destination) === null || _d === void 0 ? void 0 : _d.toBase58(),
            template: (_e = i.template) === null || _e === void 0 ? void 0 : _e.toBase58(),
            destinationTokenAccount: (_f = i.destinationTokenAccount) === null || _f === void 0 ? void 0 : _f.toBase58(),
            stream: (_g = i.stream) === null || _g === void 0 ? void 0 : _g.toBase58(),
            utcDate: i.utcDate,
        };
    });
    return activity;
});
exports.listVestingTreasuryActivity = listVestingTreasuryActivity;
function parseVestingTreasuryTransactions(treasuryAddress, transactions, programId) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const parsedActivities = [];
        if (!transactions || transactions.length === 0)
            return [];
        for (let i = 0; i < transactions.length; i++) {
            const tx = transactions[i];
            const signature = tx.transaction.signatures[0];
            for (let j = 0; j < tx.transaction.message.instructions.length; j++) {
                const ix = tx.transaction.message.instructions[j];
                if (!ix || !ix.data)
                    continue;
                const decodedIxData = bytes_1.bs58.decode(ix.data);
                const ixIdlFileVersion = decodedIxData.length >= 9 ? decodedIxData.slice(8, 9)[0] : 0;
                let activity = null;
                activity = yield parseVestingTreasuryInstruction(ix, treasuryAddress, signature, (_a = tx.blockTime) !== null && _a !== void 0 ? _a : 0, ixIdlFileVersion, programId);
                if (!activity) {
                    continue;
                }
                parsedActivities.push(activity);
            }
        }
        return parsedActivities;
    });
}
function parseVestingTreasuryInstruction(ix, treasuryAddress, transactionSignature, transactionBlockTimeInSeconds, idlFileVersion, programId) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
    return __awaiter(this, void 0, void 0, function* () {
        if (!ix.programId.equals(programId)) {
            return null;
        }
        try {
            if (!idls[idlFileVersion]) {
                if (idlFileVersion === 1) {
                    // TODO: to avoid this if else, find a way to do dynamic imports passign concatenated paths
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_001')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 2) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_002')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 3) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_003')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else if (idlFileVersion === 4) {
                    const importedIdl = yield Promise.resolve().then(() => __importStar(require('./msp_idl_004')));
                    idls[idlFileVersion] = importedIdl.IDL;
                }
                else {
                    return null;
                }
            }
            const coder = new anchor_1.BorshInstructionCoder(idls[idlFileVersion]);
            const decodedIx = coder.decode(ix.data, 'base58');
            if (!decodedIx)
                return null;
            const ixName = decodedIx.name;
            // console.log(`ixName: ${ixName}`);
            if ([
                'createTreasuryAndTemplate',
                'createStreamWithTemplate',
                'addFunds',
                'modifyStreamTemplate',
                'treasuryWithdraw',
                'withdraw',
                'closeStream',
                'pauseStream',
                'resumeStream',
                'allocate',
                'refreshTreasuryData',
            ].indexOf(ixName) === -1)
                return null;
            const ixAccountMetas = ix.accounts.map(pk => {
                return { pubkey: pk, isSigner: false, isWritable: false };
            });
            const formattedIx = coder.format(decodedIx, ixAccountMetas);
            // console.log(formattedIx);
            // console.table(
            //   formattedIx?.accounts.map(a => {
            //     return { name: a.name, pk: a.pubkey.toBase58() };
            //   }),
            // );
            const treasury = (_a = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasury')) === null || _a === void 0 ? void 0 : _a.pubkey;
            if (!treasury || !treasury.equals(treasuryAddress)) {
                return null;
            }
            const blockTime = transactionBlockTimeInSeconds * 1000; // mult by 1000 to add milliseconds
            let action = types_1.VestingTreasuryActivityAction.StreamCreate;
            let initializer;
            let mint;
            let amount;
            let template;
            let beneficiary;
            let destination;
            let destinationTokenAccount;
            let stream;
            if (decodedIx.name === 'createTreasuryAndTemplate') {
                action = types_1.VestingTreasuryActivityAction.TreasuryCreate;
                initializer = (_b = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _b === void 0 ? void 0 : _b.pubkey;
                mint = (_c = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _c === void 0 ? void 0 : _c.pubkey;
                template = (_d = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Template')) === null || _d === void 0 ? void 0 : _d.pubkey;
            }
            else if (decodedIx.name === 'modifyStreamTemplate') {
                action = types_1.VestingTreasuryActivityAction.TreasuryModify;
                initializer = (_e = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _e === void 0 ? void 0 : _e.pubkey;
                template = (_f = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Template')) === null || _f === void 0 ? void 0 : _f.pubkey;
            }
            else if (decodedIx.name === 'createStreamWithTemplate') {
                action = types_1.VestingTreasuryActivityAction.StreamCreate;
                stream = (_g = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _g === void 0 ? void 0 : _g.pubkey;
                initializer = (_h = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _h === void 0 ? void 0 : _h.pubkey;
                template = (_j = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Template')) === null || _j === void 0 ? void 0 : _j.pubkey;
                mint = (_k = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _k === void 0 ? void 0 : _k.pubkey;
                beneficiary = (_l = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _l === void 0 ? void 0 : _l.pubkey;
                const parsedAmount = (_m = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'allocationAssignedUnits')) === null || _m === void 0 ? void 0 : _m.data;
                amount = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'addFunds') {
                action = types_1.VestingTreasuryActivityAction.TreasuryAddFunds;
                initializer = (_o = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _o === void 0 ? void 0 : _o.pubkey;
                mint = (_p = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _p === void 0 ? void 0 : _p.pubkey;
                const parsedAmount = (_q = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _q === void 0 ? void 0 : _q.data;
                amount = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'treasuryWithdraw') {
                action = types_1.VestingTreasuryActivityAction.TreasuryWithdraw;
                initializer = (_r = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _r === void 0 ? void 0 : _r.pubkey;
                mint = (_s = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _s === void 0 ? void 0 : _s.pubkey;
                destination = (_t = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Destination Authority')) === null || _t === void 0 ? void 0 : _t.pubkey;
                destinationTokenAccount = (_u = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Destination Token Account')) === null || _u === void 0 ? void 0 : _u.pubkey;
                const parsedAmount = (_v = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _v === void 0 ? void 0 : _v.data;
                amount = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'withdraw') {
                action = types_1.VestingTreasuryActivityAction.StreamWithdraw;
                stream = (_w = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _w === void 0 ? void 0 : _w.pubkey;
                initializer = (_x = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _x === void 0 ? void 0 : _x.pubkey;
                beneficiary = (_y = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _y === void 0 ? void 0 : _y.pubkey;
                mint = (_z = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _z === void 0 ? void 0 : _z.pubkey;
                const parsedAmount = (_0 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _0 === void 0 ? void 0 : _0.data;
                amount = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'allocate') {
                action = types_1.VestingTreasuryActivityAction.StreamAllocateFunds;
                stream = (_1 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _1 === void 0 ? void 0 : _1.pubkey;
                initializer = (_2 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _2 === void 0 ? void 0 : _2.pubkey;
                mint = (_3 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _3 === void 0 ? void 0 : _3.pubkey;
                const parsedAmount = (_4 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.args.find(a => a.name === 'amount')) === null || _4 === void 0 ? void 0 : _4.data;
                amount = parsedAmount ? new anchor_1.BN(parsedAmount) : undefined;
            }
            else if (decodedIx.name === 'closeStream') {
                action = types_1.VestingTreasuryActivityAction.StreamClose;
                stream = (_5 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _5 === void 0 ? void 0 : _5.pubkey;
                initializer = (_6 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _6 === void 0 ? void 0 : _6.pubkey;
                beneficiary = (_7 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Beneficiary')) === null || _7 === void 0 ? void 0 : _7.pubkey;
                mint = (_8 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _8 === void 0 ? void 0 : _8.pubkey;
            }
            else if (decodedIx.name === 'pauseStream') {
                action = types_1.VestingTreasuryActivityAction.StreamPause;
                stream = (_9 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _9 === void 0 ? void 0 : _9.pubkey;
                initializer = (_10 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Initializer')) === null || _10 === void 0 ? void 0 : _10.pubkey;
            }
            else if (decodedIx.name === 'resumeStream') {
                action = types_1.VestingTreasuryActivityAction.StreamResume;
                stream = (_11 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Stream')) === null || _11 === void 0 ? void 0 : _11.pubkey;
                initializer = (_12 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Initializer')) === null || _12 === void 0 ? void 0 : _12.pubkey;
            }
            else if (decodedIx.name === 'refreshTreasuryData') {
                action = types_1.VestingTreasuryActivityAction.TreasuryRefresh;
                initializer = (_13 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Treasurer')) === null || _13 === void 0 ? void 0 : _13.pubkey;
                mint = (_14 = formattedIx === null || formattedIx === void 0 ? void 0 : formattedIx.accounts.find(a => a.name === 'Associated Token')) === null || _14 === void 0 ? void 0 : _14.pubkey;
            }
            return {
                signature: transactionSignature,
                action,
                template,
                amount,
                beneficiary,
                blockTime,
                destination,
                destinationTokenAccount,
                initializer,
                mint,
                stream,
                utcDate: new Date(blockTime).toUTCString(),
            };
        }
        catch (error) {
            console.log(error);
            return null;
        }
    });
}
