"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MSP = void 0;
/**
 * Solana
 */
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const anchor_1 = require("@project-serum/anchor");
/**
 * MSP
 */
const types_1 = require("./types");
const types_2 = require("./types");
const utils_1 = require("./utils");
const utils_2 = require("./utils");
const utils_3 = require("./utils");
const constants_1 = require("./constants");
const constants_2 = require("./constants");
const _1 = require(".");
const u64n_1 = require("./u64n");
/**
 * API class with functions to interact with the Money Streaming Program using Solana Web3 JS API
 */
class MSP {
    /**
     * Create a Streaming API object
     *
     * @param cluster The solana cluster endpoint used for the connecton
     */
    constructor(rpcUrl, programId, commitment = 'finalized') {
        this.commitment = commitment;
        this.connection = new web3_js_1.Connection(rpcUrl, this.commitment || 'finalized');
        this.program = (0, utils_1.createProgram)(this.connection, programId);
    }
    getStream(id, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, utils_1.getStream)(this.program, id, friendly);
        });
    }
    refreshStream(streamInfo, hardUpdate = false, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const copyStreamInfo = Object.assign({}, streamInfo);
            if (hardUpdate) {
                const streamId = typeof copyStreamInfo.id === 'string'
                    ? new web3_js_1.PublicKey(copyStreamInfo.id)
                    : copyStreamInfo.id;
                return yield (0, utils_1.getStream)(this.program, streamId);
            }
            return (0, utils_1.getStreamCached)(copyStreamInfo, friendly);
        });
    }
    listStreams({ treasurer, treasury, beneficiary, friendly = true, category = undefined, subCategory = undefined, }) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, utils_1.listStreams)(this.program, treasurer, treasury, beneficiary, friendly, category, subCategory);
        });
    }
    refreshStreams(streamInfoList, treasurer, treasury, beneficiary, hardUpdate = false, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (hardUpdate) {
                return yield (0, utils_1.listStreams)(this.program, treasurer, treasury, beneficiary, friendly);
            }
            return (0, utils_1.listStreamsCached)(streamInfoList, friendly);
        });
    }
    /**
     *
     * @param id The address of the stream
     * @param before The signature to start searching backwards from.
     * @param limit The max amount of elements to retrieve
     * @param commitment Commitment to query the stream activity
     * @param friendly The data will be displayed in a user readable format
     * @returns
     */
    listStreamActivity(id, before, limit = 10, commitment, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountInfo = yield this.connection.getAccountInfo(id, commitment);
            if (!accountInfo) {
                throw Error("Stream doesn't exists");
            }
            return (0, utils_1.listStreamActivity)(this.program, id, before, limit, commitment, friendly);
        });
    }
    getTreasury(id, commitment, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountInfo = yield this.program.account.treasury.getAccountInfo(id, commitment);
            if (!accountInfo) {
                throw Error("Treasury doesn't exists");
            }
            return (0, utils_1.getTreasury)(this.program, id, friendly);
        });
    }
    listTreasuries(treasurer, friendly = true, excludeAutoClose, category, subCategory) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, _1.listTreasuries)(this.program, treasurer, friendly, excludeAutoClose, category, subCategory);
        });
    }
    getStreamTemplate(treasury, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const [template] = yield (0, utils_3.findStreamTemplateAddress)(treasury, this.program.programId);
            return (0, utils_2.getStreamTemplate)(this.program, template, friendly);
        });
    }
    transfer(sender, beneficiary, mint, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const ixs = [];
            if (mint.equals(constants_1.Constants.SOL_MINT)) {
                ixs.push(web3_js_1.SystemProgram.transfer({
                    fromPubkey: sender,
                    toPubkey: beneficiary,
                    lamports: amount,
                }));
            }
            else {
                const senderToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, sender, true);
                const senderTokenInfo = yield this.connection.getAccountInfo(senderToken);
                if (!senderTokenInfo) {
                    throw Error('Sender token account not found');
                }
                let beneficiaryToken = beneficiary;
                const beneficiaryAccountInfo = yield this.connection.getAccountInfo(beneficiary);
                if (!beneficiaryAccountInfo ||
                    !beneficiaryAccountInfo.owner.equals(spl_token_1.TOKEN_PROGRAM_ID)) {
                    beneficiaryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, beneficiary, true);
                    const beneficiaryTokenAccountInfo = yield this.connection.getAccountInfo(beneficiaryToken);
                    if (!beneficiaryTokenAccountInfo) {
                        ixs.push(spl_token_1.Token.createAssociatedTokenAccountInstruction(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, beneficiaryToken, beneficiary, sender));
                    }
                }
                else {
                    // At this point the beneficiaryToken is either a mint or a token account
                    // Let's make sure it is a token account of the passed mint
                    const tokenClient = new spl_token_1.Token(this.connection, mint, spl_token_1.TOKEN_PROGRAM_ID, web3_js_1.Keypair.generate());
                    try {
                        const beneficiaryTokenInfo = yield tokenClient.getAccountInfo(beneficiaryToken);
                        if (!beneficiaryTokenInfo)
                            throw Error('Reciever is not a token account');
                    }
                    catch (error) {
                        throw Error('Reciever is not a token account');
                    }
                }
                ixs.push(spl_token_1.Token.createTransferInstruction(spl_token_1.TOKEN_PROGRAM_ID, senderToken, beneficiaryToken, sender, [], amount));
            }
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = sender;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    scheduledTransfer(treasurer, beneficiary, mint, amount, startUtc, streamName, feePayedByTreasurer = false, category = types_2.Category.default, subCategory = types_1.SubCategory.default) {
        return __awaiter(this, void 0, void 0, function* () {
            let autoWSol = false;
            if (mint.equals(constants_1.Constants.SOL_MINT)) {
                mint = spl_token_1.NATIVE_MINT;
                autoWSol = true;
            }
            const ixs = [];
            const txSigners = [];
            const now = new Date();
            const start = !startUtc || startUtc.getTime() < now.getTime() ? now : startUtc;
            const treasurerToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, treasurer, true);
            const treasurerTokenInfo = yield this.connection.getAccountInfo(treasurerToken);
            yield this.ensureAutoWrapSolInstructions(autoWSol, amount, treasurer, treasurerToken, treasurerTokenInfo, ixs, txSigners);
            // Create the treasury account since the OTP is schedule
            const slot = yield this.connection.getSlot(this.commitment);
            const slotBuffer = new u64n_1.u64Number(slot).toBuffer();
            const treasurySeeds = [treasurer.toBuffer(), slotBuffer];
            const [treasury] = yield web3_js_1.PublicKey.findProgramAddress(treasurySeeds, this.program.programId);
            const treasuryMintSeeds = [
                treasurer.toBuffer(),
                treasury.toBuffer(),
                slotBuffer,
            ];
            const [treasuryMint] = yield web3_js_1.PublicKey.findProgramAddress(treasuryMintSeeds, this.program.programId);
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, treasury, true);
            // Get the treasury pool treasurer token
            const treasurerTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, treasurer, true);
            // Create treasury
            ixs.push(this.program.instruction.createTreasury(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(slot), streamName !== null && streamName !== void 0 ? streamName : '', types_1.TreasuryType.Open, true, // autoclose = true
            false, // sol fee payed by treasury
            { [types_2.Category[category]]: {} }, { [types_1.SubCategory[subCategory]]: {} }, {
                accounts: {
                    payer: treasurer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            // // Create treasury
            // const ix1 = this.program.methods.createTreasury(
            //   new BN(slot),
            //   streamName,
            //   TreasuryType.Open,
            //   true, // autoclose = true
            //   false, // sol fee payed by treasury
            // )
            //   .accounts({
            //     payer: treasurer,
            //     treasurer: treasurer,
            //     treasury: treasury,
            //     treasuryMint: treasuryMint,
            //     treasuryToken: treasuryToken,
            //     associatedToken: mint,
            //     feeTreasury: Constants.FEE_TREASURY,
            //     associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            //     tokenProgram: TOKEN_PROGRAM_ID,
            //     systemProgram: SystemProgram.programId,
            //     rent: SYSVAR_RENT_PUBKEY
            //   })
            //   .instruction();
            // ixs.push(ix1);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, constants_1.Constants.FEE_TREASURY, true);
            // Add Funds
            ixs.push(this.program.instruction.addFunds(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: treasurer,
                    contributor: treasurer,
                    contributorToken: treasurerToken,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            // Create stream account since the OTP is scheduled
            const streamAccount = web3_js_1.Keypair.generate();
            txSigners.push(streamAccount);
            const startUtcInSeconds = parseInt((start.getTime() / 1000).toString());
            // Create Stream
            ixs.push(this.program.instruction.createStream(constants_2.LATEST_IDL_FILE_VERSION, streamName !== null && streamName !== void 0 ? streamName : '', new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(0), // rate amount units
            new anchor_1.BN(0), // rate interval in seconds
            new anchor_1.BN(amount), // allocation assigned
            new anchor_1.BN(amount), // cliff vest amount
            new anchor_1.BN(0), // cliff vest percent
            feePayedByTreasurer, {
                accounts: {
                    payer: treasurer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    beneficiary: beneficiary,
                    stream: streamAccount.publicKey,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
                signers: [streamAccount],
            }));
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = treasurer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    streamPayment(treasurer, beneficiary, mint, streamName, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer = false, category = types_2.Category.default, subCategory = types_1.SubCategory.default) {
        return __awaiter(this, void 0, void 0, function* () {
            if (treasurer.equals(beneficiary)) {
                throw Error('Beneficiary can not be the same Treasurer');
            }
            let autoWSol = false;
            if (mint.equals(constants_1.Constants.SOL_MINT)) {
                mint = spl_token_1.NATIVE_MINT;
                autoWSol = true;
            }
            const ixs = [];
            const txSigners = [];
            const now = new Date();
            const start = !startUtc || startUtc.getTime() < Date.now() ? now : startUtc;
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, constants_1.Constants.FEE_TREASURY, true);
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            const slot = yield this.connection.getSlot(this.commitment || 'finalized');
            const slotBuffer = new u64n_1.u64Number(slot).toBuffer();
            const treasurySeeds = [treasurer.toBuffer(), slotBuffer];
            const [treasury] = yield web3_js_1.PublicKey.findProgramAddress(treasurySeeds, this.program.programId);
            const treasuryMintSeeds = [
                treasurer.toBuffer(),
                treasury.toBuffer(),
                slotBuffer,
            ];
            const [treasuryMint] = yield web3_js_1.PublicKey.findProgramAddress(treasuryMintSeeds, this.program.programId);
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, treasury, true);
            // Get the treasury pool treasurer token
            const treasurerTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, treasurer, true);
            // Create treasury
            ixs.push(this.program.instruction.createTreasury(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(slot), streamName, types_1.TreasuryType.Open, true, // autoclose = true
            false, // sol fee payed by treasury
            { [types_2.Category[category]]: {} }, { [types_1.SubCategory[subCategory]]: {} }, {
                accounts: {
                    payer: treasurer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            // Get the treasurer token account
            const treasurerToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, treasurer, true);
            const treasurerTokenInfo = yield this.connection.getAccountInfo(treasurerToken);
            yield this.ensureAutoWrapSolInstructions(autoWSol, allocationAssigned, treasurer, treasurerToken, treasurerTokenInfo, ixs, txSigners);
            // Add Funds
            ixs.push(this.program.instruction.addFunds(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(allocationAssigned), {
                accounts: {
                    payer: treasurer,
                    contributor: treasurer,
                    contributorToken: treasurerToken,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            const streamAccount = web3_js_1.Keypair.generate();
            txSigners.push(streamAccount);
            const startUtcInSeconds = parseInt((start.getTime() / 1000).toString());
            // Create Stream
            ixs.push(this.program.instruction.createStream(constants_2.LATEST_IDL_FILE_VERSION, streamName, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateAmount !== null && rateAmount !== void 0 ? rateAmount : 0), // rate amount units
            new anchor_1.BN(rateIntervalInSeconds !== null && rateIntervalInSeconds !== void 0 ? rateIntervalInSeconds : 0), // rate interval in seconds
            new anchor_1.BN(allocationAssigned), // allocation assigned
            new anchor_1.BN(cliffVestAmount !== null && cliffVestAmount !== void 0 ? cliffVestAmount : 0), // cliff vest amount
            new anchor_1.BN((cliffVestPercent !== null && cliffVestPercent !== void 0 ? cliffVestPercent : 0) * 10000), // cliff vest percent
            feePayedByTreasurer, {
                accounts: {
                    payer: treasurer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    beneficiary: beneficiary,
                    stream: streamAccount.publicKey,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
                signers: [streamAccount],
            }));
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = treasurer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    createTreasury(payer, treasurer, associatedTokenMint, label, type, solFeePayedByTreasury = false, category = types_2.Category.default, subCategory = types_1.SubCategory.default) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.createTreasury2(payer, treasurer, associatedTokenMint, label, type, solFeePayedByTreasury, category, subCategory))[0];
        });
    }
    /**
     * This one returns not only the transaction but also the address of the
     * treasury that will be created
     */
    createTreasury2(payer, treasurer, associatedTokenMint, label, type, solFeePayedByTreasury = false, category = types_2.Category.default, subCategory = types_1.SubCategory.default) {
        return __awaiter(this, void 0, void 0, function* () {
            const slot = yield this.connection.getSlot(this.commitment || 'finalized');
            const slotBuffer = new u64n_1.u64Number(slot).toBuffer();
            const treasurySeeds = [treasurer.toBuffer(), slotBuffer];
            // Treasury Pool PDA
            const [treasury] = yield web3_js_1.PublicKey.findProgramAddress(treasurySeeds, this.program.programId);
            const treasuryPoolMintSeeds = [
                treasurer.toBuffer(),
                treasury.toBuffer(),
                slotBuffer,
            ];
            // Treasury Pool Mint PDA
            const [treasuryMint] = yield web3_js_1.PublicKey.findProgramAddress(treasuryPoolMintSeeds, this.program.programId);
            if (associatedTokenMint.equals(constants_1.Constants.SOL_MINT)) {
                associatedTokenMint = spl_token_1.NATIVE_MINT;
            }
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedTokenMint, treasury, true);
            const tx = this.program.transaction.createTreasury(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(slot), label, type, false, // autoclose = false
            solFeePayedByTreasury, { [types_2.Category[category]]: {} }, { [types_1.SubCategory[subCategory]]: {} }, {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: associatedTokenMint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            tx.feePayer = treasurer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return [tx, treasury];
        });
    }
    createStream(payer, treasurer, treasury, beneficiary, streamName, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            const [tx] = yield this.createStream2(payer, treasurer, treasury, beneficiary, streamName, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer);
            return tx;
        });
    }
    /**
     * This one returns not only the transaction but also the address of the
     * stream that will be created
     */
    createStream2(payer, treasurer, treasury, beneficiary, streamName, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            if (treasurer.equals(beneficiary)) {
                throw Error('Beneficiary can not be the same Treasurer');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            const now = new Date();
            const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
            const startUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            const streamAccount = web3_js_1.Keypair.generate();
            // Create Stream
            const tx = this.program.transaction.createStream(constants_2.LATEST_IDL_FILE_VERSION, streamName, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateAmount), new anchor_1.BN(rateIntervalInSeconds), new anchor_1.BN(allocationAssigned), new anchor_1.BN(cliffVestAmount), new anchor_1.BN(cliffVestPercentValue), feePayedByTreasurer !== null && feePayedByTreasurer !== void 0 ? feePayedByTreasurer : false, {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    beneficiary: beneficiary,
                    stream: streamAccount.publicKey,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
                signers: [streamAccount],
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            tx.partialSign(...[streamAccount]);
            return [tx, streamAccount.publicKey];
        });
    }
    /**
     * This creates a vesting stream treasury with template.
     */
    createVestingTreasury(payer, treasurer, label, type, solFeePayedByTreasury, treasuryAssociatedTokenMint, duration, durationUnit, fundingAmount, vestingCategory, startUtc, cliffVestPercent = 0, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            // convert duration to seconds
            const rateIntervalInSeconds = durationUnit;
            const slot = yield this.connection.getSlot(this.commitment || 'finalized');
            const slotBuffer = new u64n_1.u64Number(slot).toBuffer();
            const treasurySeeds = [treasurer.toBuffer(), slotBuffer];
            // Treasury Pool PDA
            const [treasury] = yield web3_js_1.PublicKey.findProgramAddress(treasurySeeds, this.program.programId);
            let autoWSol = false;
            if (treasuryAssociatedTokenMint.equals(constants_1.Constants.SOL_MINT)) {
                treasuryAssociatedTokenMint = spl_token_1.NATIVE_MINT;
                autoWSol = true;
            }
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            const now = new Date();
            const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
            const startUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            // Template address
            const [template] = yield (0, utils_3.findStreamTemplateAddress)(treasury, this.program.programId);
            const tx = this.program.transaction.createTreasuryAndTemplate(constants_2.LATEST_IDL_FILE_VERSION, label, type, false, solFeePayedByTreasury, { [types_2.Category[types_2.Category.vesting]]: {} }, { [types_1.SubCategory[vestingCategory]]: {} }, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateIntervalInSeconds), new anchor_1.BN(duration), new anchor_1.BN(cliffVestPercentValue), feePayedByTreasurer !== null && feePayedByTreasurer !== void 0 ? feePayedByTreasurer : false, new anchor_1.BN(slot), {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    template,
                    associatedToken: treasuryAssociatedTokenMint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            const addFundsSigners = [];
            if (fundingAmount > 0) {
                const contributorToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, payer, true);
                const contributorTokenInfo = yield this.connection.getAccountInfo(contributorToken, 'recent');
                const ixs = [];
                yield this.ensureAutoWrapSolInstructions(autoWSol, fundingAmount, payer, contributorToken, contributorTokenInfo, ixs, addFundsSigners);
                const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
                const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
                ixs.push(this.program.instruction.addFunds(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(fundingAmount), {
                    accounts: {
                        payer: payer,
                        contributor: payer,
                        contributorToken: contributorToken,
                        treasury: treasury,
                        treasuryToken: treasuryToken,
                        associatedToken: treasuryAssociatedTokenMint,
                        feeTreasury: constants_1.Constants.FEE_TREASURY,
                        feeTreasuryToken: feeTreasuryToken,
                        associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                        tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                        systemProgram: web3_js_1.SystemProgram.programId,
                        rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                    },
                }));
                tx.add(...ixs);
            }
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (addFundsSigners.length > 0) {
                tx.partialSign(...addFundsSigners);
            }
            return [tx, treasury];
        });
    }
    /**
     * This modifies values of vesting treasury
     * template if no streams have been created yet.
     */
    modifyVestingTreasuryTemplate(payer, treasurer, vestingTreasury, duration, durationUnit, startUtc, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, vestingTreasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            // Get the template
            const [templateAddress] = yield (0, utils_3.findStreamTemplateAddress)(vestingTreasury, this.program.programId);
            const templateInfo = yield (0, utils_2.getStreamTemplate)(this.program, templateAddress);
            if (!templateInfo) {
                throw Error("Template doesn't exist");
            }
            if (treasuryInfo.totalStreams > 0) {
                throw Error('Cannot modify vesting treasury info after streams have been created');
            }
            if (duration && !durationUnit) {
                throw Error('Duration unit is required');
            }
            if (durationUnit && !duration) {
                throw Error('Duration is required');
            }
            let updatedRateIntervalInSeconds = templateInfo.rateIntervalInSeconds;
            let updatedDuration = templateInfo.durationNumberOfUnits;
            if (duration && durationUnit) {
                updatedRateIntervalInSeconds = durationUnit;
                updatedDuration = duration;
            }
            let updatedClifPercentValue = templateInfo.cliffVestPercent;
            if (cliffVestPercent) {
                updatedClifPercentValue =
                    cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR;
            }
            let updatedStartUtcInSeconds = parseInt((new Date(templateInfo.startUtc).getTime() / 1000).toString());
            if (startUtc) {
                const now = new Date();
                const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
                updatedStartUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            }
            let updatedFeePayedByTreasurer = templateInfo.feePayedByTreasurer;
            if (feePayedByTreasurer !== undefined) {
                updatedFeePayedByTreasurer = feePayedByTreasurer;
            }
            const tx = yield this.program.methods
                .modifyStreamTemplate(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(updatedStartUtcInSeconds), new anchor_1.BN(updatedRateIntervalInSeconds), new anchor_1.BN(updatedDuration), new anchor_1.BN(updatedClifPercentValue), updatedFeePayedByTreasurer)
                .accounts({
                payer: payer,
                template: templateAddress,
                treasurer: treasurer,
                treasury: vestingTreasury,
            })
                .transaction();
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    /**
     *
     * @param id The address of the treasury
     * @param before The signature to start searching backwards from.
     * @param limit The max amount of elements to retrieve
     * @param commitment Commitment to query the treasury activity
     * @param friendly The data will be displayed in a user readable format
     * @returns
     */
    listVestingTreasuryActivity(id, before, limit = 10, commitment, friendly = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const accountInfo = yield this.connection.getAccountInfo(id, commitment);
            if (!accountInfo) {
                throw Error("Treasury doesn't exists");
            }
            return (0, utils_1.listVestingTreasuryActivity)(this.program, id, before, limit, commitment, friendly);
        });
    }
    /**
     * Gets the flowing rate of a vesting contract.
     * @param vestingTreasury The address of the treasury
     * @returns a tuple of the amount, the time unit ([20, TimeUnit.Week] == 20/week)
     * and total allocation of all streams
     */
    getVestingFlowRate(vestingTreasury) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, vestingTreasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            // Get the template
            const [templateAddress] = yield (0, utils_3.findStreamTemplateAddress)(vestingTreasury, this.program.programId);
            const templateInfo = yield (0, utils_2.getStreamTemplate)(this.program, templateAddress);
            if (!templateInfo) {
                throw Error("Stream template doesn't exist");
            }
            if (treasuryInfo.totalStreams === 0)
                return [0, templateInfo.rateIntervalInSeconds, 0];
            const streams = yield (0, utils_1.listStreams)(this.program, undefined, vestingTreasury, undefined);
            let streamRate = 0;
            let totalAllocation = 0;
            for (const stream of streams) {
                totalAllocation = totalAllocation + stream.allocationAssigned;
                switch (stream.status) {
                    case types_1.STREAM_STATUS.Paused:
                    case types_1.STREAM_STATUS.Schedule:
                        continue;
                }
                if (stream.remainingAllocationAmount <= 0) {
                    // all streamed
                    continue;
                }
                const rateAmount = (stream.allocationAssigned *
                    (1 - templateInfo.cliffVestPercent / 1000000)) /
                    templateInfo.durationNumberOfUnits;
                streamRate = streamRate + rateAmount;
            }
            return [
                streamRate,
                templateInfo.rateIntervalInSeconds,
                totalAllocation,
            ];
        });
    }
    /**
     * This creates a stream with template
     */
    createStreamWithTemplate(payer, treasurer, treasury, beneficiary, allocationAssigned, streamName = '') {
        return __awaiter(this, void 0, void 0, function* () {
            if (treasurer.equals(beneficiary)) {
                throw Error('Beneficiary can not be the same Treasurer');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            // Get the template
            const [template] = yield (0, utils_3.findStreamTemplateAddress)(treasury, this.program.programId);
            const templateInfo = yield (0, utils_2.getStreamTemplate)(this.program, template);
            if (!templateInfo) {
                throw Error("Stream template doesn't exist");
            }
            // Calculate rate amount
            const rateAmount = (allocationAssigned * (1 - templateInfo.cliffVestPercent / 1000000)) /
                templateInfo.durationNumberOfUnits;
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const streamAccount = web3_js_1.Keypair.generate();
            // Create Stream
            const tx = this.program.transaction.createStreamWithTemplate(constants_2.LATEST_IDL_FILE_VERSION, streamName, new anchor_1.BN(rateAmount), new anchor_1.BN(allocationAssigned), {
                accounts: {
                    payer: payer,
                    template,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    beneficiary: beneficiary,
                    stream: streamAccount.publicKey,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
                signers: [streamAccount],
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            tx.partialSign(...[streamAccount]);
            return [tx, streamAccount.publicKey];
        });
    }
    /**
     * This creates a stream with template with PDA
     */
    createStreamWithTemplateFromPda(payer, treasurer, treasury, stream, beneficiary, allocationAssigned, streamName = '') {
        return __awaiter(this, void 0, void 0, function* () {
            if (treasurer.equals(beneficiary)) {
                throw Error('Beneficiary can not be the same Treasurer');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            // Get the template
            const [template] = yield (0, utils_3.findStreamTemplateAddress)(treasury, this.program.programId);
            const templateInfo = yield (0, utils_2.getStreamTemplate)(this.program, template);
            if (!templateInfo) {
                throw Error("Stream template doesn't exist");
            }
            // Calculate rate amount
            const rateAmount = (allocationAssigned * (1 - templateInfo.cliffVestPercent / 1000000)) /
                templateInfo.durationNumberOfUnits;
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            // Create Stream
            const tx = this.program.transaction.createStreamWithTemplate(constants_2.LATEST_IDL_FILE_VERSION, streamName, new anchor_1.BN(rateAmount), new anchor_1.BN(allocationAssigned), {
                accounts: {
                    payer: payer,
                    template,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    beneficiary: beneficiary,
                    stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getLatestBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    createStreams(payer, treasurer, treasury, beneficiaries, associatedToken, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            if (treasuryInfo.associatedToken !== associatedToken.toBase58()) {
                throw Error('Incorrect associated token address');
            }
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, constants_1.Constants.FEE_TREASURY, true);
            const now = new Date();
            const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
            const startUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            // Create Streams
            const txs = [];
            const group = (size, data) => {
                const result = [];
                for (let i = 0; i < data.length; i += size) {
                    result.push(data.slice(i, i + size));
                }
                return result;
            };
            for (const groupItem of group(3, beneficiaries)) {
                const signers = [];
                const ixs = [];
                for (const beneficiary of groupItem) {
                    if (beneficiary.address.toBase58() === treasurer.toBase58()) {
                        continue;
                    }
                    const streamAccount = web3_js_1.Keypair.generate();
                    const ix = this.program.instruction.createStream(constants_2.LATEST_IDL_FILE_VERSION, beneficiary.streamName, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateAmount), new anchor_1.BN(rateIntervalInSeconds), new anchor_1.BN(allocationAssigned), new anchor_1.BN(cliffVestAmount), new anchor_1.BN(cliffVestPercentValue), feePayedByTreasurer !== null && feePayedByTreasurer !== void 0 ? feePayedByTreasurer : false, {
                        accounts: {
                            payer: payer,
                            treasurer: treasurer,
                            treasury: treasury,
                            treasuryToken: treasuryToken,
                            associatedToken: associatedToken,
                            beneficiary: beneficiary.address,
                            stream: streamAccount.publicKey,
                            feeTreasury: constants_1.Constants.FEE_TREASURY,
                            feeTreasuryToken: feeTreasuryToken,
                            associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                            systemProgram: web3_js_1.SystemProgram.programId,
                            rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                        },
                    });
                    ixs.push(ix);
                    signers.push(streamAccount);
                }
                const tx = new web3_js_1.Transaction().add(...ixs);
                tx.feePayer = payer;
                const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
                tx.recentBlockhash = blockhash;
                tx.partialSign(...signers);
                txs.push(tx);
            }
            return txs;
        });
    }
    fundStream(payer, contributor, treasury, stream, amount, autoWSol = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const ixs = [];
            const txSigners = [];
            if (!amount) {
                throw Error('Amount should be greater than 0');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error('Treasury account not found');
            }
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error('Stream account not found');
            }
            if (treasuryInfo.associatedToken !== streamInfo.associatedToken) {
                throw Error('Invalid stream beneficiary associated token');
            }
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            const treasuryMint = new web3_js_1.PublicKey(treasuryInfo.mint);
            const contributorToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, contributor, true);
            const contributorTokenInfo = yield this.connection.getAccountInfo(contributorToken, 'recent'); // TODO: standarized commitment
            yield this.ensureAutoWrapSolInstructions(autoWSol, amount, contributor, contributorToken, contributorTokenInfo, ixs, txSigners);
            const contributorTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, contributor, true);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            ixs.push(this.program.instruction.addFunds(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: payer,
                    contributor: contributor,
                    contributorToken: contributorToken,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            // calculate fee if are payed by treasury to deduct it from the amount
            let allocationAmountBn = new anchor_1.BN(amount);
            if (streamInfo.feePayedByTreasurer) {
                allocationAmountBn = yield (0, utils_1.getValidTreasuryAllocation)(this.program.provider.connection, treasuryInfo, amount);
            }
            ixs.push(this.program.instruction.allocate(constants_2.LATEST_IDL_FILE_VERSION, allocationAmountBn, {
                accounts: {
                    payer: payer,
                    treasurer: contributor,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    stream: stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    addFunds(payer, contributor, treasury, mint, // it can be the special value: Constants.SOL_MINT
    amount) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!amount) {
                throw Error('Amount should be greater than 0');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error('Treasury account not found');
            }
            let autoWSol = false;
            if (mint.equals(constants_1.Constants.SOL_MINT)) {
                mint = spl_token_1.NATIVE_MINT;
                autoWSol = true;
            }
            const treasuryMint = new web3_js_1.PublicKey(treasuryInfo.mint);
            const contributorToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, contributor, true);
            const contributorTokenInfo = yield this.connection.getAccountInfo(contributorToken, 'recent');
            const ixs = [];
            const txSigners = [];
            yield this.ensureAutoWrapSolInstructions(autoWSol, amount, contributor, contributorToken, contributorTokenInfo, ixs, txSigners);
            const contributorTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, contributor, true);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, mint, constants_1.Constants.FEE_TREASURY, true);
            ixs.push(this.program.instruction.addFunds(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: payer,
                    contributor: contributor,
                    contributorToken: contributorToken,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: mint,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            }));
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    allocate(payer, treasurer, treasury, stream, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!amount) {
                throw Error('Amount should be greater than 0');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error('Treasury account not found');
            }
            if (treasuryInfo.treasurer !== treasurer.toBase58()) {
                throw Error('Invalid treasurer');
            }
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error('Stream account not found');
            }
            if (treasuryInfo.associatedToken !== streamInfo.associatedToken) {
                throw Error('Invalid stream beneficiary associated token');
            }
            const associatedToken = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, constants_1.Constants.FEE_TREASURY, true);
            const tx = this.program.transaction.allocate(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: associatedToken,
                    stream: stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    withdraw(payer, stream, amount, autoWSol = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!amount) {
                throw Error('Amount should be greater than 0');
            }
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error("Stream doesn't exist");
            }
            if (streamInfo.status === types_1.STREAM_STATUS.Schedule) {
                throw Error('Stream has not started');
            }
            if (streamInfo.withdrawableAmount === 0) {
                throw Error('Stream withdrawable amount is zero');
            }
            const beneficiary = new web3_js_1.PublicKey(streamInfo.beneficiary);
            // Check for the beneficiary associated token account
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(streamInfo.associatedToken);
            const beneficiaryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, beneficiary, true);
            const treasury = new web3_js_1.PublicKey(streamInfo.treasury);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const ixs = [];
            const txSigners = [];
            const withdrawIx = this.program.instruction.withdraw(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: payer,
                    beneficiary: beneficiary,
                    beneficiaryToken: beneficiaryToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    stream: stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            ixs.push(withdrawIx);
            // unwrap all on exit
            if (autoWSol && treasuryAssociatedTokenMint.equals(spl_token_1.NATIVE_MINT)) {
                const closeWSolIx = spl_token_1.Token.createCloseAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, beneficiaryToken, beneficiary, beneficiary, []);
                ixs.push(closeWSolIx);
            }
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    pauseStream(payer, treasurer, stream) {
        return __awaiter(this, void 0, void 0, function* () {
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error("Stream doesn't exist");
            }
            const treasury = new web3_js_1.PublicKey(streamInfo.treasury);
            const treasuryInfo = yield this.getTreasury(treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const associatedToken = new web3_js_1.PublicKey(streamInfo.associatedToken);
            const tx = this.program.transaction.pauseStream(constants_2.LATEST_IDL_FILE_VERSION, {
                accounts: {
                    initializer: treasurer,
                    treasury: treasury,
                    stream: stream,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    resumeStream(payer, treasurer, stream) {
        return __awaiter(this, void 0, void 0, function* () {
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error("Stream doesn't exist");
            }
            const treasury = new web3_js_1.PublicKey(streamInfo.treasury);
            const treasuryInfo = yield this.getTreasury(treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const associatedToken = new web3_js_1.PublicKey(streamInfo.associatedToken);
            const tx = this.program.transaction.resumeStream(constants_2.LATEST_IDL_FILE_VERSION, {
                accounts: {
                    initializer: treasurer,
                    treasury: treasury,
                    stream: stream,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    closeStream(payer, destination, stream, autoCloseTreasury = false, autoWSol = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error("Stream doesn't exist");
            }
            const treasury = new web3_js_1.PublicKey(streamInfo.treasury);
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            if (streamInfo.associatedToken !== treasuryInfo.associatedToken) {
                throw Error('Invalid stream beneficiary associated token');
            }
            const treasurer = new web3_js_1.PublicKey(streamInfo.treasurer);
            const beneficiary = new web3_js_1.PublicKey(streamInfo.beneficiary);
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(streamInfo.associatedToken);
            const beneficiaryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, beneficiary, true);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            // Get the money streaming program operations token account or create a new one
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const ixs = [
                this.program.instruction.closeStream(constants_2.LATEST_IDL_FILE_VERSION, {
                    accounts: {
                        payer: payer,
                        treasurer: treasurer,
                        beneficiary: beneficiary,
                        beneficiaryToken: beneficiaryToken,
                        associatedToken: treasuryAssociatedTokenMint,
                        treasury: treasury,
                        treasuryToken: treasuryToken,
                        stream: stream,
                        feeTreasury: constants_1.Constants.FEE_TREASURY,
                        feeTreasuryToken: feeTreasuryToken,
                        associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                        tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                        systemProgram: web3_js_1.SystemProgram.programId,
                        rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                    },
                }),
            ];
            if (autoCloseTreasury) {
                const treasuryMint = new web3_js_1.PublicKey(treasuryInfo.mint);
                const treasurerTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, treasurer, true);
                const destinationToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, destination, true);
                ixs.push(this.program.instruction.closeTreasury(constants_2.LATEST_IDL_FILE_VERSION, {
                    accounts: {
                        payer: payer,
                        treasurer: treasurer,
                        destinationAuthority: destination,
                        destinationTokenAccount: destinationToken,
                        associatedToken: treasuryAssociatedTokenMint,
                        treasury: treasury,
                        treasuryToken: treasuryToken,
                        feeTreasury: constants_1.Constants.FEE_TREASURY,
                        feeTreasuryToken: feeTreasuryToken,
                        associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                        tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                        systemProgram: web3_js_1.SystemProgram.programId,
                        rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                    },
                }));
                // unwrap all on exit and only if destination is also a signer
                if (autoWSol &&
                    treasuryAssociatedTokenMint.equals(spl_token_1.NATIVE_MINT) &&
                    destination.equals(treasurer)) {
                    const closeWSolIx = spl_token_1.Token.createCloseAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, destinationToken, destination, destination, []);
                    ixs.push(closeWSolIx);
                }
            }
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    closeTreasury(payer, destination, treasury, autoWSol = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error('Treasury not found');
            }
            const treasurer = new web3_js_1.PublicKey(treasuryInfo.treasurer);
            const treasuryMint = new web3_js_1.PublicKey(treasuryInfo.mint);
            const treasurerTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryMint, treasurer, true);
            let treasuryAssociatedTokenMint = new web3_js_1.PublicKey(spl_token_1.NATIVE_MINT);
            const treasuryAssociatedToken = treasuryInfo.associatedToken;
            if (treasuryAssociatedToken !== '') {
                treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryAssociatedToken);
            }
            const destinationToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, destination, true);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            // Get the money streaming program operations token account or create a new one
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const ixs = [];
            const txSigners = [];
            const closeTreasuryIx = this.program.instruction.closeTreasury(constants_2.LATEST_IDL_FILE_VERSION, {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    destinationAuthority: destination,
                    destinationTokenAccount: destinationToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            ixs.push(closeTreasuryIx);
            if (autoWSol &&
                treasuryAssociatedTokenMint.equals(spl_token_1.NATIVE_MINT) &&
                destination.equals(treasurer) // the ata authority needs to be signer for the unwrap to work
            ) {
                const closeWSolIx = spl_token_1.Token.createCloseAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, destinationToken, destination, destination, []);
                ixs.push(closeWSolIx);
            }
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    refreshTreasuryData(payer, treasury) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            const associatedToken = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, treasury, true);
            // get treasury streams amount
            const memcmpFilters = [
                { memcmp: { offset: 8 + 170, bytes: treasury.toBase58() } },
            ];
            const totalStreams = (yield this.program.account.stream.all(memcmpFilters))
                .length;
            const tx = this.program.transaction.refreshTreasuryData(constants_2.LATEST_IDL_FILE_VERSION, {
                accounts: {
                    associatedToken: associatedToken,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    transferStream(beneficiary, newBeneficiary, stream) {
        return __awaiter(this, void 0, void 0, function* () {
            const streamInfo = (yield this.getStream(stream));
            if (!streamInfo) {
                throw Error("Stream doesn't exist");
            }
            const beneficiaryAddress = new web3_js_1.PublicKey(streamInfo.beneficiary);
            if (!beneficiary.equals(beneficiaryAddress)) {
                throw Error('Not authorized');
            }
            const tx = this.program.transaction.transferStream(constants_2.LATEST_IDL_FILE_VERSION, newBeneficiary, {
                accounts: {
                    beneficiary: beneficiaryAddress,
                    stream: stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    systemProgram: web3_js_1.SystemProgram.programId,
                },
            });
            tx.feePayer = beneficiary;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    createStreamFromPda(payer, treasurer, treasury, beneficiary, associatedToken, stream, streamName, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            if (treasurer.equals(beneficiary)) {
                throw Error('Beneficiary can not be the same Treasurer');
            }
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            if (treasuryInfo.associatedToken !== associatedToken.toBase58()) {
                throw Error('Incorrect associated token address');
            }
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, constants_1.Constants.FEE_TREASURY, true);
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            const now = new Date();
            const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
            const startUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            // Create Stream
            const tx = this.program.transaction.createStream(constants_2.LATEST_IDL_FILE_VERSION, streamName, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateAmount), new anchor_1.BN(rateIntervalInSeconds), new anchor_1.BN(allocationAssigned), new anchor_1.BN(cliffVestAmount), new anchor_1.BN(cliffVestPercentValue), feePayedByTreasurer !== null && feePayedByTreasurer !== void 0 ? feePayedByTreasurer : false, {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    associatedToken: associatedToken,
                    beneficiary: beneficiary,
                    stream: stream,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            return tx;
        });
    }
    createStreamsFromPda(payer, treasurer, treasury, associatedToken, streams, allocationAssigned, rateAmount, rateIntervalInSeconds, startUtc, cliffVestAmount, cliffVestPercent, feePayedByTreasurer) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error("Treasury doesn't exist");
            }
            if (treasuryInfo.associatedToken !== associatedToken.toBase58()) {
                throw Error('Incorrect associated token address');
            }
            // Get the treasury token account
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, treasury, true);
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, associatedToken, constants_1.Constants.FEE_TREASURY, true);
            const cliffVestPercentValue = cliffVestPercent
                ? cliffVestPercent * constants_1.Constants.CLIFF_PERCENT_NUMERATOR
                : 0;
            const now = new Date();
            const startDate = startUtc && startUtc.getTime() >= now.getTime() ? startUtc : now;
            const startUtcInSeconds = parseInt((startDate.getTime() / 1000).toString());
            // Create Streams
            const txs = [];
            const group = (size, data) => {
                const result = [];
                for (let i = 0; i < data.length; i += size) {
                    result.push(data.slice(i, i + size));
                }
                return result;
            };
            for (const groupItem of group(3, streams)) {
                const ixs = [];
                for (const streamBeneficiary of groupItem) {
                    if (streamBeneficiary.address.toBase58() === treasurer.toBase58()) {
                        continue;
                    }
                    const ix = this.program.instruction.createStream(constants_2.LATEST_IDL_FILE_VERSION, streamBeneficiary.streamName, new anchor_1.BN(startUtcInSeconds), new anchor_1.BN(rateAmount), new anchor_1.BN(rateIntervalInSeconds), new anchor_1.BN(allocationAssigned), new anchor_1.BN(cliffVestAmount), new anchor_1.BN(cliffVestPercentValue), feePayedByTreasurer !== null && feePayedByTreasurer !== void 0 ? feePayedByTreasurer : false, {
                        accounts: {
                            payer: payer,
                            treasurer: treasurer,
                            treasury: treasury,
                            treasuryToken: treasuryToken,
                            associatedToken: associatedToken,
                            beneficiary: streamBeneficiary.beneficiary,
                            stream: streamBeneficiary.address,
                            feeTreasury: constants_1.Constants.FEE_TREASURY,
                            feeTreasuryToken: feeTreasuryToken,
                            associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                            systemProgram: web3_js_1.SystemProgram.programId,
                            rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                        },
                    });
                    ixs.push(ix);
                }
                const tx = new web3_js_1.Transaction().add(...ixs);
                tx.feePayer = payer;
                const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
                tx.recentBlockhash = blockhash;
                txs.push(tx);
            }
            return txs;
        });
    }
    treasuryWithdraw(payer, destination, treasury, amount, autoWSol = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const treasuryInfo = yield (0, utils_1.getTreasury)(this.program, treasury);
            if (!treasuryInfo) {
                throw Error('Treasury not found');
            }
            const treasurer = new web3_js_1.PublicKey(treasuryInfo.treasurer);
            const treasuryAssociatedTokenMint = new web3_js_1.PublicKey(treasuryInfo.associatedToken);
            const destinationToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, destination, true);
            const treasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, treasury, true);
            // Get the money streaming program operations token account or create a new one
            const feeTreasuryToken = yield spl_token_1.Token.getAssociatedTokenAddress(spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID, spl_token_1.TOKEN_PROGRAM_ID, treasuryAssociatedTokenMint, constants_1.Constants.FEE_TREASURY, true);
            const ixs = [];
            const txSigners = [];
            const treasuryWithdrawIx = this.program.instruction.treasuryWithdraw(constants_2.LATEST_IDL_FILE_VERSION, new anchor_1.BN(amount), {
                accounts: {
                    payer: payer,
                    treasurer: treasurer,
                    destinationAuthority: destination,
                    destinationTokenAccount: destinationToken,
                    associatedToken: treasuryAssociatedTokenMint,
                    treasury: treasury,
                    treasuryToken: treasuryToken,
                    feeTreasury: constants_1.Constants.FEE_TREASURY,
                    feeTreasuryToken: feeTreasuryToken,
                    associatedTokenProgram: spl_token_1.ASSOCIATED_TOKEN_PROGRAM_ID,
                    tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
                    systemProgram: web3_js_1.SystemProgram.programId,
                    rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                },
            });
            ixs.push(treasuryWithdrawIx);
            if (autoWSol &&
                treasuryAssociatedTokenMint.equals(spl_token_1.NATIVE_MINT) &&
                destination.equals(treasurer) // the ata authority needs to be signer for the unwrap to work
            ) {
                const closeWSolIx = spl_token_1.Token.createCloseAccountInstruction(spl_token_1.TOKEN_PROGRAM_ID, destinationToken, destination, destination, []);
                ixs.push(closeWSolIx);
            }
            const tx = new web3_js_1.Transaction().add(...ixs);
            tx.feePayer = payer;
            const { blockhash } = yield this.connection.getRecentBlockhash(this.commitment || 'finalized');
            tx.recentBlockhash = blockhash;
            if (txSigners.length > 0) {
                tx.partialSign(...txSigners);
            }
            return tx;
        });
    }
    ensureAutoWrapSolInstructions(autoWSol, amountInLamports, owner, ownerWSolTokenAccount, ownerWSolTokenAccountInfo, instructions, signers) {
        return __awaiter(this, void 0, void 0, function* () {
            if (autoWSol) {
                const [wrapSolIxs, wrapSolSigners] = yield (0, utils_1.createWrapSolInstructions)(this.connection, amountInLamports, owner, ownerWSolTokenAccount, ownerWSolTokenAccountInfo);
                if (wrapSolIxs && wrapSolIxs.length > 0) {
                    instructions.push(...wrapSolIxs);
                    if (wrapSolSigners && wrapSolSigners.length > 0)
                        signers.push(...wrapSolSigners);
                }
            }
            else {
                if (!ownerWSolTokenAccountInfo) {
                    throw Error('Sender token account not found');
                }
            }
        });
    }
    /**
     * Validates the given address
     * @param address Solana public address
     * @returns one of the WARNING_TYPES as result
     */
    checkAddressForWarnings(address) {
        return __awaiter(this, void 0, void 0, function* () {
            let pkAddress;
            //check the address validity
            try {
                pkAddress = new web3_js_1.PublicKey(address);
            }
            catch (error) {
                console.warn(`Invalid Solana address: ${address}`);
                return constants_1.WARNING_TYPES.INVALID_ADDRESS;
            }
            //check address PDA
            const isAddressOnCurve = web3_js_1.PublicKey.isOnCurve(pkAddress);
            if (isAddressOnCurve) {
                return constants_1.WARNING_TYPES.WARNING;
            }
            //check address exists and owned by system program
            try {
                const accountInfo = yield this.connection.getAccountInfo(pkAddress);
                if (!accountInfo || !accountInfo.owner.equals(web3_js_1.SystemProgram.programId)) {
                    return constants_1.WARNING_TYPES.WARNING;
                }
            }
            catch (error) {
                return constants_1.WARNING_TYPES.WARNING;
            }
            return constants_1.WARNING_TYPES.NO_WARNING;
        });
    }
}
exports.MSP = MSP;
