var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name2 in all)
    __defProp(target, name2, { get: all[name2], enumerable: true });
};

// src/utils/transactions/transactions-builder.ts
import { Transaction } from "@solana/web3.js";
var EMPTY_INSTRUCTION = {
  instructions: [],
  cleanupInstructions: [],
  signers: []
};
var TransactionBuilder = class {
  constructor(provider) {
    this.provider = provider;
    this.instructions = [];
    this.signers = [];
  }
  addInstruction(instruction) {
    this.instructions.push(instruction);
    return this;
  }
  addSigner(signer) {
    this.signers.push(signer);
    return this;
  }
  isEmpty() {
    return this.instructions.length == 0;
  }
  compressIx(compressPost) {
    let instructions2 = [];
    let cleanupInstructions = [];
    let signers = [];
    this.instructions.forEach((curr) => {
      instructions2 = instructions2.concat(curr.instructions);
      cleanupInstructions = curr.cleanupInstructions.concat(cleanupInstructions);
      signers = signers.concat(curr.signers);
    });
    if (compressPost) {
      instructions2 = instructions2.concat(cleanupInstructions);
      cleanupInstructions = [];
    }
    return {
      instructions: [...instructions2],
      cleanupInstructions: [...cleanupInstructions],
      signers
    };
  }
  async build() {
    const recentBlockHash = (await this.provider.connection.getRecentBlockhash("singleGossip")).blockhash;
    const transaction = new Transaction({
      recentBlockhash: recentBlockHash,
      feePayer: this.provider.wallet.publicKey
    });
    const ix = this.compressIx(true);
    transaction.add(...ix.instructions);
    transaction.feePayer = this.provider.wallet.publicKey;
    return {
      transaction,
      signers: ix.signers.concat(this.signers)
    };
  }
  async buildAndExecute() {
    const tx = await this.build();
    return this.provider.send(tx.transaction, tx.signers, { commitment: "confirmed" });
  }
};

// src/instructions/initialize-config-ix.ts
import { SystemProgram } from "@solana/web3.js";
function buildInitializeConfigIx(context, params) {
  const {
    feeAuthority,
    collectProtocolFeesAuthority,
    rewardEmissionsSuperAuthority,
    defaultProtocolFeeRate,
    funder
  } = params;
  const ix = context.program.instruction.initializeConfig(feeAuthority, collectProtocolFeesAuthority, rewardEmissionsSuperAuthority, defaultProtocolFeeRate, {
    accounts: {
      config: params.whirlpoolConfigKeypair.publicKey,
      funder,
      systemProgram: SystemProgram.programId
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: [params.whirlpoolConfigKeypair]
  };
}

// src/instructions/initialize-pool-ix.ts
import { SystemProgram as SystemProgram2, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
function buildInitPoolIx(context, params) {
  const program = context.program;
  const {
    initSqrtPrice,
    tokenMintA,
    tokenMintB,
    whirlpoolConfigKey,
    whirlpoolPda,
    feeTierKey,
    tokenVaultAKeypair,
    tokenVaultBKeypair,
    tickSpacing,
    funder
  } = params;
  const whirlpoolBumps = {
    whirlpoolBump: whirlpoolPda.bump
  };
  const ix = program.instruction.initializePool(whirlpoolBumps, tickSpacing, initSqrtPrice, {
    accounts: {
      whirlpoolsConfig: whirlpoolConfigKey,
      tokenMintA,
      tokenMintB,
      funder,
      whirlpool: whirlpoolPda.publicKey,
      tokenVaultA: tokenVaultAKeypair.publicKey,
      tokenVaultB: tokenVaultBKeypair.publicKey,
      feeTier: feeTierKey,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram2.programId,
      rent: SYSVAR_RENT_PUBKEY
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: [tokenVaultAKeypair, tokenVaultBKeypair]
  };
}

// src/instructions/open-position-ix.ts
import { PublicKey as PublicKey2, SystemProgram as SystemProgram3 } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID2, ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";

// src/utils/public/addresses.ts
import { PublicKey } from "@solana/web3.js";

// src/utils/find-program-address.ts
import { utils } from "@project-serum/anchor";
import LRU from "lru-cache";
var programAddressCache = new LRU({
  max: 1e3,
  maxSize: 1e3,
  sizeCalculation: () => {
    return 1;
  },
  ttl: 1e3 * 60 * 30,
  updateAgeOnGet: true
});
function findProgramAddress(seeds, programId) {
  const seedKey = seeds.map((seed) => seed.toString("base64")).join("");
  const cached = programAddressCache.get(seedKey);
  if (cached) {
    return cached;
  }
  const [publicKey, bump] = utils.publicKey.findProgramAddressSync(seeds, programId);
  const pda = { publicKey, bump };
  programAddressCache.set(seedKey, pda);
  return { publicKey, bump };
}

// src/utils/public/addresses.ts
import { BN } from "@project-serum/anchor";
var PDA_WHIRLPOOL_SEED = "whirlpool";
var PDA_VAULT_A_SEED = "token_vault_a";
var PDA_VAULT_B_SEED = "token_vault_b";
var PDA_POSITION_SEED = "position";
var PDA_METADATA_SEED = "metadata";
var PDA_TICK_ARRAY_SEED = "tick_array";
var PDA_FEE_TIER_SEED = "fee_tier";
var PDA_ORACLE_SEED = "oracle";
function getWhirlpoolPda(programId, whirlpoolConfigKey, tokenMintAKey, tokenMintBKey, tickSpacing) {
  return findProgramAddress([
    Buffer.from(PDA_WHIRLPOOL_SEED),
    whirlpoolConfigKey.toBuffer(),
    tokenMintAKey.toBuffer(),
    tokenMintBKey.toBuffer(),
    new BN(tickSpacing).toArrayLike(Buffer, "le", 2)
  ], programId);
}
function getWhirlpoolVaultAPda(programId, whirlpoolKey, tokenMintAKey) {
  return findProgramAddress([Buffer.from(PDA_VAULT_A_SEED), whirlpoolKey.toBuffer(), tokenMintAKey.toBuffer()], programId);
}
function getWhirlpoolVaultBPda(programId, whirlpoolKey, tokenMintBKey) {
  return findProgramAddress([Buffer.from(PDA_VAULT_B_SEED), whirlpoolKey.toBuffer(), tokenMintBKey.toBuffer()], programId);
}
function getPositionPda(programId, positionMintKey) {
  return findProgramAddress([Buffer.from(PDA_POSITION_SEED), positionMintKey.toBuffer()], programId);
}
var METADATA_PROGRAM_ADDRESS = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
function getPositionMetadataPda(positionMintKey) {
  return findProgramAddress([
    Buffer.from(PDA_METADATA_SEED),
    METADATA_PROGRAM_ADDRESS.toBuffer(),
    positionMintKey.toBuffer()
  ], METADATA_PROGRAM_ADDRESS);
}
function getTickArrayPda(programId, whirlpoolAddress, startTick) {
  return findProgramAddress([
    Buffer.from(PDA_TICK_ARRAY_SEED),
    whirlpoolAddress.toBuffer(),
    Buffer.from(startTick.toString())
  ], programId);
}
function getFeeTierPda(programId, whirlpoolsConfigAddress, tickSpacing) {
  return findProgramAddress([
    Buffer.from(PDA_FEE_TIER_SEED),
    whirlpoolsConfigAddress.toBuffer(),
    new BN(tickSpacing).toArrayLike(Buffer, "le", 2)
  ], programId);
}
function getOraclePda(programId, whirlpoolAddress) {
  return findProgramAddress([Buffer.from(PDA_ORACLE_SEED), whirlpoolAddress.toBuffer()], programId);
}

// src/utils/public/math.ts
import { BN as BN2 } from "@project-serum/anchor";
import Decimal from "decimal.js";
function toX64_BN(num) {
  return num.mul(new BN2(2).pow(new BN2(64)));
}
function toX64_Decimal(num) {
  return num.mul(Decimal.pow(2, 64));
}
function toX64(num) {
  return new BN2(num.mul(Decimal.pow(2, 64)).floor().toFixed());
}
function fromX64(num) {
  return new Decimal(num.toString()).mul(Decimal.pow(2, -64));
}
function fromX64_Decimal(num) {
  return num.mul(Decimal.pow(2, -64));
}
function fromX64_BN(num) {
  return num.div(new BN2(2).pow(new BN2(64)));
}

// src/utils/public/parse.ts
import { BorshCoder, BorshAccountsCoder } from "@project-serum/anchor";

// src/types/public/anchor-types.ts
var AccountName = /* @__PURE__ */ ((AccountName2) => {
  AccountName2["WhirlpoolsConfig"] = "WhirlpoolsConfig";
  AccountName2["Position"] = "Position";
  AccountName2["TickArray"] = "TickArray";
  AccountName2["Whirlpool"] = "Whirlpool";
  return AccountName2;
})(AccountName || {});

// src/artifacts/whirlpool.json
var whirlpool_exports = {};
__export(whirlpool_exports, {
  accounts: () => accounts,
  default: () => whirlpool_default,
  errors: () => errors,
  instructions: () => instructions,
  name: () => name,
  types: () => types,
  version: () => version
});
var version = "0.1.0";
var name = "whirlpool";
var instructions = [
  {
    name: "initializeConfig",
    accounts: [
      {
        name: "config",
        isMut: true,
        isSigner: true
      },
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "feeAuthority",
        type: "publicKey"
      },
      {
        name: "collectProtocolFeesAuthority",
        type: "publicKey"
      },
      {
        name: "rewardEmissionsSuperAuthority",
        type: "publicKey"
      },
      {
        name: "defaultProtocolFeeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "initializePool",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenMintA",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenMintB",
        isMut: false,
        isSigner: false
      },
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: true
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: true
      },
      {
        name: "feeTier",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "rent",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "bumps",
        type: {
          defined: "WhirlpoolBumps"
        }
      },
      {
        name: "tickSpacing",
        type: "u16"
      },
      {
        name: "initialSqrtPrice",
        type: "u128"
      }
    ]
  },
  {
    name: "initializeTickArray",
    accounts: [
      {
        name: "whirlpool",
        isMut: false,
        isSigner: false
      },
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "tickArray",
        isMut: true,
        isSigner: false
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "startTickIndex",
        type: "i32"
      }
    ]
  },
  {
    name: "initializeFeeTier",
    accounts: [
      {
        name: "config",
        isMut: false,
        isSigner: false
      },
      {
        name: "feeTier",
        isMut: true,
        isSigner: false
      },
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "tickSpacing",
        type: "u16"
      },
      {
        name: "defaultFeeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "initializeReward",
    accounts: [
      {
        name: "rewardAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardMint",
        isMut: false,
        isSigner: false
      },
      {
        name: "rewardVault",
        isMut: true,
        isSigner: true
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "rent",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "rewardIndex",
        type: "u8"
      }
    ]
  },
  {
    name: "setRewardEmissions",
    accounts: [
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "rewardVault",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "rewardIndex",
        type: "u8"
      },
      {
        name: "emissionsPerSecondX64",
        type: "u128"
      }
    ]
  },
  {
    name: "openPosition",
    accounts: [
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "owner",
        isMut: false,
        isSigner: false
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionMint",
        isMut: true,
        isSigner: true
      },
      {
        name: "positionTokenAccount",
        isMut: true,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "rent",
        isMut: false,
        isSigner: false
      },
      {
        name: "associatedTokenProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "bumps",
        type: {
          defined: "OpenPositionBumps"
        }
      },
      {
        name: "tickLowerIndex",
        type: "i32"
      },
      {
        name: "tickUpperIndex",
        type: "i32"
      }
    ]
  },
  {
    name: "openPositionWithMetadata",
    accounts: [
      {
        name: "funder",
        isMut: true,
        isSigner: true
      },
      {
        name: "owner",
        isMut: false,
        isSigner: false
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionMint",
        isMut: true,
        isSigner: true
      },
      {
        name: "positionMetadataAccount",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: true,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "systemProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "rent",
        isMut: false,
        isSigner: false
      },
      {
        name: "associatedTokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "metadataProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "metadataUpdateAuth",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "bumps",
        type: {
          defined: "OpenPositionWithMetadataBumps"
        }
      },
      {
        name: "tickLowerIndex",
        type: "i32"
      },
      {
        name: "tickUpperIndex",
        type: "i32"
      }
    ]
  },
  {
    name: "increaseLiquidity",
    accounts: [
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "positionAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArrayLower",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArrayUpper",
        isMut: true,
        isSigner: false
      }
    ],
    args: [
      {
        name: "liquidityAmount",
        type: "u128"
      },
      {
        name: "tokenMaxA",
        type: "u64"
      },
      {
        name: "tokenMaxB",
        type: "u64"
      }
    ]
  },
  {
    name: "decreaseLiquidity",
    accounts: [
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "positionAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArrayLower",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArrayUpper",
        isMut: true,
        isSigner: false
      }
    ],
    args: [
      {
        name: "liquidityAmount",
        type: "u128"
      },
      {
        name: "tokenMinA",
        type: "u64"
      },
      {
        name: "tokenMinB",
        type: "u64"
      }
    ]
  },
  {
    name: "updateFeesAndRewards",
    accounts: [
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArrayLower",
        isMut: false,
        isSigner: false
      },
      {
        name: "tickArrayUpper",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "collectFees",
    accounts: [
      {
        name: "whirlpool",
        isMut: false,
        isSigner: false
      },
      {
        name: "positionAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "collectReward",
    accounts: [
      {
        name: "whirlpool",
        isMut: false,
        isSigner: false
      },
      {
        name: "positionAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: false,
        isSigner: false
      },
      {
        name: "rewardOwnerAccount",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardVault",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "rewardIndex",
        type: "u8"
      }
    ]
  },
  {
    name: "collectProtocolFees",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "collectProtocolFeesAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenDestinationA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenDestinationB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "swap",
    accounts: [
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      },
      {
        name: "tokenAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultA",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenOwnerAccountB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenVaultB",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArray0",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArray1",
        isMut: true,
        isSigner: false
      },
      {
        name: "tickArray2",
        isMut: true,
        isSigner: false
      },
      {
        name: "oracle",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "amount",
        type: "u64"
      },
      {
        name: "otherAmountThreshold",
        type: "u64"
      },
      {
        name: "sqrtPriceLimit",
        type: "u128"
      },
      {
        name: "exactInput",
        type: "bool"
      },
      {
        name: "aToB",
        type: "bool"
      }
    ]
  },
  {
    name: "closePosition",
    accounts: [
      {
        name: "positionAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "receiver",
        isMut: true,
        isSigner: false
      },
      {
        name: "position",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionMint",
        isMut: true,
        isSigner: false
      },
      {
        name: "positionTokenAccount",
        isMut: true,
        isSigner: false
      },
      {
        name: "tokenProgram",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "setDefaultFeeRate",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "feeTier",
        isMut: true,
        isSigner: false
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      }
    ],
    args: [
      {
        name: "defaultFeeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "setDefaultProtocolFeeRate",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: true,
        isSigner: false
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      }
    ],
    args: [
      {
        name: "defaultProtocolFeeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "setFeeRate",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      }
    ],
    args: [
      {
        name: "feeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "setProtocolFeeRate",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      }
    ],
    args: [
      {
        name: "protocolFeeRate",
        type: "u16"
      }
    ]
  },
  {
    name: "setFeeAuthority",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: true,
        isSigner: false
      },
      {
        name: "feeAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "newFeeAuthority",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "setCollectProtocolFeesAuthority",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: true,
        isSigner: false
      },
      {
        name: "collectProtocolFeesAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "newCollectProtocolFeesAuthority",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  },
  {
    name: "setRewardAuthority",
    accounts: [
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "newRewardAuthority",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "rewardIndex",
        type: "u8"
      }
    ]
  },
  {
    name: "setRewardAuthorityBySuperAuthority",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: false,
        isSigner: false
      },
      {
        name: "whirlpool",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardEmissionsSuperAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "newRewardAuthority",
        isMut: false,
        isSigner: false
      }
    ],
    args: [
      {
        name: "rewardIndex",
        type: "u8"
      }
    ]
  },
  {
    name: "setRewardEmissionsSuperAuthority",
    accounts: [
      {
        name: "whirlpoolsConfig",
        isMut: true,
        isSigner: false
      },
      {
        name: "rewardEmissionsSuperAuthority",
        isMut: false,
        isSigner: true
      },
      {
        name: "newRewardEmissionsSuperAuthority",
        isMut: false,
        isSigner: false
      }
    ],
    args: []
  }
];
var accounts = [
  {
    name: "WhirlpoolsConfig",
    type: {
      kind: "struct",
      fields: [
        {
          name: "feeAuthority",
          type: "publicKey"
        },
        {
          name: "collectProtocolFeesAuthority",
          type: "publicKey"
        },
        {
          name: "rewardEmissionsSuperAuthority",
          type: "publicKey"
        },
        {
          name: "defaultProtocolFeeRate",
          type: "u16"
        }
      ]
    }
  },
  {
    name: "FeeTier",
    type: {
      kind: "struct",
      fields: [
        {
          name: "whirlpoolsConfig",
          type: "publicKey"
        },
        {
          name: "tickSpacing",
          type: "u16"
        },
        {
          name: "defaultFeeRate",
          type: "u16"
        }
      ]
    }
  },
  {
    name: "Position",
    type: {
      kind: "struct",
      fields: [
        {
          name: "whirlpool",
          type: "publicKey"
        },
        {
          name: "positionMint",
          type: "publicKey"
        },
        {
          name: "liquidity",
          type: "u128"
        },
        {
          name: "tickLowerIndex",
          type: "i32"
        },
        {
          name: "tickUpperIndex",
          type: "i32"
        },
        {
          name: "feeGrowthCheckpointA",
          type: "u128"
        },
        {
          name: "feeOwedA",
          type: "u64"
        },
        {
          name: "feeGrowthCheckpointB",
          type: "u128"
        },
        {
          name: "feeOwedB",
          type: "u64"
        },
        {
          name: "rewardInfos",
          type: {
            array: [
              {
                defined: "PositionRewardInfo"
              },
              3
            ]
          }
        }
      ]
    }
  },
  {
    name: "TickArray",
    type: {
      kind: "struct",
      fields: [
        {
          name: "startTickIndex",
          type: "i32"
        },
        {
          name: "ticks",
          type: {
            array: [
              {
                defined: "Tick"
              },
              88
            ]
          }
        },
        {
          name: "whirlpool",
          type: "publicKey"
        }
      ]
    }
  },
  {
    name: "Whirlpool",
    type: {
      kind: "struct",
      fields: [
        {
          name: "whirlpoolsConfig",
          type: "publicKey"
        },
        {
          name: "whirlpoolBump",
          type: {
            array: [
              "u8",
              1
            ]
          }
        },
        {
          name: "tickSpacing",
          type: "u16"
        },
        {
          name: "tickSpacingSeed",
          type: {
            array: [
              "u8",
              2
            ]
          }
        },
        {
          name: "feeRate",
          type: "u16"
        },
        {
          name: "protocolFeeRate",
          type: "u16"
        },
        {
          name: "liquidity",
          type: "u128"
        },
        {
          name: "sqrtPrice",
          type: "u128"
        },
        {
          name: "tickCurrentIndex",
          type: "i32"
        },
        {
          name: "protocolFeeOwedA",
          type: "u64"
        },
        {
          name: "protocolFeeOwedB",
          type: "u64"
        },
        {
          name: "tokenMintA",
          type: "publicKey"
        },
        {
          name: "tokenVaultA",
          type: "publicKey"
        },
        {
          name: "feeGrowthGlobalA",
          type: "u128"
        },
        {
          name: "tokenMintB",
          type: "publicKey"
        },
        {
          name: "tokenVaultB",
          type: "publicKey"
        },
        {
          name: "feeGrowthGlobalB",
          type: "u128"
        },
        {
          name: "rewardLastUpdatedTimestamp",
          type: "u64"
        },
        {
          name: "rewardInfos",
          type: {
            array: [
              {
                defined: "WhirlpoolRewardInfo"
              },
              3
            ]
          }
        }
      ]
    }
  }
];
var types = [
  {
    name: "OpenPositionBumps",
    type: {
      kind: "struct",
      fields: [
        {
          name: "positionBump",
          type: "u8"
        }
      ]
    }
  },
  {
    name: "OpenPositionWithMetadataBumps",
    type: {
      kind: "struct",
      fields: [
        {
          name: "positionBump",
          type: "u8"
        },
        {
          name: "metadataBump",
          type: "u8"
        }
      ]
    }
  },
  {
    name: "PositionRewardInfo",
    type: {
      kind: "struct",
      fields: [
        {
          name: "growthInsideCheckpoint",
          type: "u128"
        },
        {
          name: "amountOwed",
          type: "u64"
        }
      ]
    }
  },
  {
    name: "Tick",
    type: {
      kind: "struct",
      fields: [
        {
          name: "initialized",
          type: "bool"
        },
        {
          name: "liquidityNet",
          type: "i128"
        },
        {
          name: "liquidityGross",
          type: "u128"
        },
        {
          name: "feeGrowthOutsideA",
          type: "u128"
        },
        {
          name: "feeGrowthOutsideB",
          type: "u128"
        },
        {
          name: "rewardGrowthsOutside",
          type: {
            array: [
              "u128",
              3
            ]
          }
        }
      ]
    }
  },
  {
    name: "WhirlpoolRewardInfo",
    type: {
      kind: "struct",
      fields: [
        {
          name: "mint",
          type: "publicKey"
        },
        {
          name: "vault",
          type: "publicKey"
        },
        {
          name: "authority",
          type: "publicKey"
        },
        {
          name: "emissionsPerSecondX64",
          type: "u128"
        },
        {
          name: "growthGlobalX64",
          type: "u128"
        }
      ]
    }
  },
  {
    name: "WhirlpoolBumps",
    type: {
      kind: "struct",
      fields: [
        {
          name: "whirlpoolBump",
          type: "u8"
        }
      ]
    }
  },
  {
    name: "CurrIndex",
    type: {
      kind: "enum",
      variants: [
        {
          name: "Below"
        },
        {
          name: "Inside"
        },
        {
          name: "Above"
        }
      ]
    }
  },
  {
    name: "TickLabel",
    type: {
      kind: "enum",
      variants: [
        {
          name: "Upper"
        },
        {
          name: "Lower"
        }
      ]
    }
  },
  {
    name: "Direction",
    type: {
      kind: "enum",
      variants: [
        {
          name: "Left"
        },
        {
          name: "Right"
        }
      ]
    }
  }
];
var errors = [
  {
    code: 6e3,
    name: "InvalidEnum",
    msg: "Enum value could not be converted"
  },
  {
    code: 6001,
    name: "InvalidStartTick",
    msg: "Invalid start tick index provided."
  },
  {
    code: 6002,
    name: "TickArrayExistInPool",
    msg: "Tick-array already exists in this whirlpool"
  },
  {
    code: 6003,
    name: "TickArrayIndexOutofBounds",
    msg: "Attempt to search for a tick-array failed"
  },
  {
    code: 6004,
    name: "InvalidTickSpacing",
    msg: "Tick-spacing is not supported"
  },
  {
    code: 6005,
    name: "ClosePositionNotEmpty",
    msg: "Position is not empty It cannot be closed"
  },
  {
    code: 6006,
    name: "DivideByZero",
    msg: "Unable to divide by zero"
  },
  {
    code: 6007,
    name: "NumberCastError",
    msg: "Unable to cast number into BigInt"
  },
  {
    code: 6008,
    name: "NumberDownCastError",
    msg: "Unable to down cast number"
  },
  {
    code: 6009,
    name: "TickNotFound",
    msg: "Tick not found within tick array"
  },
  {
    code: 6010,
    name: "InvalidTickIndex",
    msg: "Provided tick index is either out of bounds or uninitializable"
  },
  {
    code: 6011,
    name: "SqrtPriceOutOfBounds",
    msg: "Provided sqrt price out of bounds"
  },
  {
    code: 6012,
    name: "LiquidityZero",
    msg: "Liquidity amount must be greater than zero"
  },
  {
    code: 6013,
    name: "LiquidityTooHigh",
    msg: "Liquidity amount must be less than i64::MAX"
  },
  {
    code: 6014,
    name: "LiquidityOverflow",
    msg: "Liquidity overflow"
  },
  {
    code: 6015,
    name: "LiquidityUnderflow",
    msg: "Liquidity underflow"
  },
  {
    code: 6016,
    name: "LiquidityNetError",
    msg: "Tick liquidity net underflowed or overflowed"
  },
  {
    code: 6017,
    name: "TokenMaxExceeded",
    msg: "Exceeded token max"
  },
  {
    code: 6018,
    name: "TokenMinSubceeded",
    msg: "Did not meet token min"
  },
  {
    code: 6019,
    name: "MissingOrInvalidDelegate",
    msg: "Position token account has a missing or invalid delegate"
  },
  {
    code: 6020,
    name: "InvalidPositionTokenAmount",
    msg: "Position token amount must be 1"
  },
  {
    code: 6021,
    name: "InvalidTimestampConversion",
    msg: "Timestamp should be convertible from i64 to u64"
  },
  {
    code: 6022,
    name: "InvalidTimestamp",
    msg: "Timestamp should be greater than the last updated timestamp"
  },
  {
    code: 6023,
    name: "InvalidTickArraySequence",
    msg: "Invalid tick array sequence provided for instruction."
  },
  {
    code: 6024,
    name: "InvalidTokenMintOrder",
    msg: "Token Mint in wrong order"
  },
  {
    code: 6025,
    name: "RewardNotInitialized",
    msg: "Reward not initialized"
  },
  {
    code: 6026,
    name: "InvalidRewardIndex",
    msg: "Invalid reward index"
  },
  {
    code: 6027,
    name: "RewardVaultAmountInsufficient",
    msg: "Reward vault requires amount to support emissions for at least one day"
  },
  {
    code: 6028,
    name: "FeeRateMaxExceeded",
    msg: "Exceeded max fee rate"
  },
  {
    code: 6029,
    name: "ProtocolFeeRateMaxExceeded",
    msg: "Exceeded max protocol fee rate"
  },
  {
    code: 6030,
    name: "MultiplicationShiftRightOverflow",
    msg: "Multiplication with shift right overflow"
  },
  {
    code: 6031,
    name: "MulDivOverflow",
    msg: "Muldiv overflow"
  },
  {
    code: 6032,
    name: "MulDivInvalidInput",
    msg: "Invalid div_u256 input"
  },
  {
    code: 6033,
    name: "MultiplicationOverflow",
    msg: "Multiplication overflow"
  },
  {
    code: 6034,
    name: "InvalidSqrtPriceLimitDirection",
    msg: "Provided SqrtPriceLimit not in the same direction as the swap."
  },
  {
    code: 6035,
    name: "ZeroTradableAmount",
    msg: "There are no tradable amount to swap."
  },
  {
    code: 6036,
    name: "AmountOutBelowMinimum",
    msg: "Amount out below minimum threshold"
  },
  {
    code: 6037,
    name: "AmountInAboveMaximum",
    msg: "Amount in above maximum threshold"
  }
];
var whirlpool_default = {
  version,
  name,
  instructions,
  accounts,
  types,
  errors
};

// src/utils/public/parse.ts
var WhirlpoolCoder = new BorshCoder(whirlpool_exports);
function parseWhirlpoolsConfig(data) {
  return parse("WhirlpoolsConfig" /* WhirlpoolsConfig */, data);
}
function parseWhirlpool(data) {
  return parse("Whirlpool" /* Whirlpool */, data);
}
function parsePosition(data) {
  return parse("Position" /* Position */, data);
}
function parseTickArray(data) {
  return parse("TickArray" /* TickArray */, data);
}
function parse(accountName, data) {
  const discriminator = BorshAccountsCoder.accountDiscriminator(accountName);
  if (discriminator.compare(data.slice(0, 8))) {
    console.error("incorrect account name during parsing");
    return null;
  }
  try {
    return WhirlpoolCoder.accounts.decode(accountName, data);
  } catch (_e) {
    console.error("unknown account name during parsing");
    return null;
  }
}

// src/utils/public/tick-utils.ts
import { BN as BN3 } from "@project-serum/anchor";
var MAX_TICK_INDEX = 443636;
var MIN_TICK_INDEX = -443636;
var TICK_ARRAY_SIZE = 88;
var MAX_SQRT_PRICE = "79226673515401279992447579055";
var MIN_SQRT_PRICE = "4295048016";
var BIT_PRECISION = 14;
var LOG_B_2_X32 = "59543866431248";
var LOG_B_P_ERR_MARGIN_LOWER_X64 = "184467440737095516";
var LOG_B_P_ERR_MARGIN_UPPER_X64 = "15793534762490258745";
function tickIndexToSqrtPriceX64(tickIndex) {
  if (tickIndex > MAX_TICK_INDEX || tickIndex < MIN_TICK_INDEX) {
    throw new Error("Provided tick index does not fit within supported tick index range.");
  }
  if (tickIndex > 0) {
    return new BN3(tickIndexToSqrtPricePositive(tickIndex));
  } else {
    return new BN3(tickIndexToSqrtPriceNegative(tickIndex));
  }
}
function sqrtPriceX64ToTickIndex(sqrtPriceX64) {
  if (sqrtPriceX64.gt(new BN3(MAX_SQRT_PRICE)) || sqrtPriceX64.lt(new BN3(MIN_SQRT_PRICE))) {
    throw new Error("Provided sqrtPrice is not within the supported sqrtPrice range.");
  }
  const msb = sqrtPriceX64.bitLength() - 1;
  const adjustedMsb = new BN3(msb - 64);
  const log2pIntegerX32 = signedShiftLeft(adjustedMsb, 32, 128);
  let bit = new BN3("8000000000000000", "hex");
  let precision = 0;
  let log2pFractionX64 = new BN3(0);
  let r = msb >= 64 ? sqrtPriceX64.shrn(msb - 63) : sqrtPriceX64.shln(63 - msb);
  while (bit.gt(new BN3(0)) && precision < BIT_PRECISION) {
    r = r.mul(r);
    let rMoreThanTwo = r.shrn(127);
    r = r.shrn(63 + rMoreThanTwo.toNumber());
    log2pFractionX64 = log2pFractionX64.add(bit.mul(rMoreThanTwo));
    bit = bit.shrn(1);
    precision += 1;
  }
  const log2pFractionX32 = log2pFractionX64.shrn(32);
  const log2pX32 = log2pIntegerX32.add(log2pFractionX32);
  const logbpX64 = log2pX32.mul(new BN3(LOG_B_2_X32));
  const tickLow = signedShiftRight(logbpX64.sub(new BN3(LOG_B_P_ERR_MARGIN_LOWER_X64)), 64, 128).toNumber();
  const tickHigh = signedShiftRight(logbpX64.add(new BN3(LOG_B_P_ERR_MARGIN_UPPER_X64)), 64, 128).toNumber();
  if (tickLow == tickHigh) {
    return tickLow;
  } else {
    const derivedTickHighSqrtPriceX64 = tickIndexToSqrtPriceX64(tickHigh);
    if (derivedTickHighSqrtPriceX64.lte(sqrtPriceX64)) {
      return tickHigh;
    } else {
      return tickLow;
    }
  }
}
function getStartTickIndex(tickIndex, tickSpacing) {
  const ticksInArray = tickSpacing * TICK_ARRAY_SIZE;
  return Math.floor(tickIndex / ticksInArray) * ticksInArray;
}
function tickIndexToSqrtPricePositive(tick) {
  let ratio;
  if ((tick & 1) != 0) {
    ratio = new BN3("79232123823359799118286999567");
  } else {
    ratio = new BN3("79228162514264337593543950336");
  }
  if ((tick & 2) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79236085330515764027303304731")), 96, 256);
  }
  if ((tick & 4) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79244008939048815603706035061")), 96, 256);
  }
  if ((tick & 8) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79259858533276714757314932305")), 96, 256);
  }
  if ((tick & 16) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79291567232598584799939703904")), 96, 256);
  }
  if ((tick & 32) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79355022692464371645785046466")), 96, 256);
  }
  if ((tick & 64) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79482085999252804386437311141")), 96, 256);
  }
  if ((tick & 128) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("79736823300114093921829183326")), 96, 256);
  }
  if ((tick & 256) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("80248749790819932309965073892")), 96, 256);
  }
  if ((tick & 512) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("81282483887344747381513967011")), 96, 256);
  }
  if ((tick & 1024) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("83390072131320151908154831281")), 96, 256);
  }
  if ((tick & 2048) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("87770609709833776024991924138")), 96, 256);
  }
  if ((tick & 4096) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("97234110755111693312479820773")), 96, 256);
  }
  if ((tick & 8192) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("119332217159966728226237229890")), 96, 256);
  }
  if ((tick & 16384) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("179736315981702064433883588727")), 96, 256);
  }
  if ((tick & 32768) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("407748233172238350107850275304")), 96, 256);
  }
  if ((tick & 65536) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("2098478828474011932436660412517")), 96, 256);
  }
  if ((tick & 131072) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("55581415166113811149459800483533")), 96, 256);
  }
  if ((tick & 262144) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("38992368544603139932233054999993551")), 96, 256);
  }
  return signedShiftRight(ratio, 32, 256);
}
function tickIndexToSqrtPriceNegative(tickIndex) {
  let tick = Math.abs(tickIndex);
  let ratio;
  if ((tick & 1) != 0) {
    ratio = new BN3("18445821805675392311");
  } else {
    ratio = new BN3("18446744073709551616");
  }
  if ((tick & 2) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18444899583751176498")), 64, 256);
  }
  if ((tick & 4) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18443055278223354162")), 64, 256);
  }
  if ((tick & 8) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18439367220385604838")), 64, 256);
  }
  if ((tick & 16) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18431993317065449817")), 64, 256);
  }
  if ((tick & 32) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18417254355718160513")), 64, 256);
  }
  if ((tick & 64) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18387811781193591352")), 64, 256);
  }
  if ((tick & 128) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18329067761203520168")), 64, 256);
  }
  if ((tick & 256) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("18212142134806087854")), 64, 256);
  }
  if ((tick & 512) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("17980523815641551639")), 64, 256);
  }
  if ((tick & 1024) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("17526086738831147013")), 64, 256);
  }
  if ((tick & 2048) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("16651378430235024244")), 64, 256);
  }
  if ((tick & 4096) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("15030750278693429944")), 64, 256);
  }
  if ((tick & 8192) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("12247334978882834399")), 64, 256);
  }
  if ((tick & 16384) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("8131365268884726200")), 64, 256);
  }
  if ((tick & 32768) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("3584323654723342297")), 64, 256);
  }
  if ((tick & 65536) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("696457651847595233")), 64, 256);
  }
  if ((tick & 131072) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("26294789957452057")), 64, 256);
  }
  if ((tick & 262144) != 0) {
    ratio = signedShiftRight(ratio.mul(new BN3("37481735321082")), 64, 256);
  }
  return ratio;
}
function signedShiftLeft(n0, shiftBy, bitWidth) {
  let twosN0 = n0.toTwos(bitWidth).shln(shiftBy);
  twosN0.imaskn(bitWidth + 1);
  return twosN0.fromTwos(bitWidth);
}
function signedShiftRight(n0, shiftBy, bitWidth) {
  let twoN0 = n0.toTwos(bitWidth).shrn(shiftBy);
  twoN0.imaskn(bitWidth - shiftBy + 1);
  return twoN0.fromTwos(bitWidth - shiftBy);
}

// src/utils/public/liquidity.ts
import { BN as BN4 } from "@project-serum/anchor";
import { u64 } from "@solana/spl-token";
import Decimal2 from "decimal.js";
function toTokenAmount(a, b) {
  return {
    tokenA: new u64(a.toString()),
    tokenB: new u64(b.toString())
  };
}
function estimateLiquidityFromTokenAmounts(currTick, lowerTick, upperTick, tokenAmount) {
  if (upperTick < lowerTick) {
    throw new Error("upper tick cannot be lower than the lower tick");
  }
  const currSqrtPrice = tickIndexToSqrtPriceX64(currTick);
  const lowerSqrtPrice = tickIndexToSqrtPriceX64(lowerTick);
  const upperSqrtPrice = tickIndexToSqrtPriceX64(upperTick);
  if (currTick >= upperTick) {
    return estLiquidityForTokenB(upperSqrtPrice, lowerSqrtPrice, tokenAmount.tokenB);
  } else if (currTick < lowerTick) {
    return estLiquidityForTokenA(lowerSqrtPrice, upperSqrtPrice, tokenAmount.tokenA);
  } else {
    const estLiquidityAmountA = estLiquidityForTokenA(currSqrtPrice, upperSqrtPrice, tokenAmount.tokenA);
    const estLiquidityAmountB = estLiquidityForTokenB(currSqrtPrice, lowerSqrtPrice, tokenAmount.tokenB);
    return BN4.min(estLiquidityAmountA, estLiquidityAmountB);
  }
}
function getTokenAmountsFromLiquidity(liquidity, currentPrice, lowerPrice, upperPrice, round_up) {
  const _liquidity = new Decimal2(liquidity.toString());
  const _currentPrice = new Decimal2(currentPrice.toString());
  const _lowerPrice = new Decimal2(lowerPrice.toString());
  const _upperPrice = new Decimal2(upperPrice.toString());
  let tokenA, tokenB;
  if (currentPrice.lt(lowerPrice)) {
    tokenA = toX64_Decimal(_liquidity).mul(_upperPrice.sub(_lowerPrice)).div(_lowerPrice.mul(_upperPrice));
    tokenB = new Decimal2(0);
  } else if (currentPrice.lt(upperPrice)) {
    tokenA = toX64_Decimal(_liquidity).mul(_upperPrice.sub(_currentPrice)).div(_currentPrice.mul(_upperPrice));
    tokenB = fromX64_Decimal(_liquidity.mul(_currentPrice.sub(_lowerPrice)));
  } else {
    tokenA = new Decimal2(0);
    tokenB = fromX64_Decimal(_liquidity.mul(_upperPrice.sub(_lowerPrice)));
  }
  if (round_up) {
    return {
      tokenA: new u64(tokenA.ceil().toString()),
      tokenB: new u64(tokenB.ceil().toString())
    };
  } else {
    return {
      tokenA: new u64(tokenA.floor().toString()),
      tokenB: new u64(tokenB.floor().toString())
    };
  }
}
function estLiquidityForTokenA(sqrtPrice1, sqrtPrice2, tokenAmount) {
  const lowerSqrtPriceX64 = BN4.min(sqrtPrice1, sqrtPrice2);
  const upperSqrtPriceX64 = BN4.max(sqrtPrice1, sqrtPrice2);
  const num = fromX64_BN(tokenAmount.mul(upperSqrtPriceX64).mul(lowerSqrtPriceX64));
  const dem = upperSqrtPriceX64.sub(lowerSqrtPriceX64);
  return num.div(dem);
}
function estLiquidityForTokenB(sqrtPrice1, sqrtPrice2, tokenAmount) {
  const lowerSqrtPriceX64 = BN4.min(sqrtPrice1, sqrtPrice2);
  const upperSqrtPriceX64 = BN4.max(sqrtPrice1, sqrtPrice2);
  const delta = upperSqrtPriceX64.sub(lowerSqrtPriceX64);
  return toX64_BN(tokenAmount).div(delta);
}

// src/instructions/open-position-ix.ts
function buildOpenPositionIx(context, params) {
  const { positionPda, tickLowerIndex, tickUpperIndex } = params;
  const bumps = {
    positionBump: positionPda.bump
  };
  const ix = context.program.instruction.openPosition(bumps, tickLowerIndex, tickUpperIndex, {
    accounts: openPositionAccounts(params)
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}
function buildOpenPositionWithMetadataIx(context, params) {
  const { positionPda, metadataPda, tickLowerIndex, tickUpperIndex } = params;
  const bumps = {
    positionBump: positionPda.bump,
    metadataBump: metadataPda.bump
  };
  const ix = context.program.instruction.openPositionWithMetadata(bumps, tickLowerIndex, tickUpperIndex, {
    accounts: __spreadProps(__spreadValues({}, openPositionAccounts(params)), {
      positionMetadataAccount: metadataPda.publicKey,
      metadataProgram: METADATA_PROGRAM_ADDRESS,
      metadataUpdateAuth: new PublicKey2("3axbTs2z5GBy6usVbNVoqEgZMng3vZvMnAoX29BFfwhr")
    })
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}
function openPositionAccounts(params) {
  const {
    funder,
    ownerKey,
    positionPda,
    positionMintAddress,
    positionTokenAccountAddress,
    whirlpoolKey
  } = params;
  return {
    funder,
    owner: ownerKey,
    position: positionPda.publicKey,
    positionMint: positionMintAddress,
    positionTokenAccount: positionTokenAccountAddress,
    whirlpool: whirlpoolKey,
    tokenProgram: TOKEN_PROGRAM_ID2,
    systemProgram: SystemProgram3.programId,
    rent: anchor.web3.SYSVAR_RENT_PUBKEY,
    associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID
  };
}

// src/instructions/initialize-tick-array-ix.ts
import * as anchor2 from "@project-serum/anchor";
function buildInitTickArrayIx(context, params) {
  const program = context.program;
  const { whirlpool, funder, tickArrayPda } = params;
  const ix = program.instruction.initializeTickArray(params.startTick, {
    accounts: {
      whirlpool,
      funder,
      tickArray: tickArrayPda.publicKey,
      systemProgram: anchor2.web3.SystemProgram.programId
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/increase-liquidity-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID3 } from "@solana/spl-token";
function buildIncreaseLiquidityIx(context, params) {
  const {
    liquidityAmount,
    tokenMaxA,
    tokenMaxB,
    whirlpool,
    positionAuthority,
    position,
    positionTokenAccount,
    tokenOwnerAccountA,
    tokenOwnerAccountB,
    tokenVaultA,
    tokenVaultB,
    tickArrayLower,
    tickArrayUpper
  } = params;
  const ix = context.program.instruction.increaseLiquidity(liquidityAmount, tokenMaxA, tokenMaxB, {
    accounts: {
      whirlpool,
      tokenProgram: TOKEN_PROGRAM_ID3,
      positionAuthority,
      position,
      positionTokenAccount,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA,
      tokenVaultB,
      tickArrayLower,
      tickArrayUpper
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/collect-fees-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID4 } from "@solana/spl-token";
function buildCollectFeesIx(context, params) {
  const {
    whirlpool,
    positionAuthority,
    position,
    positionTokenAccount,
    tokenOwnerAccountA,
    tokenOwnerAccountB,
    tokenVaultA,
    tokenVaultB
  } = params;
  const ix = context.program.instruction.collectFees({
    accounts: {
      whirlpool,
      positionAuthority,
      position,
      positionTokenAccount,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA,
      tokenVaultB,
      tokenProgram: TOKEN_PROGRAM_ID4
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/collect-reward-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID5 } from "@solana/spl-token";
function buildCollectRewardIx(context, params) {
  const {
    whirlpool,
    positionAuthority,
    position,
    positionTokenAccount,
    rewardOwnerAccount,
    rewardVault,
    rewardIndex
  } = params;
  const ix = context.program.instruction.collectReward(rewardIndex, {
    accounts: {
      whirlpool,
      positionAuthority,
      position,
      positionTokenAccount,
      rewardOwnerAccount,
      rewardVault,
      tokenProgram: TOKEN_PROGRAM_ID5
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/swap-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID6 } from "@solana/spl-token";
function buildSwapIx(context, params) {
  const {
    amount,
    otherAmountThreshold,
    sqrtPriceLimit,
    amountSpecifiedIsInput,
    aToB,
    whirlpool,
    tokenAuthority,
    tokenOwnerAccountA,
    tokenVaultA,
    tokenOwnerAccountB,
    tokenVaultB,
    tickArray0,
    tickArray1,
    tickArray2,
    oracle
  } = params;
  const ix = context.program.instruction.swap(amount, otherAmountThreshold, sqrtPriceLimit, amountSpecifiedIsInput, aToB, {
    accounts: {
      tokenProgram: TOKEN_PROGRAM_ID6,
      tokenAuthority,
      whirlpool,
      tokenOwnerAccountA,
      tokenVaultA,
      tokenOwnerAccountB,
      tokenVaultB,
      tickArray0,
      tickArray1,
      tickArray2,
      oracle
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/initialize-reward-ix.ts
import * as anchor3 from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID7 } from "@solana/spl-token";
import { SystemProgram as SystemProgram4 } from "@solana/web3.js";
function buildInitializeRewardIx(context, params) {
  const { rewardAuthority, funder, whirlpool, rewardMint, rewardVaultKeypair, rewardIndex } = params;
  const ix = context.program.instruction.initializeReward(rewardIndex, {
    accounts: {
      rewardAuthority,
      funder,
      whirlpool,
      rewardMint,
      rewardVault: rewardVaultKeypair.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID7,
      systemProgram: SystemProgram4.programId,
      rent: anchor3.web3.SYSVAR_RENT_PUBKEY
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: [rewardVaultKeypair]
  };
}

// src/instructions/set-reward-emissions-super-authority-ix.ts
function buildSetRewardEmissionsSuperAuthorityIx(context, params) {
  const { whirlpoolsConfig, rewardEmissionsSuperAuthority, newRewardEmissionsSuperAuthority } = params;
  const ix = context.program.instruction.setRewardEmissionsSuperAuthority({
    accounts: {
      whirlpoolsConfig,
      rewardEmissionsSuperAuthority,
      newRewardEmissionsSuperAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-reward-authority-ix.ts
function buildSetRewardAuthorityIx(context, params) {
  const { whirlpool, rewardAuthority, newRewardAuthority, rewardIndex } = params;
  const ix = context.program.instruction.setRewardAuthority(rewardIndex, {
    accounts: {
      whirlpool,
      rewardAuthority,
      newRewardAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-reward-emissions-ix.ts
function buildSetRewardEmissionsIx(context, params) {
  const { rewardAuthority, whirlpool, rewardIndex, rewardVault, emissionsPerSecondX64 } = params;
  const ix = context.program.instruction.setRewardEmissions(rewardIndex, emissionsPerSecondX64, {
    accounts: {
      rewardAuthority,
      whirlpool,
      rewardVault
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/close-position-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID8 } from "@solana/spl-token";
function buildClosePositionIx(context, params) {
  const { positionAuthority, receiver, position, positionMint, positionTokenAccount } = params;
  const ix = context.program.instruction.closePosition({
    accounts: {
      positionAuthority,
      receiver,
      position,
      positionMint,
      positionTokenAccount,
      tokenProgram: TOKEN_PROGRAM_ID8
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-reward-authority-by-super-authority-ix.ts
function buildSetRewardAuthorityBySuperAuthorityIx(context, params) {
  const {
    whirlpoolsConfig,
    whirlpool,
    rewardEmissionsSuperAuthority,
    newRewardAuthority,
    rewardIndex
  } = params;
  const ix = context.program.instruction.setRewardAuthorityBySuperAuthority(rewardIndex, {
    accounts: {
      whirlpoolsConfig,
      whirlpool,
      rewardEmissionsSuperAuthority,
      newRewardAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-fee-authority-ix.ts
function buildSetFeeAuthorityIx(context, params) {
  const { whirlpoolsConfig, feeAuthority, newFeeAuthority } = params;
  const ix = context.program.instruction.setFeeAuthority({
    accounts: {
      whirlpoolsConfig,
      feeAuthority,
      newFeeAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-collect-protocol-fees-authority-ix.ts
function buildSetCollectProtocolFeesAuthorityIx(context, params) {
  const { whirlpoolsConfig, collectProtocolFeesAuthority, newCollectProtocolFeesAuthority } = params;
  const ix = context.program.instruction.setCollectProtocolFeesAuthority({
    accounts: {
      whirlpoolsConfig,
      collectProtocolFeesAuthority,
      newCollectProtocolFeesAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/update-fees-and-rewards-ix.ts
function buildUpdateFeesAndRewardsIx(context, params) {
  const { whirlpool, position, tickArrayLower, tickArrayUpper } = params;
  const ix = context.program.instruction.updateFeesAndRewards({
    accounts: {
      whirlpool,
      position,
      tickArrayLower,
      tickArrayUpper
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/collect-protocol-fees-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID9 } from "@solana/spl-token";
function buildCollectProtocolFeesIx(context, params) {
  const {
    whirlpoolsConfig,
    whirlpool,
    collectProtocolFeesAuthority,
    tokenVaultA,
    tokenVaultB,
    tokenDestinationA,
    tokenDestinationB
  } = params;
  const ix = context.program.instruction.collectProtocolFees({
    accounts: {
      whirlpoolsConfig,
      whirlpool,
      collectProtocolFeesAuthority,
      tokenVaultA,
      tokenVaultB,
      tokenDestinationA,
      tokenDestinationB,
      tokenProgram: TOKEN_PROGRAM_ID9
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/decrease-liquidity-ix.ts
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID10 } from "@solana/spl-token";
function buildDecreaseLiquidityIx(context, params) {
  const {
    liquidityAmount,
    tokenMinA,
    tokenMinB,
    whirlpool,
    positionAuthority,
    position,
    positionTokenAccount,
    tokenOwnerAccountA,
    tokenOwnerAccountB,
    tokenVaultA,
    tokenVaultB,
    tickArrayLower,
    tickArrayUpper
  } = params;
  const ix = context.program.instruction.decreaseLiquidity(liquidityAmount, tokenMinA, tokenMinB, {
    accounts: {
      whirlpool,
      tokenProgram: TOKEN_PROGRAM_ID10,
      positionAuthority,
      position,
      positionTokenAccount,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA,
      tokenVaultB,
      tickArrayLower,
      tickArrayUpper
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-fee-rate-ix.ts
function buildSetFeeRateIx(context, params) {
  const { whirlpoolsConfig, whirlpool, feeAuthority, feeRate } = params;
  const ix = context.program.instruction.setFeeRate(feeRate, {
    accounts: {
      whirlpoolsConfig,
      whirlpool,
      feeAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-default-protocol-fee-rate-ix.ts
function buildSetDefaultProtocolFeeRateIx(context, params) {
  const { whirlpoolsConfig, feeAuthority, defaultProtocolFeeRate } = params;
  const ix = context.program.instruction.setDefaultProtocolFeeRate(defaultProtocolFeeRate, {
    accounts: {
      whirlpoolsConfig,
      feeAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-default-fee-rate-ix.ts
function buildSetDefaultFeeRateIx(context, params) {
  const { whirlpoolsConfig, feeAuthority, tickSpacing, defaultFeeRate } = params;
  const feeTierPda = getFeeTierPda(context.program.programId, whirlpoolsConfig, tickSpacing);
  const ix = context.program.instruction.setDefaultFeeRate(defaultFeeRate, {
    accounts: {
      whirlpoolsConfig,
      feeTier: feeTierPda.publicKey,
      feeAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/set-protocol-fee-rate-ix.ts
function buildSetProtocolFeeRateIx(context, params) {
  const { whirlpoolsConfig, whirlpool, feeAuthority, protocolFeeRate } = params;
  const ix = context.program.instruction.setProtocolFeeRate(protocolFeeRate, {
    accounts: {
      whirlpoolsConfig,
      whirlpool,
      feeAuthority
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/instructions/initialize-fee-tier.ts
import { SystemProgram as SystemProgram5 } from "@solana/web3.js";
function buildInitializeFeeTier(context, params) {
  const { feeTierPda, whirlpoolConfigKey, tickSpacing, feeAuthority, defaultFeeRate, funder } = params;
  const ix = context.program.instruction.initializeFeeTier(tickSpacing, defaultFeeRate, {
    accounts: {
      config: whirlpoolConfigKey,
      feeTier: feeTierPda.publicKey,
      feeAuthority,
      funder,
      systemProgram: SystemProgram5.programId
    }
  });
  return {
    instructions: [ix],
    cleanupInstructions: [],
    signers: []
  };
}

// src/client.ts
import { Decimal as Decimal3 } from "decimal.js";
Decimal3.set({ precision: 40, toExpPos: 40, toExpNeg: -20, rounding: 1 });
var WhirlpoolClient = class {
  constructor(context) {
    this.context = context;
  }
  initConfigTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildInitializeConfigIx(this.context, params));
  }
  async getConfig(configPubKey) {
    const program = this.context.program;
    const account = await program.account.whirlpoolsConfig.fetch(configPubKey);
    return account;
  }
  parseConfig(data) {
    return parseWhirlpoolsConfig(data);
  }
  initFeeTierTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildInitializeFeeTier(this.context, params));
  }
  async getFeeTier(feeTierKey) {
    const program = this.context.program;
    const feeTierAccount = await program.account.feeTier.fetch(feeTierKey);
    return feeTierAccount;
  }
  initPoolTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildInitPoolIx(this.context, params));
  }
  async getPool(poolKey) {
    const program = this.context.program;
    const whirlpoolAccount = await program.account.whirlpool.fetch(poolKey);
    return whirlpoolAccount;
  }
  parsePool(data) {
    return parseWhirlpool(data);
  }
  openPositionTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildOpenPositionIx(this.context, params));
  }
  openPositionWithMetadataTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildOpenPositionWithMetadataIx(this.context, params));
  }
  closePositionTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildClosePositionIx(this.context, params));
  }
  async getPosition(positionKey) {
    const positionAccount = await this.context.program.account.position.fetch(positionKey);
    return positionAccount;
  }
  parsePosition(data) {
    return parsePosition(data);
  }
  initTickArrayTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildInitTickArrayIx(this.context, params));
  }
  async getTickArray(arrayPubKey) {
    const program = this.context.program;
    const tickArrayAccount = await program.account.tickArray.fetch(arrayPubKey);
    return tickArrayAccount;
  }
  parseTickArray(data) {
    return parseTickArray(data);
  }
  initializeRewardTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildInitializeRewardIx(this.context, params));
  }
  setRewardEmissionsTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetRewardEmissionsIx(this.context, params));
  }
  increaseLiquidityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildIncreaseLiquidityIx(this.context, params));
  }
  decreaseLiquidityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildDecreaseLiquidityIx(this.context, params));
  }
  updateFeesAndRewards(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildUpdateFeesAndRewardsIx(this.context, params));
  }
  collectFeesTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildCollectFeesIx(this.context, params));
  }
  collectRewardTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildCollectRewardIx(this.context, params));
  }
  collectProtocolFeesTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildCollectProtocolFeesIx(this.context, params));
  }
  swapTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSwapIx(this.context, params));
  }
  setRewardEmissionsSuperAuthorityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetRewardEmissionsSuperAuthorityIx(this.context, params));
  }
  setRewardAuthorityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetRewardAuthorityIx(this.context, params));
  }
  setRewardAuthorityBySuperAuthorityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetRewardAuthorityBySuperAuthorityIx(this.context, params));
  }
  setFeeAuthorityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetFeeAuthorityIx(this.context, params));
  }
  setCollectProtocolFeesAuthorityTx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetCollectProtocolFeesAuthorityIx(this.context, params));
  }
  setFeeRateIx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetFeeRateIx(this.context, params));
  }
  setProtocolFeeRateIx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetProtocolFeeRateIx(this.context, params));
  }
  setDefaultFeeRateIx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetDefaultFeeRateIx(this.context, params));
  }
  setDefaultProtocolFeeRateIx(params) {
    return new TransactionBuilder(this.context.provider).addInstruction(buildSetDefaultProtocolFeeRateIx(this.context, params));
  }
};

// src/context.ts
import { Provider, Program } from "@project-serum/anchor";
var WhirlpoolContext = class {
  static from(connection, wallet, programId, opts = Provider.defaultOptions()) {
    const provider = new Provider(connection, wallet, opts);
    const program = new Program(whirlpool_default, programId, provider);
    return new WhirlpoolContext(provider, program, opts);
  }
  static fromWorkspace(provider, program, opts = Provider.defaultOptions()) {
    return new WhirlpoolContext(provider, program, opts);
  }
  static withProvider(provider, programId, opts = Provider.defaultOptions()) {
    const program = new Program(whirlpool_default, programId, provider);
    return new WhirlpoolContext(provider, program, opts);
  }
  constructor(provider, program, opts) {
    this.connection = provider.connection;
    this.wallet = provider.wallet;
    this.opts = opts;
    this.program = program;
    this.provider = provider;
  }
};

// src/types/public/constants.ts
var NUM_REWARDS = 3;
export {
  AccountName,
  EMPTY_INSTRUCTION,
  MAX_SQRT_PRICE,
  MAX_TICK_INDEX,
  METADATA_PROGRAM_ADDRESS,
  MIN_SQRT_PRICE,
  MIN_TICK_INDEX,
  NUM_REWARDS,
  TICK_ARRAY_SIZE,
  TransactionBuilder,
  WhirlpoolClient,
  WhirlpoolContext,
  estimateLiquidityFromTokenAmounts,
  fromX64,
  fromX64_BN,
  fromX64_Decimal,
  getFeeTierPda,
  getOraclePda,
  getPositionMetadataPda,
  getPositionPda,
  getStartTickIndex,
  getTickArrayPda,
  getTokenAmountsFromLiquidity,
  getWhirlpoolPda,
  getWhirlpoolVaultAPda,
  getWhirlpoolVaultBPda,
  parsePosition,
  parseTickArray,
  parseWhirlpool,
  parseWhirlpoolsConfig,
  sqrtPriceX64ToTickIndex,
  tickIndexToSqrtPriceX64,
  toTokenAmount,
  toX64,
  toX64_BN,
  toX64_Decimal
};
