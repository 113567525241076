var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PublicKey } from '@solana/web3.js';
import { toSvg } from 'jdenticon';
import fetch from 'cross-fetch';
import btoa from 'btoa';
import { ProfilePictureAccountLayout } from './layouts';
import { PROFILE_PICTURE_PROGRAM } from './index';
import { Buffer } from 'buffer';
var PROFILE_PICTURE_PREFIX = 'nft_profile';
var canBeResized = function (url) {
    return !(url.includes('.svg') || url.endsWith('.svg') || url.endsWith('=svg') || url.endsWith('.gif') || url.endsWith('=gif') || url.startsWith('data:'));
};
export var getResizedImageUrl = function (url, options) {
    if (options === void 0) { options = { width: 100 }; }
    if (!url) {
        return false;
    }
    if (!canBeResized(url)) {
        return url;
    }
    var optionsString = Object.keys(options).map(function (item) { return "".concat(encodeURIComponent(item), "=").concat(encodeURIComponent(options[item])); }).join(',');
    return "https://solana-cdn.com/cdn-cgi/image/".concat(optionsString, "/").concat(url);
};
export function decodeProfilePictureAccount(account) {
    if (account.data.length !== ProfilePictureAccountLayout.span) {
        throw new Error("Invalid account size. Expected ".concat(ProfilePictureAccountLayout.span, ", got ").concat(account.data.length));
    }
    return ProfilePictureAccountLayout.decode(account.data);
}
export function getProfilePicturePDA(publicKey) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from(PROFILE_PICTURE_PREFIX),
                        publicKey.toBuffer()
                    ], PROFILE_PICTURE_PROGRAM)];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 1]), result = _a[0];
                    return [2 /*return*/, result];
            }
        });
    });
}
export function getMetadataFromUrl(url) {
    return __awaiter(this, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    if (response.status >= 300) {
                        return [2 /*return*/, null];
                    }
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, (_a.sent()) || null];
                case 3:
                    err_1 = _a.sent();
                    return [2 /*return*/, null];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function generateUrl(url, ownerPublicKey, config) {
    if (url) {
        if (config.resize) {
            return getResizedImageUrl(url, config.resize);
        }
        return url;
    }
    if (config.fallback) {
        var svg = toSvg(ownerPublicKey.toString(), 100);
        return "data:image/svg+xml;base64,".concat(btoa(svg));
    }
    return 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgaGVpZ2h0PSIxMDAiIHdpZHRoPSIxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgaWQ9ImJvcmRlcnMtYW5kLWJhY2tncm91bmRzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LDQpIj48cGF0aCBkPSJtMCAwaDEwdjFoLTl2MTRoMXYxaC0yeiIvPjxwYXRoIGQ9Im0xMCA0aDR2M2gtMXYtMmgtM3oiLz48cGF0aCBkPSJtMTQgMTZ2LTZoLTF2NWgtNXYxeiIvPjxwYXRoIGQ9Im0xMiAxNHYtM2gtMXYyaC0ydjF6IiBmaWxsPSIjYmNiY2MzIi8+PHBhdGggZD0ibTEwIDBoMXYxaDF2MWgxdjFoMXYxaC0xdi0xaC0xdi0xaC0xdjJoLTF6IiBmaWxsPSIjODc4Nzg3Ii8+PHBhdGggZD0ibTIgMmg4djNoMnYyaC00djVoLTJ2MWgtMnYxaC0yeiIgZmlsbD0iI2JjYmNjMyIvPjwvZz48ZyBpZD0ibGVmdGV5ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNSw0KSI+PHBhdGggZD0ibTUgM2gydjNoLTN2LTJoMXYxaDF2LTFoLTF6IiBmaWxsPSIjMDA4OTFlIi8+PHBhdGggZD0ibTUgNGgxdjFoLTF6IiBmaWxsPSIjMDBmMjQ4Ii8+PHBhdGggZD0ibTcgNGgxdjJoLTF2MWgtMnYtMWgyeiIvPjwvZz48ZyBpZD0icmlnaHRleWUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUsNCkiPjxwYXRoIGQ9Im04IDdoM3YyaC0xdi0xaC0xdjFoMXYxaC0yeiIgZmlsbD0iIzAwNjRmYiIvPjxwYXRoIGQ9Im05IDhoMXYxaC0xeiIgZmlsbD0iIzAwZmJmZSIvPjxwYXRoIGQ9Im0xMCA5aDF2MWgtMXoiIGZpbGw9IiMwMDMyOTMiLz48cGF0aCBkPSJtMTEgN2gxdjJoLTF6Ii8+PHBhdGggZD0ibTggMTBoMnYxaC0yeiIvPjwvZz48ZyBpZD0ibW91dGgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUsNCkiPjxwYXRoIGQ9Im0zIDhoMXYxaDF2MWgxdjFoMXYxaC0xdi0xaC0xdi0xaC0xdi0xaC0xeiIgZmlsbD0iI2ZmMzkwMCIvPjxwYXRoIGQ9Im0zIDloMXYxaDF2MWgxdjFoLTN6IiBmaWxsPSIjZjczYWUxIi8+PHBhdGggZD0ibTMgMTJoM3YxaC0zeiIvPjwvZz48L3N2Zz4=';
}
