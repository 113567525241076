var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};

// src/client.ts
import { NATIVE_MINT as NATIVE_MINT5 } from "@solana/spl-token";
import Decimal7 from "decimal.js";

// src/admin/orca-admin.ts
import { Keypair as Keypair2 } from "@solana/web3.js";
import invariant from "tiny-invariant";

// src/utils/address.ts
import { translateAddress } from "@project-serum/anchor";
function toPubKey(address) {
  return translateAddress(address);
}
function toPubKeys(addresses) {
  return addresses.map((address) => toPubKey(address));
}

// src/admin/orca-admin.ts
import {
  TransactionBuilder,
  WhirlpoolContext,
  WhirlpoolClient,
  getWhirlpoolPda,
  NUM_REWARDS,
  getFeeTierPda
} from "@orca-so/whirlpool-client-sdk";

// src/utils/web3/ata-utils.ts
import {
  AccountLayout as AccountLayout3,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT as NATIVE_MINT2,
  Token as Token2,
  TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID2,
  u64 as u643
} from "@solana/spl-token";

// src/utils/web3/deserialize-token-account.ts
import { AccountLayout, u64 } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
var deserializeTokenAccount = (data) => {
  if (!data) {
    return null;
  }
  const accountInfo = AccountLayout.decode(data);
  accountInfo.mint = new PublicKey(accountInfo.mint);
  accountInfo.owner = new PublicKey(accountInfo.owner);
  accountInfo.amount = u64.fromBuffer(accountInfo.amount);
  if (accountInfo.delegateOption === 0) {
    accountInfo.delegate = null;
    accountInfo.delegatedAmount = new u64(0);
  } else {
    accountInfo.delegate = new PublicKey(accountInfo.delegate);
    accountInfo.delegatedAmount = u64.fromBuffer(accountInfo.delegatedAmount);
  }
  accountInfo.isInitialized = accountInfo.state !== 0;
  accountInfo.isFrozen = accountInfo.state === 2;
  if (accountInfo.isNativeOption === 1) {
    accountInfo.rentExemptReserve = u64.fromBuffer(accountInfo.isNative);
    accountInfo.isNative = true;
  } else {
    accountInfo.rentExemptReserve = null;
    accountInfo.isNative = false;
  }
  if (accountInfo.closeAuthorityOption === 0) {
    accountInfo.closeAuthority = null;
  } else {
    accountInfo.closeAuthority = new PublicKey(accountInfo.closeAuthority);
  }
  return accountInfo;
};

// src/utils/web3/helpers.ts
var emptyInstruction = {
  instructions: [],
  cleanupInstructions: [],
  signers: []
};

// src/utils/web3/token-instructions.ts
import { AccountLayout as AccountLayout2, NATIVE_MINT, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Keypair, SystemProgram } from "@solana/web3.js";
function createWSOLAccountInstructions(walletAddress, amountIn, rentExemptLamports) {
  const tempAccount = new Keypair();
  const createAccountInstruction = SystemProgram.createAccount({
    fromPubkey: walletAddress,
    newAccountPubkey: tempAccount.publicKey,
    lamports: amountIn.toNumber() + rentExemptLamports,
    space: AccountLayout2.span,
    programId: TOKEN_PROGRAM_ID
  });
  const initAccountInstruction = Token.createInitAccountInstruction(TOKEN_PROGRAM_ID, NATIVE_MINT, tempAccount.publicKey, walletAddress);
  const closeWSOLAccountInstruction = Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, tempAccount.publicKey, walletAddress, walletAddress, []);
  return {
    address: tempAccount.publicKey,
    instructions: [createAccountInstruction, initAccountInstruction],
    cleanupInstructions: [closeWSOLAccountInstruction],
    signers: [tempAccount]
  };
}

// src/utils/web3/ata-utils.ts
async function resolveOrCreateATA(connection, ownerAddress, tokenMint, wrappedSolAmountIn = new u643(0)) {
  if (!tokenMint.equals(NATIVE_MINT2)) {
    const ataAddress = await deriveATA(ownerAddress, tokenMint);
    const tokenAccountInfo = await connection.getAccountInfo(ataAddress);
    const tokenAccount = deserializeTokenAccount(tokenAccountInfo == null ? void 0 : tokenAccountInfo.data);
    if (tokenAccount) {
      return __spreadValues({ address: ataAddress }, emptyInstruction);
    }
    const createAtaInstruction = Token2.createAssociatedTokenAccountInstruction(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID2, tokenMint, ataAddress, ownerAddress, ownerAddress);
    return {
      address: ataAddress,
      instructions: [createAtaInstruction],
      cleanupInstructions: [],
      signers: []
    };
  } else {
    const accountRentExempt = await connection.getMinimumBalanceForRentExemption(AccountLayout3.span);
    return createWSOLAccountInstructions(ownerAddress, wrappedSolAmountIn, accountRentExempt);
  }
}
async function deriveATA(ownerAddress, tokenMint) {
  return await Token2.getAssociatedTokenAddress(ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID2, tokenMint, ownerAddress);
}

// src/admin/orca-admin.ts
var OrcaAdmin = class {
  constructor(dal) {
    this.dal = dal;
  }
  getInitPoolTx(param) {
    const { provider, initSqrtPrice, tokenMintA, tokenMintB, tickSpacing } = param;
    const { programId, whirlpoolsConfig: whirlpoolConfigKey } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    const whirlpoolPda = getWhirlpoolPda(programId, whirlpoolConfigKey, toPubKey(tokenMintA), toPubKey(tokenMintB), tickSpacing);
    const feeTierPda = getFeeTierPda(programId, whirlpoolConfigKey, tickSpacing);
    const tx = client.initPoolTx({
      initSqrtPrice,
      whirlpoolConfigKey,
      tokenMintA: toPubKey(tokenMintA),
      tokenMintB: toPubKey(tokenMintB),
      whirlpoolPda,
      tokenVaultAKeypair: Keypair2.generate(),
      tokenVaultBKeypair: Keypair2.generate(),
      tickSpacing,
      feeTierKey: feeTierPda.publicKey,
      funder: provider.wallet.publicKey
    });
    return { tx, address: whirlpoolPda.publicKey };
  }
  async getCollectProtocolFeesTx(param) {
    const { provider, poolAddress } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    const whirlpool = await this.dal.getPool(poolAddress, true);
    invariant(!!whirlpool, "OrcaAdmin - whirlpool does not exist");
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA), { address: tokenDestinationA } = _a, createTokenAAtaIx = __objRest(_a, ["address"]);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB), { address: tokenDestinationB } = _b, createTokenBAtaIx = __objRest(_b, ["address"]);
    const collectFeesIx = client.collectProtocolFeesTx({
      whirlpoolsConfig,
      whirlpool: toPubKey(poolAddress),
      collectProtocolFeesAuthority: provider.wallet.publicKey,
      tokenVaultA: whirlpool.tokenVaultA,
      tokenVaultB: whirlpool.tokenVaultB,
      tokenDestinationA: toPubKey(tokenDestinationA),
      tokenDestinationB: toPubKey(tokenDestinationB)
    }).compressIx(false);
    return new TransactionBuilder(provider).addInstruction(createTokenAAtaIx).addInstruction(createTokenBAtaIx).addInstruction(collectFeesIx);
  }
  getSetFeeAuthorityTx(param) {
    const { provider, newFeeAuthority } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    return client.setFeeAuthorityTx({
      whirlpoolsConfig,
      feeAuthority: provider.wallet.publicKey,
      newFeeAuthority: toPubKey(newFeeAuthority)
    });
  }
  async getSetFeeRateTx(param) {
    const { provider, feeRate, poolAddress } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    const whirlpoolsConfigAccount = await this.dal.getConfig(whirlpoolsConfig, true);
    invariant(!!whirlpoolsConfigAccount, `OrcaAdmin - Whirlpool config doesn't exist ${whirlpoolsConfig.toBase58()}`);
    return client.setFeeRateIx({
      whirlpool: toPubKey(poolAddress),
      whirlpoolsConfig,
      feeAuthority: whirlpoolsConfigAccount.feeAuthority,
      feeRate
    });
  }
  async getSetProtocolFeeRateTx(param) {
    const { provider, protocolFeeRate, poolAddress } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    const whirlpoolsConfigAccount = await this.dal.getConfig(whirlpoolsConfig, true);
    invariant(!!whirlpoolsConfigAccount, `OrcaAdmin - Whirlpool config doesn't exist ${whirlpoolsConfig.toBase58()}`);
    return client.setProtocolFeeRateIx({
      whirlpool: toPubKey(poolAddress),
      whirlpoolsConfig,
      feeAuthority: whirlpoolsConfigAccount.feeAuthority,
      protocolFeeRate
    });
  }
  getSetCollectProtocolFeesAuthorityTx(param) {
    const { provider, newCollectProtocolFeesAuthority } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    return client.setCollectProtocolFeesAuthorityTx({
      whirlpoolsConfig,
      collectProtocolFeesAuthority: provider.wallet.publicKey,
      newCollectProtocolFeesAuthority: toPubKey(newCollectProtocolFeesAuthority)
    });
  }
  getInitRewardTx(param) {
    const { provider, rewardAuthority, poolAddress, rewardMint, rewardIndex } = param;
    const ctx = WhirlpoolContext.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient(ctx);
    invariant(rewardIndex < NUM_REWARDS, "invalid rewardIndex");
    const rewardVaultKeypair = Keypair2.generate();
    const tx = client.initializeRewardTx({
      rewardAuthority: toPubKey(rewardAuthority),
      funder: provider.wallet.publicKey,
      whirlpool: toPubKey(poolAddress),
      rewardMint: toPubKey(rewardMint),
      rewardVaultKeypair,
      rewardIndex
    });
    return { tx, rewardVault: rewardVaultKeypair.publicKey };
  }
  getSetRewardAuthorityTx(param) {
    const { provider, poolAddress, newRewardAuthority, rewardIndex } = param;
    const ctx = WhirlpoolContext.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient(ctx);
    invariant(rewardIndex < NUM_REWARDS, "invalid rewardIndex");
    return client.setRewardAuthorityTx({
      whirlpool: toPubKey(poolAddress),
      rewardAuthority: provider.wallet.publicKey,
      newRewardAuthority: toPubKey(newRewardAuthority),
      rewardIndex
    });
  }
  async getSetRewardEmissionsTx(param) {
    var _a;
    const { provider, poolAddress, rewardIndex, emissionsPerSecondX64 } = param;
    const ctx = WhirlpoolContext.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient(ctx);
    invariant(rewardIndex < NUM_REWARDS, "invalid rewardIndex");
    const whirlpool = await this.dal.getPool(poolAddress, true);
    const rewardVault = (_a = whirlpool == null ? void 0 : whirlpool.rewardInfos[rewardIndex]) == null ? void 0 : _a.vault;
    invariant(!!rewardVault, "reward vault doeos not exist");
    return client.setRewardEmissionsTx({
      rewardAuthority: provider.wallet.publicKey,
      whirlpool: toPubKey(poolAddress),
      rewardIndex,
      emissionsPerSecondX64,
      rewardVault
    });
  }
  getSetRewardAuthorityBySuperAuthorityTx(param) {
    const { provider, poolAddress, newRewardAuthority, rewardIndex } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    invariant(rewardIndex < NUM_REWARDS, "invalid rewardIndex");
    return client.setRewardAuthorityBySuperAuthorityTx({
      whirlpoolsConfig,
      whirlpool: toPubKey(poolAddress),
      rewardEmissionsSuperAuthority: provider.wallet.publicKey,
      newRewardAuthority: toPubKey(newRewardAuthority),
      rewardIndex
    });
  }
  getSetRewardEmissionsBySuperAuthorityTx(param) {
    const { provider, rewardEmissionsSuperAuthority, newRewardEmissionsSuperAuthority } = param;
    const { programId, whirlpoolsConfig } = this.dal;
    const ctx = WhirlpoolContext.withProvider(provider, programId);
    const client = new WhirlpoolClient(ctx);
    return client.setRewardEmissionsSuperAuthorityTx({
      whirlpoolsConfig,
      rewardEmissionsSuperAuthority: toPubKey(rewardEmissionsSuperAuthority),
      newRewardEmissionsSuperAuthority: toPubKey(newRewardEmissionsSuperAuthority)
    });
  }
};

// src/constants/public/defaults.ts
import { Connection } from "@solana/web3.js";

// src/utils/public/percentage.ts
import { u64 as u644 } from "@solana/spl-token";
import Decimal from "decimal.js";
var Percentage = class {
  constructor(numerator, denominator) {
    this.toString = () => {
      return `${this.numerator.toString()}/${this.denominator.toString()}`;
    };
    this.numerator = numerator;
    this.denominator = denominator;
  }
  static fromDecimal(number) {
    return Percentage.fromFraction(number.toDecimalPlaces(1).mul(10).toNumber(), 1e3);
  }
  static fromFraction(numerator, denominator) {
    const num = typeof numerator === "number" ? new u644(numerator.toString()) : numerator;
    const denom = typeof denominator === "number" ? new u644(denominator.toString()) : denominator;
    return new Percentage(num, denom);
  }
  toDecimal() {
    if (this.denominator.eq(new u644(0))) {
      return new Decimal(0);
    }
    return new Decimal(this.numerator.toString()).div(new Decimal(this.denominator.toString()));
  }
  add(p2) {
    const denomGcd = this.denominator.gcd(p2.denominator);
    const denomLcm = this.denominator.div(denomGcd).mul(p2.denominator);
    const p1DenomAdjustment = denomLcm.div(this.denominator);
    const p2DenomAdjustment = denomLcm.div(p2.denominator);
    const p1NumeratorAdjusted = this.numerator.mul(p1DenomAdjustment);
    const p2NumeratorAdjusted = p2.numerator.mul(p2DenomAdjustment);
    const newNumerator = p1NumeratorAdjusted.add(p2NumeratorAdjusted);
    return new Percentage(new u644(newNumerator.toString()), new u644(denomLcm.toString()));
  }
};

// src/constants/public/network.ts
var OrcaNetwork = /* @__PURE__ */ ((OrcaNetwork2) => {
  OrcaNetwork2["MAINNET"] = "mainnet";
  OrcaNetwork2["DEVNET"] = "devnet";
  return OrcaNetwork2;
})(OrcaNetwork || {});

// src/constants/public/defaults.ts
var defaultSlippagePercentage = Percentage.fromFraction(1, 1e3);
var ZERO_SLIPPAGE = Percentage.fromFraction(0, 1e3);
var defaultNetwork = "mainnet" /* MAINNET */;
function getDefaultConnection(network) {
  switch (network) {
    case "mainnet" /* MAINNET */:
      return new Connection("https://ssc-dao.genesysgo.net", "processed");
    case "devnet" /* DEVNET */:
      return new Connection("https://api.devnet.solana.com", "processed");
    default:
      throw new Error(`type ${network} is an Unknown network`);
  }
}
function getDefaultOffchainDataURI(network) {
  switch (network) {
    case "mainnet" /* MAINNET */:
      return "https://mainnet-zp2-v2.orca.so";
    case "devnet" /* DEVNET */:
      return "https://devnet-zp2-v2.orca.so";
    default:
      throw new Error(`type ${network} is an Unknown network`);
  }
}

// src/constants/programs.ts
import { PublicKey as PublicKey4 } from "@solana/web3.js";
function getWhirlpoolsConfig(network) {
  switch (network) {
    case "mainnet" /* MAINNET */:
      return new PublicKey4("2LecshUwdy9xi7meFgHtFJQNSKk4KdTrcpvaB56dP2NQ");
    case "devnet" /* DEVNET */:
      return new PublicKey4("847gd7SckNwJetbjf22ktip9vAtKWPMY4ntdr6CdCsJj");
    default:
      throw new Error(`type ${network} is an Unknown network`);
  }
}
function getWhirlpoolProgramId(network) {
  switch (network) {
    case "mainnet" /* MAINNET */:
      return new PublicKey4("whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc");
    case "devnet" /* DEVNET */:
      return new PublicKey4("whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc");
    default:
      throw new Error(`type ${network} is an Unknown network`);
  }
}

// src/dal/orca-dal.ts
import invariant2 from "tiny-invariant";
import { TOKEN_PROGRAM_ID as TOKEN_PROGRAM_ID3 } from "@solana/spl-token";

// src/dal/parse.ts
import {
  parsePosition,
  parseTickArray,
  parseWhirlpool,
  parseWhirlpoolsConfig
} from "@orca-so/whirlpool-client-sdk";
import { MintLayout, u64 as u645 } from "@solana/spl-token";
import { PublicKey as PublicKey5 } from "@solana/web3.js";
var ParsableWhirlpoolsConfig = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      return parseWhirlpoolsConfig(data);
    } catch (e) {
      console.error(`error while parsing WhirlpoolsConfig: ${e}`);
      return null;
    }
  }
};
ParsableWhirlpoolsConfig = __decorateClass([
  staticImplements()
], ParsableWhirlpoolsConfig);
var ParsableWhirlpool = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      return parseWhirlpool(data);
    } catch (e) {
      console.error(`error while parsing Whirlpool: ${e}`);
      return null;
    }
  }
};
ParsableWhirlpool = __decorateClass([
  staticImplements()
], ParsableWhirlpool);
var ParsablePosition = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      return parsePosition(data);
    } catch (e) {
      console.error(`error while parsing Position: ${e}`);
      return null;
    }
  }
};
ParsablePosition = __decorateClass([
  staticImplements()
], ParsablePosition);
var ParsableTickArray = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      return parseTickArray(data);
    } catch (e) {
      console.error(`error while parsing TickArray: ${e}`);
      return null;
    }
  }
};
ParsableTickArray = __decorateClass([
  staticImplements()
], ParsableTickArray);
var ParsableTokenInfo = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      return deserializeTokenAccount(data);
    } catch (e) {
      console.error(`error while parsing TokenAccount: ${e}`);
      return null;
    }
  }
};
ParsableTokenInfo = __decorateClass([
  staticImplements()
], ParsableTokenInfo);
var ParsableMintInfo = class {
  constructor() {
  }
  static parse(data) {
    if (!data) {
      return null;
    }
    try {
      const buffer = MintLayout.decode(data);
      const mintInfo = {
        mintAuthority: buffer.mintAuthorityOption === 0 ? null : new PublicKey5(buffer.mintAuthority),
        supply: u645.fromBuffer(buffer.supply),
        decimals: buffer.decimals,
        isInitialized: buffer.isInitialized !== 0,
        freezeAuthority: buffer.freezeAuthority === 0 ? null : new PublicKey5(buffer.freezeAuthority)
      };
      return mintInfo;
    } catch (e) {
      console.error(`error while parsing MintInfo: ${e}`);
      return null;
    }
  }
};
ParsableMintInfo = __decorateClass([
  staticImplements()
], ParsableMintInfo);
function staticImplements() {
  return (constructor) => {
    constructor;
  };
}

// src/dal/orca-dal.ts
var OrcaDAL = class {
  constructor(whirlpoolsConfig, programId, connection) {
    this._cache = {};
    this._userTokens = [];
    this.whirlpoolsConfig = toPubKey(whirlpoolsConfig);
    this.programId = toPubKey(programId);
    this.connection = connection;
  }
  async getPool(address, refresh) {
    return this.get(toPubKey(address), ParsableWhirlpool, refresh);
  }
  async getPosition(address, refresh) {
    return this.get(toPubKey(address), ParsablePosition, refresh);
  }
  async getTickArray(address, refresh) {
    return this.get(toPubKey(address), ParsableTickArray, refresh);
  }
  async getTokenInfo(address, refresh) {
    return this.get(toPubKey(address), ParsableTokenInfo, refresh);
  }
  async getMintInfo(address, refresh) {
    return this.get(toPubKey(address), ParsableMintInfo, refresh);
  }
  async getConfig(address, refresh) {
    return this.get(toPubKey(address), ParsableWhirlpoolsConfig, refresh);
  }
  async listPools(addresses, refresh) {
    return this.list(toPubKeys(addresses), ParsableWhirlpool, refresh);
  }
  async listPositions(addresses, refresh) {
    return this.list(toPubKeys(addresses), ParsablePosition, refresh);
  }
  async listTickArrays(addresses, refresh) {
    return this.list(toPubKeys(addresses), ParsableTickArray, refresh);
  }
  async listTokenInfos(addresses, refresh) {
    return this.list(toPubKeys(addresses), ParsableTokenInfo, refresh);
  }
  async listMintInfos(addresses, refresh) {
    return this.list(toPubKeys(addresses), ParsableMintInfo, refresh);
  }
  async listUserTokens(walletAddress, refresh) {
    if (!this._userTokens || refresh) {
      const filter = { programId: TOKEN_PROGRAM_ID3 };
      const { value } = await this.connection.getParsedTokenAccountsByOwner(toPubKey(walletAddress), filter);
      const userTokens = value.map((accountInfo) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
        return {
          address: accountInfo.pubkey,
          amount: (_e = (_d = (_c = (_b = (_a = accountInfo.account) == null ? void 0 : _a.data) == null ? void 0 : _b.parsed) == null ? void 0 : _c.info) == null ? void 0 : _d.tokenAmount) == null ? void 0 : _e.amount,
          decimals: (_j = (_i = (_h = (_g = (_f = accountInfo.account) == null ? void 0 : _f.data) == null ? void 0 : _g.parsed) == null ? void 0 : _h.info) == null ? void 0 : _i.tokenAmount) == null ? void 0 : _j.decimals,
          mint: (_n = (_m = (_l = (_k = accountInfo.account) == null ? void 0 : _k.data) == null ? void 0 : _l.parsed) == null ? void 0 : _m.info) == null ? void 0 : _n.mint
        };
      });
      this._userTokens = userTokens;
      return userTokens;
    }
    return this._userTokens;
  }
  async refreshAll() {
    const addresses = Object.keys(this._cache);
    const data = await this.bulkRequest(addresses);
    for (const [idx, [key, cachedContent]] of Object.entries(this._cache).entries()) {
      const entity = cachedContent.entity;
      const value = entity.parse(data[idx]);
      this._cache[key] = { entity, value };
    }
  }
  async get(address, entity, refresh) {
    var _a;
    const key = address.toBase58();
    const cachedValue = (_a = this._cache[key]) == null ? void 0 : _a.value;
    if (cachedValue !== void 0 && !refresh) {
      return cachedValue;
    }
    const accountInfo = await this.connection.getAccountInfo(address);
    const accountData = accountInfo == null ? void 0 : accountInfo.data;
    const value = entity.parse(accountData);
    this._cache[key] = { entity, value };
    return value;
  }
  async list(addresses, entity, refresh) {
    const keys = addresses.map((address) => address.toBase58());
    const cachedValues = keys.map((key) => {
      var _a;
      return [
        key,
        refresh ? void 0 : (_a = this._cache[key]) == null ? void 0 : _a.value
      ];
    });
    const undefinedAccounts = [];
    cachedValues.forEach(([key, value], cacheIndex) => {
      if (value === void 0) {
        undefinedAccounts.push({ cacheIndex, key });
      }
    });
    if (undefinedAccounts.length > 0) {
      const data = await this.bulkRequest(undefinedAccounts.map((account) => account.key));
      undefinedAccounts.forEach(({ cacheIndex, key }, dataIndex) => {
        var _a;
        const value = entity.parse(data[dataIndex]);
        invariant2(((_a = cachedValues[cacheIndex]) == null ? void 0 : _a[1]) === void 0, "unexpected non-undefined value");
        cachedValues[cacheIndex] = [key, value];
        this._cache[key] = { entity, value };
      });
    }
    const result = cachedValues.map(([_, value]) => value).filter((value) => value !== void 0);
    invariant2(result.length === addresses.length, "not enough results fetched");
    return result;
  }
  async bulkRequest(addresses) {
    const responses = [];
    const chunk = 100;
    for (let i = 0; i < addresses.length; i += chunk) {
      const addressesSubset = addresses.slice(i, i + chunk);
      const res = this.connection._rpcRequest("getMultipleAccounts", [
        addressesSubset,
        { commitment: this.connection.commitment }
      ]);
      responses.push(res);
    }
    const combinedResult = [];
    (await Promise.all(responses)).forEach((res) => {
      var _a;
      invariant2(!res.error, `bulkRequest result error: ${res.error}`);
      invariant2(!!((_a = res.result) == null ? void 0 : _a.value), "bulkRequest no value");
      res.result.value.forEach((account) => {
        if (!account || account.data[1] !== "base64") {
          combinedResult.push(null);
        } else {
          combinedResult.push(Buffer.from(account.data[0], account.data[1]));
        }
      });
    });
    invariant2(combinedResult.length === addresses.length, "bulkRequest not enough results");
    return combinedResult;
  }
};

// src/pool/orca-pool.ts
import { BN as BN3, translateAddress as translateAddress3 } from "@project-serum/anchor";
import { u64 as u646 } from "@solana/spl-token";
import { Keypair as Keypair3, PublicKey as PublicKey7 } from "@solana/web3.js";
import invariant6 from "tiny-invariant";

// src/pool/public/types.ts
function isQuoteByPrice(param) {
  return param.priceLower != null && param.priceUpper != null;
}
function isQuoteByTickIndex(param) {
  return param.tickLowerIndex != null && param.tickUpperIndex != null;
}

// src/pool/quotes/swap-quoter.ts
import {
  MIN_SQRT_PRICE,
  MAX_SQRT_PRICE,
  tickIndexToSqrtPriceX64,
  TICK_ARRAY_SIZE as TICK_ARRAY_SIZE2
} from "@orca-so/whirlpool-client-sdk";
import { BN as BN2 } from "@project-serum/anchor";
import invariant4 from "tiny-invariant";

// src/utils/web3/math-utils.ts
import { BN } from "@project-serum/anchor";
var ZERO = new BN(0);
var ONE = new BN(1);
var TWO = new BN(2);
var U128 = TWO.pow(new BN(128));
var U64_MAX = TWO.pow(new BN(64)).sub(ONE);
function shiftRightRoundUp(n) {
  let result = n.shrn(64);
  if (n.mod(U64_MAX).gt(ZERO)) {
    result = result.add(ONE);
  }
  return result;
}
function divRoundUp(n0, n1) {
  const hasRemainder = !n0.mod(n1).eq(ZERO);
  if (hasRemainder) {
    return n0.div(n1).add(new BN(1));
  } else {
    return n0.div(n1);
  }
}
function subUnderflowU128(n0, n1) {
  return n0.add(U128).sub(n1).mod(U128);
}

// src/utils/whirlpool/pool-util.ts
import { PublicKey as PublicKey6 } from "@solana/web3.js";
var PoolUtil = class {
  constructor() {
  }
  static isRewardInitialized(rewardInfo) {
    return !PublicKey6.default.equals(rewardInfo.mint) && !PublicKey6.default.equals(rewardInfo.vault);
  }
  static getFeeRate(feeRate) {
    return Percentage.fromFraction(feeRate, 1e6);
  }
  static getProtocolFeeRate(protocolFeeRate) {
    return Percentage.fromFraction(protocolFeeRate, 1e4);
  }
  static orderMints(mintX, mintY) {
    let mintA, mintB;
    if (Buffer.compare(toPubKey(mintX).toBuffer(), toPubKey(mintY).toBuffer()) < 0) {
      mintA = mintX;
      mintB = mintY;
    } else {
      mintA = mintY;
      mintB = mintX;
    }
    return [mintA, mintB];
  }
};

// src/utils/whirlpool/tick-util.ts
import invariant3 from "tiny-invariant";
import {
  getTickArrayPda,
  TICK_ARRAY_SIZE,
  MIN_TICK_INDEX,
  MAX_TICK_INDEX,
  sqrtPriceX64ToTickIndex
} from "@orca-so/whirlpool-client-sdk";
var TickUtil = class {
  constructor() {
  }
  static toValid(tickIndex, tickSpacing) {
    return tickIndex - tickIndex % tickSpacing;
  }
  static getTick(tickArray, tickIndex, tickSpacing) {
    const realIndex = TickUtil.tickIndexToTickArrayIndex(tickArray, tickIndex, tickSpacing);
    const tick = tickArray.ticks[realIndex];
    invariant3(!!tick, "tick realIndex out of range");
    return tick;
  }
  static getLowerAndUpperTickArrayAddresses(tickLowerIndex, tickUpperIndex, tickSpacing, whirlpool, programId) {
    return [
      TickUtil.getPdaWithTickIndex(tickLowerIndex, tickSpacing, whirlpool, programId).publicKey,
      TickUtil.getPdaWithTickIndex(tickUpperIndex, tickSpacing, whirlpool, programId).publicKey
    ];
  }
  static getPdaWithTickIndex(tickIndex, tickSpacing, whirlpool, programId, tickArrayOffset = 0) {
    const startIndex = TickUtil.getStartTickIndex(tickIndex, tickSpacing, tickArrayOffset);
    return getTickArrayPda(toPubKey(programId), toPubKey(whirlpool), startIndex);
  }
  static getPDAWithSqrtPrice(sqrtPriceX64, tickSpacing, whirlpool, programId, tickArrayOffset = 0) {
    const tickIndex = sqrtPriceX64ToTickIndex(sqrtPriceX64);
    return TickUtil.getPdaWithTickIndex(tickIndex, tickSpacing, whirlpool, programId, tickArrayOffset);
  }
  static getStartTickIndex(tickIndex, tickSpacing, offset = 0) {
    const realIndex = Math.floor(tickIndex / tickSpacing / TICK_ARRAY_SIZE);
    const startTickIndex = (realIndex + offset) * tickSpacing * TICK_ARRAY_SIZE;
    const ticksInArray = TICK_ARRAY_SIZE * tickSpacing;
    const minTickIndex = MIN_TICK_INDEX - (MIN_TICK_INDEX % ticksInArray + ticksInArray);
    invariant3(startTickIndex >= minTickIndex, "startTickIndex is too small");
    invariant3(startTickIndex <= MAX_TICK_INDEX, "startTickIndex is too large");
    return startTickIndex;
  }
  static getPrevInitializedTickIndex(account, currentTickIndex, tickSpacing) {
    return TickUtil.findInitializedTick(account, currentTickIndex, tickSpacing, 0 /* Left */);
  }
  static getNextInitializedTickIndex(account, currentTickIndex, tickSpacing) {
    return TickUtil.findInitializedTick(account, currentTickIndex, tickSpacing, 1 /* Right */);
  }
  static findInitializedTick(account, currentTickIndex, tickSpacing, searchDirection) {
    var _a;
    const currentTickArrayIndex = TickUtil.tickIndexToTickArrayIndex(account, currentTickIndex, tickSpacing);
    const increment = searchDirection === 1 /* Right */ ? 1 : -1;
    let stepInitializedTickArrayIndex = searchDirection === 1 /* Right */ ? currentTickArrayIndex + increment : currentTickArrayIndex;
    while (stepInitializedTickArrayIndex >= 0 && stepInitializedTickArrayIndex < account.ticks.length) {
      if ((_a = account.ticks[stepInitializedTickArrayIndex]) == null ? void 0 : _a.initialized) {
        return TickUtil.tickArrayIndexToTickIndex(account, stepInitializedTickArrayIndex, tickSpacing);
      }
      stepInitializedTickArrayIndex += increment;
    }
    return null;
  }
  static tickIndexToTickArrayIndex({ startTickIndex }, tickIndex, tickSpacing) {
    return Math.floor((tickIndex - startTickIndex) / tickSpacing);
  }
  static tickArrayIndexToTickIndex({ startTickIndex }, tickArrayIndex, tickSpacing) {
    return startTickIndex + tickArrayIndex * tickSpacing;
  }
  static getNextInitializableTickIndex(tickIndex, tickSpacing, aToB) {
    if (!aToB) {
      return tickIndex + tickSpacing;
    } else {
      return tickIndex;
    }
  }
};

// src/pool/quotes/swap-quoter.ts
var MAX_TICK_ARRAY_CROSSINGS = 2;
var SwapSimulator = class {
  constructor() {
  }
  async simulateSwap(baseInput, input) {
    const { amountSpecified, swapDirection } = baseInput;
    let {
      currentTickIndex,
      currentLiquidity,
      amount: specifiedAmountLeft,
      currentSqrtPriceX64
    } = input;
    invariant4(!specifiedAmountLeft.eq(ZERO), "amount must be nonzero");
    let otherAmountCalculated = ZERO;
    let tickArraysCrossed = 0;
    let sqrtPriceLimitX64;
    while (specifiedAmountLeft.gt(ZERO)) {
      if (tickArraysCrossed > MAX_TICK_ARRAY_CROSSINGS) {
        throw Error("Crossed the maximum number of tick arrays");
      }
      const swapStepSimulationOutput = await this.simulateSwapStep(baseInput, {
        sqrtPriceX64: currentSqrtPriceX64,
        amountRemaining: specifiedAmountLeft,
        tickIndex: currentTickIndex,
        liquidity: currentLiquidity,
        tickArraysCrossed
      });
      const { input: input2, output, nextSqrtPriceX64, nextTickIndex, hasReachedNextTick } = swapStepSimulationOutput;
      const [specifiedAmountUsed, otherAmount] = resolveTokenAmounts(input2, output, amountSpecified);
      specifiedAmountLeft = specifiedAmountLeft.sub(specifiedAmountUsed);
      otherAmountCalculated = otherAmountCalculated.add(otherAmount);
      if (hasReachedNextTick) {
        const nextTick = await fetchTick(baseInput, nextTickIndex);
        currentLiquidity = calculateNewLiquidity(currentLiquidity, nextTick.liquidityNet, swapDirection);
        currentTickIndex = swapDirection == "Swap A to B" /* AtoB */ ? nextTickIndex - 1 : nextTickIndex;
      }
      currentSqrtPriceX64 = nextSqrtPriceX64;
      tickArraysCrossed = swapStepSimulationOutput.tickArraysCrossed;
      if (tickArraysCrossed > MAX_TICK_ARRAY_CROSSINGS) {
        sqrtPriceLimitX64 = tickIndexToSqrtPriceX64(nextTickIndex);
      }
    }
    const [inputAmount, outputAmount] = resolveTokenAmounts(input.amount.sub(specifiedAmountLeft), otherAmountCalculated, amountSpecified);
    if (!sqrtPriceLimitX64) {
      if (swapDirection === "Swap A to B" /* AtoB */) {
        sqrtPriceLimitX64 = new BN2(MIN_SQRT_PRICE);
      } else {
        sqrtPriceLimitX64 = new BN2(MAX_SQRT_PRICE);
      }
    }
    return {
      amountIn: inputAmount,
      amountOut: outputAmount,
      sqrtPriceLimitX64
    };
  }
  async simulateSwapStep(baseInput, input) {
    const { whirlpoolData, amountSpecified, swapDirection } = baseInput;
    const { feeRate } = whirlpoolData;
    const feeRatePercentage = PoolUtil.getFeeRate(feeRate);
    const { amountRemaining, liquidity, sqrtPriceX64, tickIndex, tickArraysCrossed } = input;
    const { tickIndex: nextTickIndex, tickArraysCrossed: tickArraysCrossedUpdate } = await getNextInitializedTickIndex(baseInput, tickIndex, tickArraysCrossed);
    const targetSqrtPriceX64 = tickIndexToSqrtPriceX64(nextTickIndex);
    let fixedDelta = getAmountFixedDelta(sqrtPriceX64, targetSqrtPriceX64, liquidity, amountSpecified, swapDirection);
    let amountCalculated = amountRemaining;
    if (amountSpecified == "Specified input amount" /* Input */) {
      amountCalculated = calculateAmountAfterFees(amountRemaining, feeRatePercentage);
    }
    const nextSqrtPriceX64 = amountCalculated.gte(fixedDelta) ? targetSqrtPriceX64 : getNextSqrtPrice(sqrtPriceX64, liquidity, amountCalculated, amountSpecified, swapDirection);
    const hasReachedNextTick = nextSqrtPriceX64.eq(targetSqrtPriceX64);
    const unfixedDelta = getAmountUnfixedDelta(sqrtPriceX64, nextSqrtPriceX64, liquidity, amountSpecified, swapDirection);
    if (!hasReachedNextTick) {
      fixedDelta = getAmountFixedDelta(sqrtPriceX64, nextSqrtPriceX64, liquidity, amountSpecified, swapDirection);
    }
    let [inputDelta, outputDelta] = resolveTokenAmounts(fixedDelta, unfixedDelta, amountSpecified);
    if (amountSpecified == "Specified output amount" /* Output */ && outputDelta.gt(amountRemaining)) {
      outputDelta = amountRemaining;
    }
    if (amountSpecified == "Specified input amount" /* Input */ && !hasReachedNextTick) {
      inputDelta = amountRemaining;
    } else {
      inputDelta = inputDelta.add(calculateFeesFromAmount(inputDelta, feeRatePercentage));
    }
    return {
      nextTickIndex,
      nextSqrtPriceX64,
      input: inputDelta,
      output: outputDelta,
      tickArraysCrossed: tickArraysCrossedUpdate,
      hasReachedNextTick
    };
  }
};
function calculateAmountAfterFees(amount, feeRate) {
  return amount.mul(feeRate.denominator.sub(feeRate.numerator)).div(feeRate.denominator);
}
function calculateFeesFromAmount(amount, feeRate) {
  return divRoundUp(amount.mul(feeRate.numerator), feeRate.denominator.sub(feeRate.numerator));
}
function calculateNewLiquidity(liquidity, nextLiquidityNet, swapDirection) {
  if (swapDirection == "Swap A to B" /* AtoB */) {
    nextLiquidityNet = nextLiquidityNet.neg();
  }
  return liquidity.add(nextLiquidityNet);
}
function resolveTokenAmounts(specifiedTokenAmount, otherTokenAmount, amountSpecified) {
  if (amountSpecified == "Specified input amount" /* Input */) {
    return [specifiedTokenAmount, otherTokenAmount];
  } else {
    return [otherTokenAmount, specifiedTokenAmount];
  }
}
async function fetchTickArray(baseInput, tickIndex) {
  const {
    dal,
    poolAddress,
    refresh,
    whirlpoolData: { tickSpacing }
  } = baseInput;
  const tickArray = await dal.getTickArray(TickUtil.getPdaWithTickIndex(tickIndex, tickSpacing, poolAddress, dal.programId).publicKey, refresh);
  invariant4(!!tickArray, "tickArray is null");
  return tickArray;
}
async function fetchTick(baseInput, tickIndex) {
  const tickArray = await fetchTickArray(baseInput, tickIndex);
  const {
    whirlpoolData: { tickSpacing }
  } = baseInput;
  return TickUtil.getTick(tickArray, tickIndex, tickSpacing);
}
async function getNextInitializedTickIndex(baseInput, currentTickIndex, tickArraysCrossed) {
  const {
    whirlpoolData: { tickSpacing },
    swapDirection
  } = baseInput;
  let nextInitializedTickIndex = void 0;
  while (nextInitializedTickIndex === void 0) {
    const currentTickArray = await fetchTickArray(baseInput, currentTickIndex);
    let temp;
    if (swapDirection == "Swap A to B" /* AtoB */) {
      temp = TickUtil.getPrevInitializedTickIndex(currentTickArray, currentTickIndex, tickSpacing);
    } else {
      temp = TickUtil.getNextInitializedTickIndex(currentTickArray, currentTickIndex, tickSpacing);
    }
    if (temp) {
      nextInitializedTickIndex = temp;
    } else if (tickArraysCrossed === MAX_TICK_ARRAY_CROSSINGS) {
      if (swapDirection === "Swap A to B" /* AtoB */) {
        nextInitializedTickIndex = currentTickArray.startTickIndex;
      } else {
        nextInitializedTickIndex = currentTickArray.startTickIndex + TICK_ARRAY_SIZE2 * tickSpacing;
      }
      tickArraysCrossed++;
    } else {
      if (swapDirection === "Swap A to B" /* AtoB */) {
        currentTickIndex = currentTickArray.startTickIndex - 1;
      } else {
        currentTickIndex = currentTickArray.startTickIndex + TICK_ARRAY_SIZE2 * tickSpacing;
      }
      tickArraysCrossed++;
    }
  }
  return {
    tickIndex: nextInitializedTickIndex,
    tickArraysCrossed
  };
}

// src/utils/whirlpool/swap-util.ts
function getLowerSqrtPriceFromTokenA(amount, liquidity, sqrtPriceX64) {
  const numerator = liquidity.mul(sqrtPriceX64).shln(64);
  const denominator = liquidity.shln(64).add(amount.mul(sqrtPriceX64));
  return divRoundUp(numerator, denominator);
}
function getUpperSqrtPriceFromTokenA(amount, liquidity, sqrtPriceX64) {
  const numerator = liquidity.mul(sqrtPriceX64).shln(64);
  const denominator = liquidity.shln(64).sub(amount.mul(sqrtPriceX64));
  return divRoundUp(numerator, denominator);
}
function getLowerSqrtPriceFromTokenB(amount, liquidity, sqrtPriceX64) {
  return sqrtPriceX64.sub(divRoundUp(amount.shln(64), liquidity));
}
function getUpperSqrtPriceFromTokenB(amount, liquidity, sqrtPriceX64) {
  return sqrtPriceX64.add(amount.shln(64).div(liquidity));
}

// src/utils/public/position-util.ts
var PositionStatus = /* @__PURE__ */ ((PositionStatus2) => {
  PositionStatus2[PositionStatus2["BelowRange"] = 0] = "BelowRange";
  PositionStatus2[PositionStatus2["InRange"] = 1] = "InRange";
  PositionStatus2[PositionStatus2["AboveRange"] = 2] = "AboveRange";
  return PositionStatus2;
})(PositionStatus || {});
var PositionUtil = class {
  constructor() {
  }
  static getPositionStatus(tickCurrentIndex, tickLowerIndex, tickUpperIndex) {
    if (tickCurrentIndex < tickLowerIndex) {
      return 0 /* BelowRange */;
    } else if (tickCurrentIndex < tickUpperIndex) {
      return 1 /* InRange */;
    } else {
      return 2 /* AboveRange */;
    }
  }
};
function adjustForSlippage(n, { numerator, denominator }, adjustUp) {
  if (adjustUp) {
    return n.mul(denominator.add(numerator)).div(denominator);
  } else {
    return n.mul(denominator).div(denominator.add(numerator));
  }
}
function adjustAmountForSlippage(amountIn, amountOut, { numerator, denominator }, amountSpecified) {
  if (amountSpecified === "Specified input amount" /* Input */) {
    return amountOut.mul(denominator).div(denominator.add(numerator));
  } else {
    return amountIn.mul(denominator.add(numerator)).div(denominator);
  }
}
function getLiquidityFromTokenA(amount, sqrtPriceLowerX64, sqrtPriceUpperX64, roundUp) {
  const result = amount.mul(sqrtPriceLowerX64).mul(sqrtPriceUpperX64).div(sqrtPriceUpperX64.sub(sqrtPriceLowerX64));
  if (roundUp) {
    return shiftRightRoundUp(result);
  } else {
    return result.shrn(64);
  }
}
function getLiquidityFromTokenB(amount, sqrtPriceLowerX64, sqrtPriceUpperX64, roundUp) {
  const numerator = amount.shln(64);
  const denominator = sqrtPriceUpperX64.sub(sqrtPriceLowerX64);
  if (roundUp) {
    return divRoundUp(numerator, denominator);
  } else {
    return numerator.div(denominator);
  }
}
function orderSqrtPrice(sqrtPrice0X64, sqrtPrice1X64) {
  if (sqrtPrice0X64.lt(sqrtPrice1X64)) {
    return [sqrtPrice0X64, sqrtPrice1X64];
  } else {
    return [sqrtPrice1X64, sqrtPrice0X64];
  }
}
function getTokenAFromLiquidity(liquidity, sqrtPrice0X64, sqrtPrice1X64, roundUp) {
  const [sqrtPriceLowerX64, sqrtPriceUpperX64] = orderSqrtPrice(sqrtPrice0X64, sqrtPrice1X64);
  const numerator = liquidity.mul(sqrtPriceUpperX64.sub(sqrtPriceLowerX64)).shln(64);
  const denominator = sqrtPriceUpperX64.mul(sqrtPriceLowerX64);
  if (roundUp) {
    return divRoundUp(numerator, denominator);
  } else {
    return numerator.div(denominator);
  }
}
function getTokenBFromLiquidity(liquidity, sqrtPrice0X64, sqrtPrice1X64, roundUp) {
  const [sqrtPriceLowerX64, sqrtPriceUpperX64] = orderSqrtPrice(sqrtPrice0X64, sqrtPrice1X64);
  const result = liquidity.mul(sqrtPriceUpperX64.sub(sqrtPriceLowerX64));
  if (roundUp) {
    return shiftRightRoundUp(result);
  } else {
    return result.shrn(64);
  }
}
function getAmountFixedDelta(currentSqrtPriceX64, targetSqrtPriceX64, liquidity, amountSpecified, swapDirection) {
  if (amountSpecified == "Specified input amount" /* Input */ == (swapDirection == "Swap A to B" /* AtoB */)) {
    return getTokenAFromLiquidity(liquidity, currentSqrtPriceX64, targetSqrtPriceX64, amountSpecified == "Specified input amount" /* Input */);
  } else {
    return getTokenBFromLiquidity(liquidity, currentSqrtPriceX64, targetSqrtPriceX64, amountSpecified == "Specified input amount" /* Input */);
  }
}
function getAmountUnfixedDelta(currentSqrtPriceX64, targetSqrtPriceX64, liquidity, amountSpecified, swapDirection) {
  if (amountSpecified == "Specified input amount" /* Input */ == (swapDirection == "Swap A to B" /* AtoB */)) {
    return getTokenBFromLiquidity(liquidity, currentSqrtPriceX64, targetSqrtPriceX64, amountSpecified == "Specified output amount" /* Output */);
  } else {
    return getTokenAFromLiquidity(liquidity, currentSqrtPriceX64, targetSqrtPriceX64, amountSpecified == "Specified output amount" /* Output */);
  }
}
function getNextSqrtPrice(sqrtPriceX64, liquidity, amount, amountSpecified, swapDirection) {
  if (amountSpecified === "Specified input amount" /* Input */ && swapDirection === "Swap A to B" /* AtoB */) {
    return getLowerSqrtPriceFromTokenA(amount, liquidity, sqrtPriceX64);
  } else if (amountSpecified === "Specified output amount" /* Output */ && swapDirection === "Swap B to A" /* BtoA */) {
    return getUpperSqrtPriceFromTokenA(amount, liquidity, sqrtPriceX64);
  } else if (amountSpecified === "Specified input amount" /* Input */ && swapDirection === "Swap B to A" /* BtoA */) {
    return getUpperSqrtPriceFromTokenB(amount, liquidity, sqrtPriceX64);
  } else {
    return getLowerSqrtPriceFromTokenB(amount, liquidity, sqrtPriceX64);
  }
}

// src/position/quotes/add-liquidity.ts
import { tickIndexToSqrtPriceX64 as tickIndexToSqrtPriceX642 } from "@orca-so/whirlpool-client-sdk";
function getAddLiquidityQuote(param) {
  const positionStatus = PositionUtil.getPositionStatus(param.tickCurrentIndex, param.tickLowerIndex, param.tickUpperIndex);
  switch (positionStatus) {
    case 0 /* BelowRange */:
      return getAddLiquidityQuoteWhenPositionIsBelowRange(param);
    case 1 /* InRange */:
      return getAddLiquidityQuoteWhenPositionIsInRange(param);
    case 2 /* AboveRange */:
      return getAddLiquidityQuoteWhenPositionIsAboveRange(param);
    default:
      throw new Error(`type ${positionStatus} is an unknown PositionStatus`);
  }
}
function getAddLiquidityQuoteWhenPositionIsBelowRange(param) {
  const {
    tokenMintA,
    inputTokenMint,
    inputTokenAmount,
    tickLowerIndex,
    tickUpperIndex,
    slippageTolerance
  } = param;
  if (!tokenMintA.equals(inputTokenMint)) {
    return {
      maxTokenA: ZERO,
      maxTokenB: ZERO,
      liquidity: ZERO
    };
  }
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX642(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX642(tickUpperIndex);
  const liquidity = getLiquidityFromTokenA(inputTokenAmount, sqrtPriceLowerX64, sqrtPriceUpperX64, false);
  const maxTokenA = adjustForSlippage(getTokenAFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceUpperX64, true), slippageTolerance, true);
  const maxTokenB = ZERO;
  return {
    maxTokenA,
    maxTokenB,
    liquidity
  };
}
function getAddLiquidityQuoteWhenPositionIsInRange(param) {
  const {
    tokenMintA,
    sqrtPrice,
    inputTokenMint,
    inputTokenAmount,
    tickLowerIndex,
    tickUpperIndex,
    slippageTolerance
  } = param;
  const sqrtPriceX64 = sqrtPrice;
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX642(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX642(tickUpperIndex);
  let [tokenAmountA, tokenAmountB] = tokenMintA.equals(inputTokenMint) ? [inputTokenAmount, void 0] : [void 0, inputTokenAmount];
  let liquidity;
  if (tokenAmountA) {
    liquidity = getLiquidityFromTokenA(tokenAmountA, sqrtPriceX64, sqrtPriceUpperX64, false);
    tokenAmountA = getTokenAFromLiquidity(liquidity, sqrtPriceX64, sqrtPriceUpperX64, true);
    tokenAmountB = getTokenBFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceX64, true);
  } else if (tokenAmountB) {
    liquidity = getLiquidityFromTokenB(tokenAmountB, sqrtPriceLowerX64, sqrtPriceX64, false);
    tokenAmountA = getTokenAFromLiquidity(liquidity, sqrtPriceX64, sqrtPriceUpperX64, true);
    tokenAmountB = getTokenBFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceX64, true);
  } else {
    throw new Error("invariant violation");
  }
  const maxTokenA = adjustForSlippage(tokenAmountA, slippageTolerance, true);
  const maxTokenB = adjustForSlippage(tokenAmountB, slippageTolerance, true);
  return {
    maxTokenA,
    maxTokenB,
    liquidity
  };
}
function getAddLiquidityQuoteWhenPositionIsAboveRange(param) {
  const {
    tokenMintB,
    inputTokenMint,
    inputTokenAmount,
    tickLowerIndex,
    tickUpperIndex,
    slippageTolerance
  } = param;
  if (!tokenMintB.equals(inputTokenMint)) {
    return {
      maxTokenA: ZERO,
      maxTokenB: ZERO,
      liquidity: ZERO
    };
  }
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX642(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX642(tickUpperIndex);
  const liquidity = getLiquidityFromTokenB(inputTokenAmount, sqrtPriceLowerX64, sqrtPriceUpperX64, false);
  const maxTokenA = ZERO;
  const maxTokenB = adjustForSlippage(getTokenBFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceUpperX64, true), slippageTolerance, true);
  return {
    maxTokenA,
    maxTokenB,
    liquidity
  };
}

// src/position/quotes/remove-liquidity.ts
import { tickIndexToSqrtPriceX64 as tickIndexToSqrtPriceX643 } from "@orca-so/whirlpool-client-sdk";
function getRemoveLiquidityQuote(param) {
  const positionStatus = PositionUtil.getPositionStatus(param.tickCurrentIndex, param.tickLowerIndex, param.tickUpperIndex);
  switch (positionStatus) {
    case 0 /* BelowRange */:
      return getRemoveLiquidityQuoteWhenPositionIsBelowRange(param);
    case 1 /* InRange */:
      return getRemoveLiquidityQuoteWhenPositionIsInRange(param);
    case 2 /* AboveRange */:
      return getRemoveLiquidityQuoteWhenPositionIsAboveRange(param);
    default:
      throw new Error(`type ${positionStatus} is an unknown PositionStatus`);
  }
}
function getRemoveLiquidityQuoteWhenPositionIsBelowRange(param) {
  const { positionAddress, tickLowerIndex, tickUpperIndex, liquidity, slippageTolerance } = param;
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX643(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX643(tickUpperIndex);
  const minTokenA = adjustForSlippage(getTokenAFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceUpperX64, false), slippageTolerance, false);
  return {
    positionAddress,
    minTokenA,
    minTokenB: ZERO,
    liquidity
  };
}
function getRemoveLiquidityQuoteWhenPositionIsInRange(param) {
  const {
    positionAddress,
    sqrtPrice,
    tickLowerIndex,
    tickUpperIndex,
    liquidity,
    slippageTolerance
  } = param;
  const sqrtPriceX64 = sqrtPrice;
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX643(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX643(tickUpperIndex);
  const minTokenA = adjustForSlippage(getTokenAFromLiquidity(liquidity, sqrtPriceX64, sqrtPriceUpperX64, false), slippageTolerance, false);
  const minTokenB = adjustForSlippage(getTokenBFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceX64, false), slippageTolerance, false);
  return {
    positionAddress,
    minTokenA,
    minTokenB,
    liquidity
  };
}
function getRemoveLiquidityQuoteWhenPositionIsAboveRange(param) {
  const {
    positionAddress,
    tickLowerIndex,
    tickUpperIndex,
    liquidity,
    slippageTolerance
  } = param;
  const sqrtPriceLowerX64 = tickIndexToSqrtPriceX643(tickLowerIndex);
  const sqrtPriceUpperX64 = tickIndexToSqrtPriceX643(tickUpperIndex);
  const minTokenB = adjustForSlippage(getTokenBFromLiquidity(liquidity, sqrtPriceLowerX64, sqrtPriceUpperX64, false), slippageTolerance, false);
  return {
    positionAddress,
    minTokenA: ZERO,
    minTokenB,
    liquidity
  };
}

// src/utils/public/multi-transaction-builder.ts
var MultiTransactionBuilder = class {
  constructor(provider, txBuilders) {
    this.provider = provider;
    this.txBuilders = txBuilders;
  }
  async buildAndExecute() {
    const txRequest = await Promise.all(this.txBuilders.map(async (txBuilder) => {
      const { transaction, signers } = await txBuilder.build();
      return { tx: transaction, signers };
    }));
    return this.provider.sendAll(txRequest, { commitment: "singleGossip" });
  }
  addTxBuilder(txBuilder) {
    this.txBuilders.push(txBuilder);
    return this;
  }
  merge(multiTxBuilder) {
    return new MultiTransactionBuilder(this.provider, [
      ...this.txBuilders,
      ...multiTxBuilder.txBuilders
    ]);
  }
  static mergeAll(multiTxBuilders) {
    var _a;
    const provider = (_a = multiTxBuilders[0]) == null ? void 0 : _a.provider;
    if (!provider) {
      return null;
    }
    const combinedTxBuilders = [];
    multiTxBuilders.forEach(({ txBuilders }) => combinedTxBuilders.push(...txBuilders));
    return new MultiTransactionBuilder(provider, combinedTxBuilders);
  }
};

// src/pool/ux/liquidity-distribution.ts
import { getTickArrayPda as getTickArrayPda2 } from "@orca-so/whirlpool-client-sdk";
import { translateAddress as translateAddress2 } from "@project-serum/anchor";
import Decimal2 from "decimal.js";
async function getLiquidityDistribution(dal, poolAddress, tickLower, tickUpper, refresh) {
  var _a, _b;
  const datapoints = [];
  const pool = await dal.getPool(poolAddress, refresh);
  if (!pool) {
    throw new Error(`Whirlpool not found: ${translateAddress2(poolAddress).toBase58()}`);
  }
  const tokenDecimalsA = (_a = await dal.getMintInfo(pool.tokenMintA, false)) == null ? void 0 : _a.decimals;
  if (!tokenDecimalsA) {
    throw new Error(`Token mint not found: ${pool.tokenMintA.toBase58()}`);
  }
  const tokenDecimalsB = (_b = await dal.getMintInfo(pool.tokenMintB, false)) == null ? void 0 : _b.decimals;
  if (!tokenDecimalsB) {
    throw new Error(`Token mint not found: ${pool.tokenMintB.toBase58()}`);
  }
  const tickArrayAddresses = getSurroundingTickArrayAddresses(pool, poolAddress, tickLower, tickUpper, dal.programId);
  const tickArrays = await dal.listTickArrays(tickArrayAddresses, refresh);
  const currentLiquidity = new Decimal2(pool.liquidity.toString());
  let relativeLiquidity = currentLiquidity;
  let minLiquidity = new Decimal2(0);
  let liquidity = new Decimal2(0);
  tickArrays.forEach((tickArray) => {
    if (!tickArray) {
      return;
    }
    const startIndex = tickArray.startTickIndex;
    tickArray.ticks.forEach((tick, index) => {
      const tickIndex = startIndex + index * pool.tickSpacing;
      const price = tickIndexToPrice(tickIndex, tokenDecimalsA, tokenDecimalsB);
      const liquidityNet = new Decimal2(tick.liquidityNet.toString());
      liquidity = liquidity.add(liquidityNet);
      datapoints.push({ liquidity: new Decimal2(liquidity), price, tickIndex });
      minLiquidity = liquidity.lt(minLiquidity) ? liquidity : minLiquidity;
      if (tickIndex === pool.tickCurrentIndex) {
        relativeLiquidity = relativeLiquidity.sub(liquidityNet);
      }
    });
  });
  if (!relativeLiquidity.eq(currentLiquidity)) {
    minLiquidity = minLiquidity.add(relativeLiquidity);
    datapoints.forEach((datapoint) => {
      datapoint.liquidity = datapoint.liquidity.add(relativeLiquidity);
    });
  }
  if (minLiquidity.lt(0)) {
    datapoints.forEach((datapoint) => {
      datapoint.liquidity = datapoint.liquidity.add(minLiquidity.neg());
    });
  }
  return {
    currentPrice: sqrtPriceX64ToPrice(pool.sqrtPrice, tokenDecimalsA, tokenDecimalsB),
    currentTickIndex: pool.tickCurrentIndex,
    datapoints
  };
}
function getSurroundingTickArrayAddresses(pool, poolAddress, tickLower, tickUpper, programId) {
  const tickArrayAddresses = [];
  let startIndex = TickUtil.getStartTickIndex(tickLower, pool.tickSpacing);
  while (startIndex <= tickUpper) {
    const address = getTickArrayPda2(toPubKey(programId), toPubKey(poolAddress), startIndex).publicKey;
    tickArrayAddresses.push(address);
    try {
      startIndex = TickUtil.getStartTickIndex(startIndex, pool.tickSpacing, 1);
    } catch (_e) {
      return tickArrayAddresses;
    }
  }
  return tickArrayAddresses;
}

// src/pool/orca-pool.ts
import {
  getWhirlpoolPda as getWhirlpoolPda2,
  getTickArrayPda as getTickArrayPda3,
  getPositionPda,
  TransactionBuilder as TransactionBuilder3,
  sqrtPriceX64ToTickIndex as sqrtPriceX64ToTickIndex2,
  toX64,
  WhirlpoolClient as WhirlpoolClient3,
  WhirlpoolContext as WhirlpoolContext3,
  MIN_SQRT_PRICE as MIN_SQRT_PRICE2,
  MAX_SQRT_PRICE as MAX_SQRT_PRICE2,
  MIN_TICK_INDEX as MIN_TICK_INDEX2,
  MAX_TICK_INDEX as MAX_TICK_INDEX3,
  getOraclePda,
  getPositionMetadataPda
} from "@orca-so/whirlpool-client-sdk";

// src/position/txs/fees-and-rewards.ts
import {
  EMPTY_INSTRUCTION,
  NUM_REWARDS as NUM_REWARDS2,
  TransactionBuilder as TransactionBuilder2,
  WhirlpoolClient as WhirlpoolClient2,
  WhirlpoolContext as WhirlpoolContext2
} from "@orca-so/whirlpool-client-sdk";
import invariant5 from "tiny-invariant";
import { NATIVE_MINT as NATIVE_MINT3 } from "@solana/spl-token";
async function buildMultipleCollectFeesAndRewardsTx(dal, param) {
  const { provider, positionAddresses, resolvedAssociatedTokenAddresses } = param;
  const ctx = WhirlpoolContext2.withProvider(provider, dal.programId);
  const client = new WhirlpoolClient2(ctx);
  const collectPositionTransactions = [];
  for (const positionAddress of positionAddresses) {
    const txn = await buildSingleCollectFeeAndRewardsTx(positionAddress, dal, client, provider, resolvedAssociatedTokenAddresses);
    if (!txn.isEmpty()) {
      collectPositionTransactions.push(txn);
    }
  }
  const collectAllTransactionBuilder = new MultiTransactionBuilder(provider, []);
  collectPositionTransactions.forEach((collectTxn) => collectAllTransactionBuilder.addTxBuilder(collectTxn));
  return collectAllTransactionBuilder;
}
async function buildCollectFeesAndRewardsTx(dal, param) {
  const { provider, positionAddress, resolvedAssociatedTokenAddresses } = param;
  const ctx = WhirlpoolContext2.withProvider(provider, dal.programId);
  const client = new WhirlpoolClient2(ctx);
  return await buildSingleCollectFeeAndRewardsTx(positionAddress, dal, client, provider, resolvedAssociatedTokenAddresses);
}
async function buildSingleCollectFeeAndRewardsTx(positionAddress, dal, client, provider, ataMap) {
  const txn = new TransactionBuilder2(provider);
  const positionInfo = await derivePositionInfo(positionAddress, dal, provider.wallet.publicKey);
  if (positionInfo == null) {
    return txn;
  }
  const {
    position,
    whirlpool,
    tickArrayLower,
    tickArrayUpper,
    positionTokenAccount,
    nothingToCollect
  } = positionInfo;
  if (nothingToCollect) {
    return txn;
  }
  if (!ataMap) {
    ataMap = {};
  }
  const {
    tokenOwnerAccount: tokenOwnerAccountA,
    createTokenOwnerAccountIx: createTokenOwnerAccountAIx
  } = await getTokenAtaAndPopulateATAMap(provider, whirlpool.tokenMintA, ataMap);
  const {
    tokenOwnerAccount: tokenOwnerAccountB,
    createTokenOwnerAccountIx: createTokenOwnerAccountBIx
  } = await getTokenAtaAndPopulateATAMap(provider, whirlpool.tokenMintB, ataMap);
  txn.addInstruction(createTokenOwnerAccountAIx).addInstruction(createTokenOwnerAccountBIx);
  if (!position.liquidity.isZero()) {
    txn.addInstruction(client.updateFeesAndRewards({
      whirlpool: position.whirlpool,
      position: toPubKey(positionAddress),
      tickArrayLower,
      tickArrayUpper
    }).compressIx(false));
  }
  txn.addInstruction(client.collectFeesTx({
    whirlpool: position.whirlpool,
    positionAuthority: provider.wallet.publicKey,
    position: toPubKey(positionAddress),
    positionTokenAccount,
    tokenOwnerAccountA,
    tokenOwnerAccountB,
    tokenVaultA: whirlpool.tokenVaultA,
    tokenVaultB: whirlpool.tokenVaultB
  }).compressIx(false));
  for (const i of [...Array(NUM_REWARDS2).keys()]) {
    const rewardInfo = whirlpool.rewardInfos[i];
    invariant5(!!rewardInfo, "rewardInfo cannot be undefined");
    if (!PoolUtil.isRewardInitialized(rewardInfo)) {
      continue;
    }
    const {
      tokenOwnerAccount: rewardOwnerAccount,
      createTokenOwnerAccountIx: createRewardTokenOwnerAccountIx
    } = await getTokenAtaAndPopulateATAMap(provider, rewardInfo.mint, ataMap);
    if (createRewardTokenOwnerAccountIx) {
      txn.addInstruction(createRewardTokenOwnerAccountIx);
    }
    txn.addInstruction(client.collectRewardTx({
      whirlpool: position.whirlpool,
      positionAuthority: provider.wallet.publicKey,
      position: toPubKey(positionAddress),
      positionTokenAccount,
      rewardOwnerAccount,
      rewardVault: rewardInfo.vault,
      rewardIndex: i
    }).compressIx(false));
  }
  return txn;
}
async function getTokenAtaAndPopulateATAMap(provider, tokenMint, ataMap) {
  let _tokenMintA = tokenMint.toBase58();
  let tokenOwnerAccount;
  let createTokenOwnerAccountIx = EMPTY_INSTRUCTION;
  const mappedTokenAAddress = ataMap[_tokenMintA];
  if (!mappedTokenAAddress) {
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, tokenMint), { address: _tokenOwnerAccount } = _a, _tokenOwnerAccountAIx = __objRest(_a, ["address"]);
    tokenOwnerAccount = _tokenOwnerAccount;
    createTokenOwnerAccountIx = _tokenOwnerAccountAIx;
    if (!tokenMint.equals(NATIVE_MINT3)) {
      ataMap[_tokenMintA] = _tokenOwnerAccount;
    }
  } else {
    tokenOwnerAccount = mappedTokenAAddress;
  }
  return { tokenOwnerAccount, createTokenOwnerAccountIx };
}
async function derivePositionInfo(positionAddress, dal, walletKey) {
  const position = await dal.getPosition(positionAddress, false);
  if (!position) {
    return null;
  }
  const whirlpool = await dal.getPool(position.whirlpool, false);
  if (!whirlpool) {
    return null;
  }
  const [tickArrayLower, tickArrayUpper] = TickUtil.getLowerAndUpperTickArrayAddresses(position.tickLowerIndex, position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, dal.programId);
  const positionTokenAccount = await deriveATA(walletKey, position.positionMint);
  const nothingToCollect = position.liquidity.isZero() && !hasOwedFees(position) && !hasOwedRewards(position);
  return {
    position,
    whirlpool,
    tickArrayLower,
    tickArrayUpper,
    positionTokenAccount,
    nothingToCollect
  };
}
function hasOwedFees(position) {
  return !(position.feeOwedA.isZero() && position.feeOwedB.isZero());
}
function hasOwedRewards(position) {
  return position.rewardInfos.some((rewardInfo) => !rewardInfo.amountOwed.isZero());
}

// src/pool/orca-pool.ts
var OrcaPool = class {
  constructor(dal) {
    this.dal = dal;
  }
  async getLiquidityDistribution(poolAddress, tickLower, tickUpper, refresh = true) {
    return getLiquidityDistribution(this.dal, poolAddress, tickLower, tickUpper, refresh);
  }
  derivePDA(tokenMintA, tokenMintB, tickSpacing) {
    const [_tokenMintA, _tokenMintB] = PoolUtil.orderMints(tokenMintA, tokenMintB);
    return getWhirlpoolPda2(this.dal.programId, this.dal.whirlpoolsConfig, toPubKey(_tokenMintA), toPubKey(_tokenMintB), tickSpacing);
  }
  async getOpenPositionTx(param) {
    return this.getOpenPositionWithOptMetadataTx(param);
  }
  async getOpenPositionWithMetadataTx(param) {
    return this.getOpenPositionWithOptMetadataTx(param, true);
  }
  async getOpenPositionWithOptMetadataTx(param, withMetadata = false) {
    const {
      provider,
      quote: { maxTokenA, maxTokenB, liquidity, tickLowerIndex, tickUpperIndex, poolAddress }
    } = param;
    invariant6(liquidity.gt(new u646(0)), "liquidity must be greater than zero");
    const ctx = WhirlpoolContext3.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient3(ctx);
    const whirlpool = await this.dal.getPool(poolAddress, false);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(poolAddress).toBase58()}`);
    }
    const positionMintKeypair = Keypair3.generate();
    const positionPda = getPositionPda(this.dal.programId, positionMintKeypair.publicKey);
    const metadataPda = getPositionMetadataPda(positionMintKeypair.publicKey);
    const positionTokenAccountAddress = await deriveATA(provider.wallet.publicKey, positionMintKeypair.publicKey);
    const txBuilder = new TransactionBuilder3(provider);
    const preTxBuilder = new TransactionBuilder3(provider);
    const positionIx = (withMetadata ? client.openPositionWithMetadataTx : client.openPositionTx).bind(client)({
      funder: provider.wallet.publicKey,
      ownerKey: provider.wallet.publicKey,
      positionPda,
      metadataPda,
      positionMintAddress: positionMintKeypair.publicKey,
      positionTokenAccountAddress,
      whirlpoolKey: toPubKey(poolAddress),
      tickLowerIndex,
      tickUpperIndex
    }).compressIx(false);
    txBuilder.addInstruction(positionIx).addSigner(positionMintKeypair);
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA, maxTokenA), { address: tokenOwnerAccountA } = _a, tokenOwnerAccountAIx = __objRest(_a, ["address"]);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB, maxTokenB), { address: tokenOwnerAccountB } = _b, tokenOwnerAccountBIx = __objRest(_b, ["address"]);
    txBuilder.addInstruction(tokenOwnerAccountAIx);
    txBuilder.addInstruction(tokenOwnerAccountBIx);
    const tickArrayLowerPda = TickUtil.getPdaWithTickIndex(tickLowerIndex, whirlpool.tickSpacing, poolAddress, this.dal.programId);
    const tickArrayUpperPda = TickUtil.getPdaWithTickIndex(tickUpperIndex, whirlpool.tickSpacing, poolAddress, this.dal.programId);
    const [tickArrayLower, tickArrayUpper] = await this.dal.listTickArrays([tickArrayLowerPda.publicKey, tickArrayUpperPda.publicKey], true);
    let requirePreTx = false;
    if (tickArrayLower === null) {
      const tickArrayIx = client.initTickArrayTx({
        whirlpool: toPubKey(poolAddress),
        tickArrayPda: tickArrayLowerPda,
        startTick: TickUtil.getStartTickIndex(tickLowerIndex, whirlpool.tickSpacing),
        funder: provider.wallet.publicKey
      }).compressIx(false);
      preTxBuilder.addInstruction(tickArrayIx);
      requirePreTx = true;
    }
    if (tickArrayUpper === null && !tickArrayLowerPda.publicKey.equals(tickArrayUpperPda.publicKey)) {
      const tickArrayIx = client.initTickArrayTx({
        whirlpool: toPubKey(poolAddress),
        tickArrayPda: tickArrayUpperPda,
        startTick: TickUtil.getStartTickIndex(tickUpperIndex, whirlpool.tickSpacing),
        funder: provider.wallet.publicKey
      }).compressIx(false);
      preTxBuilder.addInstruction(tickArrayIx);
      requirePreTx = true;
    }
    const liquidityIx = client.increaseLiquidityTx({
      liquidityAmount: liquidity,
      tokenMaxA: maxTokenA,
      tokenMaxB: maxTokenB,
      whirlpool: toPubKey(poolAddress),
      positionAuthority: provider.wallet.publicKey,
      position: positionPda.publicKey,
      positionTokenAccount: positionTokenAccountAddress,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA: whirlpool.tokenVaultA,
      tokenVaultB: whirlpool.tokenVaultB,
      tickArrayLower: tickArrayLowerPda.publicKey,
      tickArrayUpper: tickArrayUpperPda.publicKey
    }).compressIx(false);
    txBuilder.addInstruction(liquidityIx);
    const tx = new MultiTransactionBuilder(provider, []);
    if (requirePreTx) {
      tx.addTxBuilder(preTxBuilder);
    }
    tx.addTxBuilder(txBuilder);
    return {
      mint: positionMintKeypair.publicKey,
      tx
    };
  }
  async getInitTickArrayTx(provider, param) {
    const ctx = WhirlpoolContext3.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient3(ctx);
    return client.initTickArrayTx(param);
  }
  async getClosePositionTx(param) {
    const { provider, quote } = param;
    const ctx = WhirlpoolContext3.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient3(ctx);
    const position = await this.dal.getPosition(quote.positionAddress, true);
    if (!position) {
      throw new Error(`Position not found: ${translateAddress3(quote.positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, false);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(position.whirlpool).toBase58()}`);
    }
    const tickArrayLower = TickUtil.getPdaWithTickIndex(position.tickLowerIndex, whirlpool.tickSpacing, position.whirlpool, this.dal.programId).publicKey;
    const tickArrayUpper = TickUtil.getPdaWithTickIndex(position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, this.dal.programId).publicKey;
    const positionTokenAccount = await deriveATA(provider.wallet.publicKey, position.positionMint);
    const txBuilder = new TransactionBuilder3(provider);
    const resolvedAssociatedTokenAddresses = {};
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA), { address: tokenOwnerAccountA } = _a, createTokenOwnerAccountAIx = __objRest(_a, ["address"]);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB), { address: tokenOwnerAccountB } = _b, createTokenOwnerAccountBIx = __objRest(_b, ["address"]);
    txBuilder.addInstruction(createTokenOwnerAccountAIx).addInstruction(createTokenOwnerAccountBIx);
    resolvedAssociatedTokenAddresses[whirlpool.tokenMintA.toBase58()] = tokenOwnerAccountA;
    resolvedAssociatedTokenAddresses[whirlpool.tokenMintB.toBase58()] = tokenOwnerAccountB;
    const collectTx = await buildCollectFeesAndRewardsTx(this.dal, {
      provider,
      positionAddress: quote.positionAddress,
      resolvedAssociatedTokenAddresses
    });
    txBuilder.addInstruction(collectTx.compressIx(false));
    if (position.liquidity.gt(new u646(0))) {
      const liquidityIx = client.decreaseLiquidityTx({
        liquidityAmount: position.liquidity,
        tokenMinA: quote.minTokenA,
        tokenMinB: quote.minTokenB,
        whirlpool: position.whirlpool,
        positionAuthority: provider.wallet.publicKey,
        position: toPubKey(quote.positionAddress),
        positionTokenAccount,
        tokenOwnerAccountA,
        tokenOwnerAccountB,
        tokenVaultA: whirlpool.tokenVaultA,
        tokenVaultB: whirlpool.tokenVaultB,
        tickArrayLower,
        tickArrayUpper
      }).compressIx(false);
      txBuilder.addInstruction(liquidityIx);
    }
    const positionIx = client.closePositionTx({
      positionAuthority: provider.wallet.publicKey,
      receiver: provider.wallet.publicKey,
      positionTokenAccount,
      position: toPubKey(quote.positionAddress),
      positionMint: position.positionMint
    }).compressIx(false);
    txBuilder.addInstruction(positionIx);
    return txBuilder;
  }
  async getLowestInitializedTickArrayTickIndex(poolAddress, tickSpacing) {
    let offset = 1;
    while (true) {
      const tickArrayPda = TickUtil.getPdaWithTickIndex(MIN_TICK_INDEX2, tickSpacing, poolAddress, this.dal.programId, offset);
      await new Promise((resolve) => setTimeout(resolve, 1e3));
      const tickArray = await this.dal.getTickArray(tickArrayPda.publicKey, false);
      if (!tickArray) {
        offset++;
        continue;
      }
      return TickUtil.getStartTickIndex(MIN_TICK_INDEX2, tickSpacing, offset);
    }
  }
  async getHighestInitializedTickArrayTickIndex(poolAddress, tickSpacing) {
    let offset = -1;
    while (true) {
      const tickArrayPda = TickUtil.getPdaWithTickIndex(MAX_TICK_INDEX3, tickSpacing, poolAddress, this.dal.programId, offset);
      await new Promise((resolve) => setTimeout(resolve, 1e3));
      const tickArray = await this.dal.getTickArray(tickArrayPda.publicKey, false);
      if (!tickArray) {
        offset--;
        continue;
      }
      return TickUtil.getStartTickIndex(MAX_TICK_INDEX3, tickSpacing, offset);
    }
  }
  async getInitializeGapTickArraysTx(param) {
    const { provider, poolAddress } = param;
    const ctx = WhirlpoolContext3.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient3(ctx);
    const whirlpool = await this.dal.getPool(poolAddress, true);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(poolAddress).toBase58()}`);
    }
    const firstTickIndex = await this.getLowestInitializedTickArrayTickIndex(poolAddress, whirlpool.tickSpacing);
    const lastTickIndex = await this.getHighestInitializedTickArrayTickIndex(poolAddress, whirlpool.tickSpacing);
    let numIxs = 0;
    let txBuilder = new TransactionBuilder3(provider);
    const multiTxBuilder = new MultiTransactionBuilder(provider, []);
    let offset = 1;
    while (true) {
      const tickArrayPda = TickUtil.getPdaWithTickIndex(firstTickIndex, whirlpool.tickSpacing, poolAddress, this.dal.programId, offset);
      const startTick = TickUtil.getStartTickIndex(firstTickIndex, whirlpool.tickSpacing, offset);
      if (startTick === lastTickIndex) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 1e3));
      const tickArray = await this.dal.getTickArray(tickArrayPda.publicKey, false);
      if (!tickArray) {
        txBuilder.addInstruction(client.initTickArrayTx({
          whirlpool: toPubKey(poolAddress),
          tickArrayPda,
          startTick,
          funder: provider.wallet.publicKey
        }).compressIx(false));
        numIxs++;
        if (!(numIxs % 7)) {
          multiTxBuilder.addTxBuilder(txBuilder);
          txBuilder = new TransactionBuilder3(provider);
        }
      }
      offset++;
    }
    if (numIxs % 7) {
      multiTxBuilder.addTxBuilder(txBuilder);
    }
    return multiTxBuilder;
  }
  async getSwapTx(param) {
    const {
      provider,
      quote: {
        sqrtPriceLimitX64,
        otherAmountThreshold,
        amountIn,
        amountOut,
        aToB,
        fixedInput,
        poolAddress
      }
    } = param;
    const ctx = WhirlpoolContext3.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient3(ctx);
    const whirlpool = await this.dal.getPool(poolAddress, true);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(poolAddress).toBase58()}`);
    }
    const txBuilder = new TransactionBuilder3(ctx.provider);
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA, aToB ? amountIn : ZERO), { address: tokenOwnerAccountA } = _a, tokenOwnerAccountAIx = __objRest(_a, ["address"]);
    txBuilder.addInstruction(tokenOwnerAccountAIx);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB, !aToB ? amountIn : ZERO), { address: tokenOwnerAccountB } = _b, tokenOwnerAccountBIx = __objRest(_b, ["address"]);
    txBuilder.addInstruction(tokenOwnerAccountBIx);
    const targetSqrtPriceLimitX64 = sqrtPriceLimitX64 || this.getDefaultSqrtPriceLimit(aToB);
    const tickArrayAddresses = await this.getTickArrayPublicKeysForSwap(whirlpool.tickCurrentIndex, targetSqrtPriceLimitX64, whirlpool.tickSpacing, toPubKey(poolAddress), this.dal.programId, aToB);
    const oraclePda = getOraclePda(ctx.program.programId, translateAddress3(poolAddress));
    txBuilder.addInstruction(client.swapTx({
      amount: fixedInput ? amountIn : amountOut,
      otherAmountThreshold,
      sqrtPriceLimit: targetSqrtPriceLimitX64,
      amountSpecifiedIsInput: fixedInput,
      aToB,
      whirlpool: toPubKey(poolAddress),
      tokenAuthority: provider.wallet.publicKey,
      tokenOwnerAccountA,
      tokenVaultA: whirlpool.tokenVaultA,
      tokenOwnerAccountB,
      tokenVaultB: whirlpool.tokenVaultB,
      tickArray0: tickArrayAddresses[0],
      tickArray1: tickArrayAddresses[1],
      tickArray2: tickArrayAddresses[2],
      oracle: oraclePda.publicKey
    }).compressIx(false));
    return new MultiTransactionBuilder(provider, [txBuilder]);
  }
  getDefaultSqrtPriceLimit(aToB) {
    return new BN3(aToB ? MIN_SQRT_PRICE2 : MAX_SQRT_PRICE2);
  }
  async getTickArrayPublicKeysForSwap(tickCurrentIndex, targetSqrtPriceX64, tickSpacing, poolAddress, programId, aToB) {
    const nextInitializableTickIndex = TickUtil.getNextInitializableTickIndex(tickCurrentIndex, tickSpacing, aToB);
    const targetTickIndex = sqrtPriceX64ToTickIndex2(targetSqrtPriceX64);
    let currentStartTickIndex = TickUtil.getStartTickIndex(nextInitializableTickIndex, tickSpacing);
    const targetStartTickIndex = TickUtil.getStartTickIndex(targetTickIndex, tickSpacing);
    const offset = nextInitializableTickIndex < targetTickIndex ? 1 : -1;
    let count = 1;
    const tickArrayAddresses = [
      getTickArrayPda3(programId, poolAddress, currentStartTickIndex).publicKey,
      PublicKey7.default,
      PublicKey7.default
    ];
    while (currentStartTickIndex !== targetStartTickIndex && count < 3) {
      const nextStartTickIndex = TickUtil.getStartTickIndex(nextInitializableTickIndex, tickSpacing, offset * count);
      const nextTickArrayAddress = getTickArrayPda3(programId, poolAddress, nextStartTickIndex).publicKey;
      const nextTickArray = await this.dal.getTickArray(nextTickArrayAddress, false);
      if (!nextTickArray) {
        break;
      }
      tickArrayAddresses[count] = nextTickArrayAddress;
      count++;
      currentStartTickIndex = nextStartTickIndex;
    }
    while (count < 3) {
      tickArrayAddresses[count] = getTickArrayPda3(programId, poolAddress, currentStartTickIndex).publicKey;
      count++;
    }
    return tickArrayAddresses;
  }
  async getOpenPositionQuote(param) {
    const { poolAddress, tokenMint, tokenAmount, slippageTolerance, refresh } = param;
    const whirlpool = await this.dal.getPool(poolAddress, refresh);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(poolAddress).toBase58()}`);
    }
    let tickLowerIndex = void 0;
    let tickUpperIndex = void 0;
    if (isQuoteByTickIndex(param)) {
      tickLowerIndex = param.tickLowerIndex;
      tickUpperIndex = param.tickUpperIndex;
    } else {
      invariant6(isQuoteByPrice(param), "invalid OpenPositionQuoteParam");
      tickLowerIndex = TickUtil.toValid(sqrtPriceX64ToTickIndex2(toX64(param.priceLower.sqrt())), whirlpool.tickSpacing);
      tickUpperIndex = TickUtil.toValid(sqrtPriceX64ToTickIndex2(toX64(param.priceUpper.sqrt())), whirlpool.tickSpacing);
    }
    const internalParam = {
      tokenMintA: whirlpool.tokenMintA,
      tokenMintB: whirlpool.tokenMintB,
      tickCurrentIndex: whirlpool.tickCurrentIndex,
      sqrtPrice: whirlpool.sqrtPrice,
      inputTokenMint: toPubKey(tokenMint),
      inputTokenAmount: tokenAmount,
      tickLowerIndex,
      tickUpperIndex,
      slippageTolerance: slippageTolerance || defaultSlippagePercentage
    };
    return __spreadValues({
      poolAddress,
      tickLowerIndex,
      tickUpperIndex
    }, getAddLiquidityQuote(internalParam));
  }
  async getClosePositionQuote(param) {
    const { positionAddress, refresh, slippageTolerance } = param;
    const position = await this.dal.getPosition(positionAddress, refresh);
    if (!position) {
      throw new Error(`Position not found: ${translateAddress3(positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, refresh);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(position.whirlpool).toBase58()}`);
    }
    return getRemoveLiquidityQuote({
      positionAddress: toPubKey(positionAddress),
      tickCurrentIndex: whirlpool.tickCurrentIndex,
      sqrtPrice: whirlpool.sqrtPrice,
      tickLowerIndex: position.tickLowerIndex,
      tickUpperIndex: position.tickUpperIndex,
      liquidity: position.liquidity,
      slippageTolerance: slippageTolerance || defaultSlippagePercentage
    });
  }
  async getSwapQuote(param) {
    const {
      poolAddress,
      tokenMint,
      tokenAmount,
      isInput,
      slippageTolerance = defaultSlippagePercentage,
      refresh
    } = param;
    const whirlpool = await this.dal.getPool(poolAddress, refresh);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress3(poolAddress).toBase58()}`);
    }
    const swapDirection = toPubKey(tokenMint).equals(whirlpool.tokenMintA) === isInput ? "Swap A to B" /* AtoB */ : "Swap B to A" /* BtoA */;
    const amountSpecified = isInput ? "Specified input amount" /* Input */ : "Specified output amount" /* Output */;
    const swapSimulator = new SwapSimulator();
    const { amountIn, amountOut, sqrtPriceLimitX64 } = await swapSimulator.simulateSwap({
      refresh,
      dal: this.dal,
      poolAddress,
      whirlpoolData: whirlpool,
      amountSpecified,
      swapDirection
    }, {
      amount: tokenAmount,
      currentSqrtPriceX64: whirlpool.sqrtPrice,
      currentTickIndex: whirlpool.tickCurrentIndex,
      currentLiquidity: whirlpool.liquidity
    });
    const otherAmountThreshold = adjustAmountForSlippage(amountIn, amountOut, slippageTolerance, amountSpecified);
    return {
      poolAddress,
      otherAmountThreshold,
      sqrtPriceLimitX64,
      amountIn,
      amountOut,
      aToB: swapDirection === "Swap A to B" /* AtoB */,
      fixedInput: isInput
    };
  }
};

// src/position/orca-position.ts
import { translateAddress as translateAddress4 } from "@project-serum/anchor";
import {
  getPositionPda as getPositionPda2,
  TransactionBuilder as TransactionBuilder4,
  WhirlpoolContext as WhirlpoolContext4,
  WhirlpoolClient as WhirlpoolClient4
} from "@orca-so/whirlpool-client-sdk";
var OrcaPosition = class {
  constructor(dal) {
    this.dal = dal;
  }
  derivePDA(positionMint) {
    return getPositionPda2(this.dal.programId, toPubKey(positionMint));
  }
  async getAddLiquidityTx(param) {
    const { provider, quote } = param;
    const ctx = WhirlpoolContext4.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient4(ctx);
    const position = await this.dal.getPosition(quote.positionAddress, false);
    if (!position) {
      throw new Error(`Position not found: ${translateAddress4(quote.positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, false);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress4(position.whirlpool).toBase58()}`);
    }
    const [tickArrayLower, tickArrayUpper] = TickUtil.getLowerAndUpperTickArrayAddresses(position.tickLowerIndex, position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, this.dal.programId);
    const positionTokenAccount = await deriveATA(provider.wallet.publicKey, position.positionMint);
    const txBuilder = new TransactionBuilder4(ctx.provider);
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA, quote.maxTokenA), { address: tokenOwnerAccountA } = _a, tokenOwnerAccountAIx = __objRest(_a, ["address"]);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB, quote.maxTokenB), { address: tokenOwnerAccountB } = _b, tokenOwnerAccountBIx = __objRest(_b, ["address"]);
    txBuilder.addInstruction(tokenOwnerAccountAIx);
    txBuilder.addInstruction(tokenOwnerAccountBIx);
    const addLiquidityIx = client.increaseLiquidityTx({
      liquidityAmount: quote.liquidity,
      tokenMaxA: quote.maxTokenA,
      tokenMaxB: quote.maxTokenB,
      whirlpool: position.whirlpool,
      positionAuthority: provider.wallet.publicKey,
      position: toPubKey(quote.positionAddress),
      positionTokenAccount,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA: whirlpool.tokenVaultA,
      tokenVaultB: whirlpool.tokenVaultB,
      tickArrayLower,
      tickArrayUpper
    }).compressIx(false);
    txBuilder.addInstruction(addLiquidityIx);
    return txBuilder;
  }
  async getRemoveLiquidityTx(param) {
    const { provider, quote } = param;
    const ctx = WhirlpoolContext4.withProvider(provider, this.dal.programId);
    const client = new WhirlpoolClient4(ctx);
    const position = await this.dal.getPosition(quote.positionAddress, false);
    if (!position) {
      throw new Error(`Position not found: ${translateAddress4(quote.positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, false);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress4(position.whirlpool).toBase58()}`);
    }
    const [tickArrayLower, tickArrayUpper] = TickUtil.getLowerAndUpperTickArrayAddresses(position.tickLowerIndex, position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, this.dal.programId);
    const positionTokenAccount = await deriveATA(provider.wallet.publicKey, position.positionMint);
    const txBuilder = new TransactionBuilder4(ctx.provider);
    const _a = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintA), { address: tokenOwnerAccountA } = _a, tokenOwnerAccountAIx = __objRest(_a, ["address"]);
    const _b = await resolveOrCreateATA(provider.connection, provider.wallet.publicKey, whirlpool.tokenMintB), { address: tokenOwnerAccountB } = _b, tokenOwnerAccountBIx = __objRest(_b, ["address"]);
    txBuilder.addInstruction(tokenOwnerAccountAIx);
    txBuilder.addInstruction(tokenOwnerAccountBIx);
    const removeLiquidityIx = client.decreaseLiquidityTx({
      liquidityAmount: quote.liquidity,
      tokenMinA: quote.minTokenA,
      tokenMinB: quote.minTokenB,
      whirlpool: position.whirlpool,
      positionAuthority: provider.wallet.publicKey,
      position: toPubKey(quote.positionAddress),
      positionTokenAccount,
      tokenOwnerAccountA,
      tokenOwnerAccountB,
      tokenVaultA: whirlpool.tokenVaultA,
      tokenVaultB: whirlpool.tokenVaultB,
      tickArrayLower,
      tickArrayUpper
    }).compressIx(false);
    txBuilder.addInstruction(removeLiquidityIx);
    return txBuilder;
  }
  async getCollectFeesAndRewardsTx(param) {
    return buildCollectFeesAndRewardsTx(this.dal, {
      provider: param.provider,
      positionAddress: param.positionAddress
    });
  }
  async getCollectMultipleFeesAndRewardsTx(param) {
    return buildMultipleCollectFeesAndRewardsTx(this.dal, param);
  }
  async getAddLiquidityQuote(param) {
    const { positionAddress, tokenMint, tokenAmount, refresh, slippageTolerance } = param;
    const position = await this.dal.getPosition(positionAddress, refresh);
    if (!position) {
      throw new Error(`Position not found: ${translateAddress4(positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, refresh);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: ${translateAddress4(position.whirlpool).toBase58()}`);
    }
    const internalParam = {
      tokenMintA: whirlpool.tokenMintA,
      tokenMintB: whirlpool.tokenMintB,
      tickCurrentIndex: whirlpool.tickCurrentIndex,
      sqrtPrice: whirlpool.sqrtPrice,
      inputTokenMint: toPubKey(tokenMint),
      inputTokenAmount: tokenAmount,
      tickLowerIndex: position.tickLowerIndex,
      tickUpperIndex: position.tickUpperIndex,
      slippageTolerance: slippageTolerance || defaultSlippagePercentage
    };
    return __spreadValues({
      positionAddress
    }, getAddLiquidityQuote(internalParam));
  }
  async getRemoveLiquidityQuote(param) {
    const { positionAddress, liquidity, refresh, slippageTolerance } = param;
    const position = await this.dal.getPosition(positionAddress, refresh);
    if (!position) {
      throw new Error(`Position not found: {$translateAddress(positionAddress).toBase58()}`);
    }
    const whirlpool = await this.dal.getPool(position.whirlpool, refresh);
    if (!whirlpool) {
      throw new Error(`Whirlpool not found: {$translateAddress(poolAddress).toBase58()}`);
    }
    return getRemoveLiquidityQuote({
      positionAddress: toPubKey(positionAddress),
      tickCurrentIndex: whirlpool.tickCurrentIndex,
      sqrtPrice: whirlpool.sqrtPrice,
      tickLowerIndex: position.tickLowerIndex,
      tickUpperIndex: position.tickUpperIndex,
      liquidity,
      slippageTolerance: slippageTolerance || defaultSlippagePercentage
    });
  }
};

// src/utils/token-price.ts
import { NATIVE_MINT as NATIVE_MINT4 } from "@solana/spl-token";
import Decimal3 from "decimal.js";
async function getTokenUSDPrices(dal, pools, baseTokenMint, baseTokenUSDPrice = new Decimal3(1), otherBaseTokenMints = [NATIVE_MINT4]) {
  var _a, _b;
  const tokenGraph = {};
  pools.forEach((pool) => {
    const tokenMintA = pool.tokenMintA.toBase58();
    const tokenMintB = pool.tokenMintB.toBase58();
    tokenGraph[tokenMintA] = __spreadValues({ [tokenMintB]: pool }, tokenGraph[tokenMintA]);
    tokenGraph[tokenMintB] = __spreadValues({ [tokenMintA]: pool }, tokenGraph[tokenMintB]);
  });
  const base = toPubKey(baseTokenMint).toBase58();
  const otherBases = toPubKeys(otherBaseTokenMints).map((pubKey) => pubKey.toBase58());
  const result = { [base]: baseTokenUSDPrice };
  const queue = [base, ...otherBases];
  const visited = /* @__PURE__ */ new Set();
  while (queue.length > 0) {
    const vertex = queue.shift();
    if (!vertex || visited.has(vertex)) {
      continue;
    } else {
      visited.add(vertex);
    }
    const vertexPriceUSD = result[vertex];
    if (!vertexPriceUSD) {
      continue;
    }
    for (const [neighbor, pool] of Object.entries(tokenGraph[vertex] || {})) {
      if (visited.has(neighbor)) {
        continue;
      }
      if (result[neighbor]) {
        queue.push(neighbor);
        continue;
      }
      const tokenDecimalsA = (_a = await dal.getMintInfo(pool.tokenMintA, false)) == null ? void 0 : _a.decimals;
      if (!tokenDecimalsA) {
        throw new Error(`Token mint not found: ${pool.tokenMintA.toBase58()}`);
      }
      const tokenDecimalsB = (_b = await dal.getMintInfo(pool.tokenMintB, false)) == null ? void 0 : _b.decimals;
      if (!tokenDecimalsB) {
        throw new Error(`Token mint not found: ${pool.tokenMintB.toBase58()}`);
      }
      const yxPrice = sqrtPriceX64ToPrice(pool.sqrtPrice, tokenDecimalsA, tokenDecimalsB);
      if (pool.tokenMintA.toBase58() === neighbor) {
        result[neighbor] = yxPrice.mul(vertexPriceUSD);
      } else {
        result[neighbor] = vertexPriceUSD.div(yxPrice);
      }
      queue.push(neighbor);
    }
  }
  return result;
}

// src/pool/convert-data.ts
import { PublicKey as PublicKey9 } from "@solana/web3.js";

// src/utils/public/decimal-utils.ts
import { u64 as u647 } from "@solana/spl-token";
import Decimal4 from "decimal.js";
var DecimalUtil = class {
  static adjustDecimals(input, shift = 0) {
    return input.div(Decimal4.pow(10, shift));
  }
  static fromU64(input, shift = 0) {
    return new Decimal4(input.toString()).div(new Decimal4(10).pow(shift));
  }
  static fromNumber(input, shift = 0) {
    return new Decimal4(input).div(new Decimal4(10).pow(shift));
  }
  static toU64(input, shift = 0) {
    if (input.isNeg()) {
      throw new Error("Negative decimal value ${input} cannot be converted to u64.");
    }
    const shiftedValue = input.mul(new Decimal4(10).pow(shift));
    const zeroDecimalValue = shiftedValue.trunc();
    return new u647(zeroDecimalValue.toString());
  }
};

// src/pool/convert-data.ts
import { fromX64 as fromX642 } from "@orca-so/whirlpool-client-sdk";

// src/utils/public/apr.ts
import { PublicKey as PublicKey8 } from "@solana/web3.js";
import Decimal5 from "decimal.js";
var ZERO_APR = {
  fee: 0,
  rewards: [0, 0, 0]
};
function estimateAprsForPriceRange(pool, tokenPrices, fees24h, tickLowerIndex, tickUpperIndex) {
  const {
    liquidity,
    sqrtPrice,
    tokenMintA,
    tokenMintB,
    tokenDecimalsA,
    tokenDecimalsB,
    tickCurrentIndex
  } = pool;
  const tokenPriceA = tokenPrices[tokenMintA.toBase58()];
  const tokenPriceB = tokenPrices[tokenMintB.toBase58()];
  if (!fees24h || !tokenPriceA || !tokenPriceB || tickLowerIndex >= tickUpperIndex) {
    return ZERO_APR;
  }
  const { minTokenA, minTokenB } = getRemoveLiquidityQuote({
    positionAddress: PublicKey8.default,
    tickCurrentIndex,
    sqrtPrice,
    tickLowerIndex,
    tickUpperIndex,
    liquidity,
    slippageTolerance: ZERO_SLIPPAGE
  });
  const tokenValueA = getTokenValue(minTokenA, tokenDecimalsA, tokenPriceA);
  const tokenValueB = getTokenValue(minTokenB, tokenDecimalsB, tokenPriceB);
  const concentratedValue = tokenValueA.add(tokenValueB);
  const feesPerYear = new Decimal5(fees24h).mul(365);
  const feeApr = feesPerYear.div(concentratedValue).toNumber();
  const rewards = pool.rewards.map((reward) => estimateRewardApr(reward, concentratedValue, tokenPrices));
  return { fee: feeApr, rewards };
}
var SECONDS_PER_YEAR = 60 * 60 * 24 * 365;
function estimateRewardApr(reward, concentratedValue, tokenPrices) {
  const { mint, emissionsPerSecond } = reward;
  const rewardTokenPrice = tokenPrices[mint.toBase58()];
  if (!emissionsPerSecond || !rewardTokenPrice) {
    return 0;
  }
  return emissionsPerSecond.mul(SECONDS_PER_YEAR).mul(rewardTokenPrice).div(concentratedValue).toNumber();
}
function getTokenValue(tokenAmount, tokenDecimals, tokenPrice) {
  return DecimalUtil.adjustDecimals(new Decimal5(tokenAmount.toString()), tokenDecimals).mul(tokenPrice);
}

// src/utils/public/tick.ts
import {
  fromX64,
  sqrtPriceX64ToTickIndex as sqrtPriceX64ToTickIndex3,
  tickIndexToSqrtPriceX64 as tickIndexToSqrtPriceX644,
  TICK_ARRAY_SIZE as TICK_ARRAY_SIZE3,
  toX64 as toX642
} from "@orca-so/whirlpool-client-sdk";
import Decimal6 from "decimal.js";
function getNearestValidTickIndexFromTickIndex(tickIndex, tickSpacing) {
  return TickUtil.toValid(tickIndex, tickSpacing);
}
function getNearestValidTickIndex(price, decimalsA, decimalsB, tickSpacing) {
  return TickUtil.toValid(priceToTickIndex(price, decimalsA, decimalsB), tickSpacing);
}
function getNextValidTickIndex(tickIndex, tickSpacing) {
  return tickIndex + tickSpacing;
}
function getPrevValidTickIndex(tickIndex, tickSpacing) {
  return tickIndex - tickSpacing;
}
function sqrtPriceX64ToPrice(sqrtPriceX64, decimalsA, decimalsB) {
  return fromX64(sqrtPriceX64).pow(2).mul(Decimal6.pow(10, decimalsA - decimalsB));
}
function priceToSqrtX64(price, decimalsA, decimalsB) {
  return toX642(price.mul(Decimal6.pow(10, decimalsB - decimalsA)).sqrt());
}
function tickIndexToPrice(tickIndex, decimalsA, decimalsB) {
  return sqrtPriceX64ToPrice(tickIndexToSqrtPriceX644(tickIndex), decimalsA, decimalsB);
}
function priceToTickIndex(price, decimalsA, decimalsB) {
  return sqrtPriceX64ToTickIndex3(priceToSqrtX64(price, decimalsA, decimalsB));
}
function getStartTickIndex(tickIndex, tickSpacing, offset = 0) {
  const realIndex = Math.floor(tickIndex / tickSpacing / TICK_ARRAY_SIZE3);
  const startTickIndex = (realIndex + offset) * tickSpacing * TICK_ARRAY_SIZE3;
  return startTickIndex;
}

// src/pool/convert-data.ts
async function convertWhirlpoolDataToPoolData(dal, poolAddresses, refresh) {
  var _a, _b, _c, _d, _e, _f;
  if (refresh) {
    const pools = await dal.listPools(poolAddresses, true);
    const allTokenAccounts = /* @__PURE__ */ new Set();
    const allMintInfos = /* @__PURE__ */ new Set();
    const allTickArrays = [];
    pools.forEach((pool, index) => {
      const poolAddress = poolAddresses[index];
      if (pool && poolAddress) {
        allTokenAccounts.add(pool.tokenVaultA.toBase58());
        allTokenAccounts.add(pool.tokenVaultB.toBase58());
        allMintInfos.add(pool.tokenMintA.toBase58());
        allMintInfos.add(pool.tokenMintB.toBase58());
        allTickArrays.push(TickUtil.getPDAWithSqrtPrice(pool.sqrtPrice, pool.tickSpacing, poolAddress, dal.programId).publicKey.toBase58());
        pool.rewardInfos.forEach(({ vault, mint }) => {
          if (!mint.equals(PublicKey9.default) && !vault.equals(PublicKey9.default)) {
            allTokenAccounts.add(vault.toBase58());
            allMintInfos.add(mint.toBase58());
          }
        });
      }
    });
    await Promise.all([
      dal.listTokenInfos(Array.from(allTokenAccounts), true),
      dal.listMintInfos(Array.from(allMintInfos), false),
      dal.listTickArrays(allTickArrays, true)
    ]);
  }
  const result = {};
  for (const address of poolAddresses) {
    const poolId = toPubKey(address).toBase58();
    const pool = await dal.getPool(address, false);
    if (!pool) {
      console.error(`error - pool not found`);
      continue;
    }
    const amountA = (_a = await dal.getTokenInfo(pool.tokenVaultA, false)) == null ? void 0 : _a.amount;
    const amountB = (_b = await dal.getTokenInfo(pool.tokenVaultB, false)) == null ? void 0 : _b.amount;
    const decimalsA = (_c = await dal.getMintInfo(pool.tokenMintA, false)) == null ? void 0 : _c.decimals;
    const decimalsB = (_d = await dal.getMintInfo(pool.tokenMintB, false)) == null ? void 0 : _d.decimals;
    if (!amountA || !amountB || decimalsA === void 0 || decimalsB === void 0) {
      console.error(`error - amount or decimals not found`);
      continue;
    }
    const feePercentage = DecimalUtil.fromNumber(pool.feeRate, 6);
    const protocolFeePercentage = DecimalUtil.fromNumber(pool.protocolFeeRate, 4);
    const rewards = [];
    for (const { mint, vault, emissionsPerSecondX64, growthGlobalX64 } of pool.rewardInfos) {
      let amount = void 0;
      let decimals = void 0;
      if (!mint.equals(PublicKey9.default) && !vault.equals(PublicKey9.default)) {
        amount = (_e = await dal.getTokenInfo(vault, false)) == null ? void 0 : _e.amount;
        decimals = (_f = await dal.getMintInfo(mint, false)) == null ? void 0 : _f.decimals;
      }
      rewards.push({
        mint,
        vault,
        vaultAmount: amount,
        decimalVaultAmount: decimals && amount ? DecimalUtil.fromU64(amount, decimals) : void 0,
        emissionsPerSecondX64,
        growthGlobalX64,
        emissionsPerSecond: decimals ? DecimalUtil.adjustDecimals(fromX642(emissionsPerSecondX64), decimals) : void 0
      });
    }
    result[poolId] = {
      address: toPubKey(address),
      tokenMintA: pool.tokenMintA,
      tokenMintB: pool.tokenMintB,
      tickSpacing: pool.tickSpacing,
      feeRate: pool.feeRate,
      protocolFeeRate: pool.protocolFeeRate,
      liquidity: pool.liquidity,
      sqrtPrice: pool.sqrtPrice,
      tickCurrentIndex: pool.tickCurrentIndex,
      protocolFeeOwedA: pool.protocolFeeOwedA,
      protocolFeeOwedB: pool.protocolFeeOwedB,
      tokenVaultAmountA: amountA,
      tokenVaultAmountB: amountB,
      rewards,
      feeGrowthGlobalAX64: pool.feeGrowthGlobalA,
      feeGrowthGlobalBX64: pool.feeGrowthGlobalB,
      feePercentage,
      protocolFeePercentage,
      price: sqrtPriceX64ToPrice(pool.sqrtPrice, decimalsA, decimalsB),
      decimalProtocolFeeOwedA: DecimalUtil.fromU64(pool.protocolFeeOwedA, decimalsA),
      decimalProtocolFeeOwedB: DecimalUtil.fromU64(pool.protocolFeeOwedB, decimalsB),
      decimalTokenVaultAmountA: DecimalUtil.fromU64(amountA, decimalsA),
      decimalTokenVaultAmountB: DecimalUtil.fromU64(amountB, decimalsB),
      tokenDecimalsA: decimalsA,
      tokenDecimalsB: decimalsB
    };
  }
  return result;
}

// src/position/convert-data.ts
import { PublicKey as PublicKey10 } from "@solana/web3.js";
import invariant8 from "tiny-invariant";

// src/position/quotes/collect-fees.ts
function getCollectFeesQuoteInternal(param) {
  const { whirlpool, position, tickLower, tickUpper } = param;
  const {
    tickCurrentIndex,
    feeGrowthGlobalA: feeGrowthGlobalAX64,
    feeGrowthGlobalB: feeGrowthGlobalBX64
  } = whirlpool;
  const {
    tickLowerIndex,
    tickUpperIndex,
    liquidity,
    feeOwedA,
    feeOwedB,
    feeGrowthCheckpointA: feeGrowthCheckpointAX64,
    feeGrowthCheckpointB: feeGrowthCheckpointBX64
  } = position;
  const {
    feeGrowthOutsideA: tickLowerFeeGrowthOutsideAX64,
    feeGrowthOutsideB: tickLowerFeeGrowthOutsideBX64
  } = tickLower;
  const {
    feeGrowthOutsideA: tickUpperFeeGrowthOutsideAX64,
    feeGrowthOutsideB: tickUpperFeeGrowthOutsideBX64
  } = tickUpper;
  let feeGrowthBelowAX64 = null;
  let feeGrowthBelowBX64 = null;
  if (tickCurrentIndex < tickLowerIndex) {
    feeGrowthBelowAX64 = subUnderflowU128(feeGrowthGlobalAX64, tickLowerFeeGrowthOutsideAX64);
    feeGrowthBelowBX64 = subUnderflowU128(feeGrowthGlobalBX64, tickLowerFeeGrowthOutsideBX64);
  } else {
    feeGrowthBelowAX64 = tickLowerFeeGrowthOutsideAX64;
    feeGrowthBelowBX64 = tickLowerFeeGrowthOutsideBX64;
  }
  let feeGrowthAboveAX64 = null;
  let feeGrowthAboveBX64 = null;
  if (tickCurrentIndex < tickUpperIndex) {
    feeGrowthAboveAX64 = tickUpperFeeGrowthOutsideAX64;
    feeGrowthAboveBX64 = tickUpperFeeGrowthOutsideBX64;
  } else {
    feeGrowthAboveAX64 = subUnderflowU128(feeGrowthGlobalAX64, tickUpperFeeGrowthOutsideAX64);
    feeGrowthAboveBX64 = subUnderflowU128(feeGrowthGlobalBX64, tickUpperFeeGrowthOutsideBX64);
  }
  const feeGrowthInsideAX64 = subUnderflowU128(subUnderflowU128(feeGrowthGlobalAX64, feeGrowthBelowAX64), feeGrowthAboveAX64);
  const feeGrowthInsideBX64 = subUnderflowU128(subUnderflowU128(feeGrowthGlobalBX64, feeGrowthBelowBX64), feeGrowthAboveBX64);
  const feeOwedADelta = subUnderflowU128(feeGrowthInsideAX64, feeGrowthCheckpointAX64).mul(liquidity).shrn(64);
  const feeOwedBDelta = subUnderflowU128(feeGrowthInsideBX64, feeGrowthCheckpointBX64).mul(liquidity).shrn(64);
  const updatedFeeOwedA = feeOwedA.add(feeOwedADelta);
  const updatedFeeOwedB = feeOwedB.add(feeOwedBDelta);
  return {
    feeOwedA: updatedFeeOwedA,
    feeOwedB: updatedFeeOwedB
  };
}

// src/position/quotes/collect-rewards.ts
import { NUM_REWARDS as NUM_REWARDS3 } from "@orca-so/whirlpool-client-sdk";
import { BN as BN4 } from "@project-serum/anchor";
import invariant7 from "tiny-invariant";
function getCollectRewardsQuoteInternal(param) {
  var _a, _b, _c, _d, _e, _f;
  const { whirlpool, position, tickLower, tickUpper } = param;
  const { tickCurrentIndex, rewardInfos: whirlpoolRewardsInfos } = whirlpool;
  const { tickLowerIndex, tickUpperIndex, liquidity, rewardInfos } = position;
  const range = [...Array(NUM_REWARDS3).keys()];
  const rewardGrowthsBelowX64 = range.map(() => new BN4(0));
  const rewardGrowthsAboveX64 = range.map(() => new BN4(0));
  for (const i of range) {
    const rewardInfo = whirlpoolRewardsInfos[i];
    invariant7(!!rewardInfo, "whirlpoolRewardsInfos cannot be undefined");
    const growthGlobalX64 = rewardInfo.growthGlobalX64;
    const lowerRewardGrowthsOutside = tickLower.rewardGrowthsOutside[i];
    const upperRewardGrowthsOutside = tickUpper.rewardGrowthsOutside[i];
    invariant7(!!lowerRewardGrowthsOutside, "lowerRewardGrowthsOutside cannot be undefined");
    invariant7(!!upperRewardGrowthsOutside, "upperRewardGrowthsOutside cannot be undefined");
    if (tickCurrentIndex < tickLowerIndex) {
      rewardGrowthsBelowX64[i] = subUnderflowU128(growthGlobalX64, lowerRewardGrowthsOutside);
    } else {
      rewardGrowthsBelowX64[i] = lowerRewardGrowthsOutside;
    }
    if (tickCurrentIndex < tickUpperIndex) {
      rewardGrowthsAboveX64[i] = upperRewardGrowthsOutside;
    } else {
      rewardGrowthsAboveX64[i] = subUnderflowU128(growthGlobalX64, upperRewardGrowthsOutside);
    }
  }
  const rewardGrowthsInsideX64 = range.map(() => [new BN4(0), false]);
  for (const i of range) {
    const rewardInfo = whirlpoolRewardsInfos[i];
    invariant7(!!rewardInfo, "whirlpoolRewardsInfos cannot be undefined");
    const isRewardInitialized = PoolUtil.isRewardInitialized(rewardInfo);
    if (isRewardInitialized) {
      const growthBelowX64 = rewardGrowthsBelowX64[i];
      const growthAboveX64 = rewardGrowthsAboveX64[i];
      invariant7(!!growthBelowX64, "growthBelowX64 cannot be undefined");
      invariant7(!!growthAboveX64, "growthAboveX64 cannot be undefined");
      const growthInsde = subUnderflowU128(subUnderflowU128(rewardInfo.growthGlobalX64, growthBelowX64), growthAboveX64);
      rewardGrowthsInsideX64[i] = [growthInsde, true];
    }
  }
  const updatedRewardInfosX64 = range.map(() => new BN4(0));
  for (const i of range) {
    const growthInsideX64 = rewardGrowthsInsideX64[i];
    invariant7(!!growthInsideX64, "growthInsideX64 cannot be undefined");
    const [rewardGrowthInsideX64, isRewardInitialized] = growthInsideX64;
    if (isRewardInitialized) {
      const rewardInfo = rewardInfos[i];
      invariant7(!!rewardInfo, "rewardInfo cannot be undefined");
      const amountOwedX64 = rewardInfo.amountOwed.shln(64);
      const growthInsideCheckpointX64 = rewardInfo.growthInsideCheckpoint;
      updatedRewardInfosX64[i] = amountOwedX64.add(subUnderflowU128(rewardGrowthInsideX64, growthInsideCheckpointX64).mul(liquidity));
    }
  }
  invariant7(rewardGrowthsInsideX64.length >= 3, "rewards length is less than 3");
  const rewardExistsA = (_a = rewardGrowthsInsideX64[0]) == null ? void 0 : _a[1];
  const rewardExistsB = (_b = rewardGrowthsInsideX64[1]) == null ? void 0 : _b[1];
  const rewardExistsC = (_c = rewardGrowthsInsideX64[2]) == null ? void 0 : _c[1];
  const rewardOwedA = rewardExistsA ? (_d = updatedRewardInfosX64[0]) == null ? void 0 : _d.shrn(64) : void 0;
  const rewardOwedB = rewardExistsB ? (_e = updatedRewardInfosX64[1]) == null ? void 0 : _e.shrn(64) : void 0;
  const rewardOwedC = rewardExistsC ? (_f = updatedRewardInfosX64[2]) == null ? void 0 : _f.shrn(64) : void 0;
  return [rewardOwedA, rewardOwedB, rewardOwedC];
}

// src/position/convert-data.ts
import { getPositionPda as getPositionPda3 } from "@orca-so/whirlpool-client-sdk";
async function convertPositionDataToUserPositionData(dal, walletAddress, refresh) {
  var _a, _b, _c;
  const positionAddresses = await getUserPositions(dal, walletAddress, refresh);
  const result = {};
  for (const address of positionAddresses) {
    const positionId = toPubKey(address).toBase58();
    const position = await dal.getPosition(address, refresh);
    if (!position) {
      console.error(`error - position not found`);
      continue;
    }
    const whirlpool = await dal.getPool(position.whirlpool, refresh);
    if (!whirlpool) {
      console.error(`error - whirlpool not found`);
      continue;
    }
    const [tickLowerAddress, tickUpperAddress] = TickUtil.getLowerAndUpperTickArrayAddresses(position.tickLowerIndex, position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, dal.programId);
    const tickArrayLower = await dal.getTickArray(tickLowerAddress, false);
    const tickArrayUpper = await dal.getTickArray(tickUpperAddress, false);
    if (!tickArrayLower || !tickArrayUpper) {
      console.error(`error - tick array not found`);
      continue;
    }
    const tickLower = TickUtil.getTick(tickArrayLower, position.tickLowerIndex, whirlpool.tickSpacing);
    const tickUpper = TickUtil.getTick(tickArrayUpper, position.tickUpperIndex, whirlpool.tickSpacing);
    const quoteParam = { whirlpool, position, tickLower, tickUpper };
    const feesQuote = getCollectFeesQuoteInternal(quoteParam);
    const decimalsA = (_a = await dal.getMintInfo(whirlpool.tokenMintA, false)) == null ? void 0 : _a.decimals;
    const decimalsB = (_b = await dal.getMintInfo(whirlpool.tokenMintB, false)) == null ? void 0 : _b.decimals;
    if (decimalsA === void 0 || decimalsB === void 0) {
      console.error(`error - decimals not found`);
      continue;
    }
    const decimalFeeOwedA = DecimalUtil.fromU64(feesQuote.feeOwedA, decimalsA);
    const decimalFeeOwedB = DecimalUtil.fromU64(feesQuote.feeOwedB, decimalsB);
    const rewardsQuote = getCollectRewardsQuoteInternal(quoteParam);
    const rewards = [];
    for (const [index, { mint, vault }] of whirlpool.rewardInfos.entries()) {
      const amountOwed = rewardsQuote[index];
      const decimals = !mint.equals(PublicKey10.default) && !vault.equals(PublicKey10.default) ? (_c = await dal.getMintInfo(mint, false)) == null ? void 0 : _c.decimals : void 0;
      const decimalAmountOwed = amountOwed && decimals ? DecimalUtil.fromU64(amountOwed, decimals) : void 0;
      rewards.push({
        mint,
        amountOwed,
        decimalAmountOwed
      });
    }
    result[positionId] = {
      address: toPubKey(address),
      poolAddress: position.whirlpool,
      positionMint: position.positionMint,
      liquidity: position.liquidity,
      tickLowerIndex: position.tickLowerIndex,
      tickUpperIndex: position.tickUpperIndex,
      feeOwedA: feesQuote.feeOwedA,
      feeOwedB: feesQuote.feeOwedB,
      rewards,
      priceLower: tickIndexToPrice(position.tickLowerIndex, decimalsA, decimalsB),
      priceUpper: tickIndexToPrice(position.tickUpperIndex, decimalsA, decimalsB),
      decimalFeeOwedA,
      decimalFeeOwedB
    };
  }
  return result;
}
async function getUserPositions(dal, walletAddress, refresh) {
  const potentialPositionAddresses = [];
  const userTokens = await dal.listUserTokens(walletAddress, refresh);
  userTokens.forEach(({ amount, decimals, mint }) => {
    if (amount === "1" && decimals === 0 && !!mint) {
      potentialPositionAddresses.push(getPositionPda3(dal.programId, toPubKey(mint)).publicKey);
    }
  });
  const positions = await dal.listPositions(potentialPositionAddresses, refresh);
  invariant8(potentialPositionAddresses.length === positions.length, "not enough positions data");
  if (refresh) {
    const whirlpoolAddresses = /* @__PURE__ */ new Set();
    positions.forEach((position) => {
      if (position) {
        whirlpoolAddresses.add(position.whirlpool.toBase58());
      }
    });
    const pools = await dal.listPools(Array.from(whirlpoolAddresses), refresh);
    const allMintInfos = /* @__PURE__ */ new Set();
    pools.forEach((pool) => {
      if (pool) {
        allMintInfos.add(pool.tokenMintA.toBase58());
        allMintInfos.add(pool.tokenMintB.toBase58());
        pool.rewardInfos.forEach(({ mint, vault }) => {
          if (!mint.equals(PublicKey10.default) && !vault.equals(PublicKey10.default)) {
            allMintInfos.add(mint.toBase58());
          }
        });
      }
    });
    const tickArrayAddresses = /* @__PURE__ */ new Set();
    for (const position of positions) {
      if (position) {
        const whirlpool = await dal.getPool(position.whirlpool, false);
        if (whirlpool) {
          const [tickLowerAddress, tickUpperAddress] = TickUtil.getLowerAndUpperTickArrayAddresses(position.tickLowerIndex, position.tickUpperIndex, whirlpool.tickSpacing, position.whirlpool, dal.programId);
          tickArrayAddresses.add(tickLowerAddress.toBase58());
          tickArrayAddresses.add(tickUpperAddress.toBase58());
        }
      }
    }
    await Promise.all([
      dal.listMintInfos(Array.from(allMintInfos), false),
      dal.listTickArrays(Array.from(tickArrayAddresses), true)
    ]);
  }
  return potentialPositionAddresses.filter((_, index) => {
    return positions[index] !== null;
  });
}

// src/offchain/orca-zp.ts
import axios from "axios";
var CacheKey_Tokens = "tokens";
var CacheKey_Pools = "pools";
var OrcaZooplankton = class {
  constructor(dataSourceURI) {
    this._cache = new InternalZPCache();
    this._request = axios.create({ baseURL: dataSourceURI });
  }
  async getTokens() {
    try {
      const cachedResponse = this._cache.get(CacheKey_Tokens);
      if (cachedResponse) {
        return cachedResponse;
      }
      const response = await this._request.request({ url: "/tokens", method: "get" });
      const data = response == null ? void 0 : response.data;
      if (!data) {
        return null;
      }
      const result = {};
      data.forEach((token) => {
        result[token.mint] = {
          mint: token.mint,
          name: token.name,
          symbol: token.symbol,
          logoURI: token.logoURI,
          whitelisted: token.whitelisted,
          coingeckoId: token.coingeckoId,
          ftxId: token.ftxId
        };
      });
      this._cache.set(CacheKey_Tokens, result);
      return result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  async getPools() {
    try {
      const cachedResponse = this._cache.get(CacheKey_Pools);
      if (cachedResponse) {
        return cachedResponse;
      }
      const response = await this._request.request({ url: "/pools", method: "get" });
      const data = response == null ? void 0 : response.data;
      if (!data) {
        return null;
      }
      const result = {};
      data.forEach((pool) => {
        result[pool.address] = {
          address: pool.address,
          whitelisted: pool.whitelisted,
          tokenMintA: pool.tokenMintA,
          tokenMintB: pool.tokenMintB,
          stable: pool.stable,
          price: pool.price,
          lpsFeeRate: pool.lpsFeeRate,
          protocolFeeRate: pool.protocolFeeRate,
          priceHistory: pool.priceHistory,
          tokenAPriceUSD: pool.tokenAPriceUSD,
          tokenBPriceUSD: pool.tokenBPriceUSD,
          tvl: pool.tvl,
          volume: pool.volume,
          feeApr: pool.feeApr,
          reward0Apr: pool.reward0Apr,
          reward1Apr: pool.reward1Apr,
          reward2Apr: pool.reward2Apr,
          totalApr: pool.totalApr
        };
      });
      this._cache.set(CacheKey_Pools, result);
      return result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};
var InternalZPCache = class {
  constructor() {
    this._cache = {};
  }
  get(key) {
    const content = this._cache[key];
    if (!content || Date.now() >= content.eol) {
      return null;
    }
    return content.value;
  }
  set(key, value, ttl = 15e3) {
    this._cache[key] = { value, eol: Date.now() + ttl };
  }
};

// src/client.ts
Decimal7.set({ precision: 40, toExpPos: 40, toExpNeg: -20, rounding: 1 });
var OrcaWhirlpoolClient = class {
  constructor(config) {
    const network = (config == null ? void 0 : config.network) || defaultNetwork;
    const connection = (config == null ? void 0 : config.connection) || getDefaultConnection(network);
    const whirlpoolsConfig = (config == null ? void 0 : config.whirlpoolConfig) || getWhirlpoolsConfig(network);
    const programId = (config == null ? void 0 : config.programId) || getWhirlpoolProgramId(network);
    const offchainDataURI = (config == null ? void 0 : config.offchainDataURI) || getDefaultOffchainDataURI(network);
    this.data = new OrcaDAL(whirlpoolsConfig, programId, connection);
    this.admin = new OrcaAdmin(this.data);
    this.pool = new OrcaPool(this.data);
    this.position = new OrcaPosition(this.data);
    this.offchain = new OrcaZooplankton(offchainDataURI);
  }
  async getTokenPrices(poolAddresses, baseTokenMint, baseTokenUSDPrice = new Decimal7(1), otherBaseTokenMints = [NATIVE_MINT5], refresh = true) {
    const allPools = await this.data.listPools(poolAddresses, refresh);
    const pools = allPools.filter((pool) => pool !== null);
    return getTokenUSDPrices(this.data, pools, baseTokenMint, baseTokenUSDPrice, otherBaseTokenMints);
  }
  async getUserPositions(walletAddress, refresh = true) {
    return convertPositionDataToUserPositionData(this.data, walletAddress, refresh);
  }
  async getPools(poolAddresses, refresh = true) {
    return convertWhirlpoolDataToPoolData(this.data, poolAddresses, refresh);
  }
  async getPool(poolAddress, refresh = true) {
    const pool = (await this.getPools([poolAddress], refresh))[toPubKey(poolAddress).toBase58()];
    return pool || null;
  }
};

// src/admin/public/config.ts
import {
  WhirlpoolContext as WhirlpoolContext5,
  WhirlpoolClient as WhirlpoolClient5,
  getFeeTierPda as getFeeTierPda2
} from "@orca-so/whirlpool-client-sdk";
function getInitWhirlpoolConfigsTx({
  programId,
  provider,
  whirlpoolConfigKeypair,
  feeAuthority,
  collectProtocolFeesAuthority,
  rewardEmissionsSuperAuthority,
  defaultProtocolFeeRate
}) {
  const ctx = WhirlpoolContext5.withProvider(provider, toPubKey(programId));
  const client = new WhirlpoolClient5(ctx);
  return client.initConfigTx({
    whirlpoolConfigKeypair,
    feeAuthority: toPubKey(feeAuthority),
    collectProtocolFeesAuthority: toPubKey(collectProtocolFeesAuthority),
    rewardEmissionsSuperAuthority: toPubKey(rewardEmissionsSuperAuthority),
    defaultProtocolFeeRate,
    funder: provider.wallet.publicKey
  });
}
function getInitFeeTierConfigTx({
  programId,
  provider,
  whirlpoolConfigKey,
  feeAuthority,
  tickSpacing,
  defaultFeeRate
}) {
  const ctx = WhirlpoolContext5.withProvider(provider, toPubKey(programId));
  const client = new WhirlpoolClient5(ctx);
  const feeTierPda = getFeeTierPda2(toPubKey(programId), toPubKey(whirlpoolConfigKey), tickSpacing);
  const params = {
    whirlpoolConfigKey: toPubKey(whirlpoolConfigKey),
    feeAuthority: toPubKey(feeAuthority),
    feeTierPda,
    tickSpacing,
    defaultFeeRate,
    funder: provider.wallet.publicKey
  };
  return client.initFeeTierTx(params);
}

// src/pool/orca-pool-sync.ts
import {
  getTickArrayPda as getTickArrayPda4,
  MAX_SQRT_PRICE as MAX_SQRT_PRICE3,
  MIN_SQRT_PRICE as MIN_SQRT_PRICE3,
  parseTickArray as parseTickArray2,
  parseWhirlpool as parseWhirlpool2,
  sqrtPriceX64ToTickIndex as sqrtPriceX64ToTickIndex4,
  tickIndexToSqrtPriceX64 as tickIndexToSqrtPriceX645,
  TICK_ARRAY_SIZE as TICK_ARRAY_SIZE4
} from "@orca-so/whirlpool-client-sdk";
import { PublicKey as PublicKey11 } from "@solana/web3.js";
import BN5 from "bn.js";
import invariant9 from "tiny-invariant";
var PROGRAM_ID = new PublicKey11("whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc");
function getSwapQuote(param) {
  const {
    poolAddress,
    whirlpool,
    tickArrays,
    tokenMint,
    tokenAmount,
    isInput,
    slippageTolerance = defaultSlippagePercentage
  } = param;
  const swapDirection = tokenMint.equals(whirlpool.tokenMintA) === isInput ? "Swap A to B" /* AtoB */ : "Swap B to A" /* BtoA */;
  const amountSpecified = isInput ? "Specified input amount" /* Input */ : "Specified output amount" /* Output */;
  const { amountIn, amountOut, sqrtPriceLimitX64 } = simulateSwap({
    poolAddress,
    whirlpoolData: whirlpool,
    tickArrays,
    amountSpecified,
    swapDirection
  }, {
    amount: tokenAmount,
    currentSqrtPriceX64: whirlpool.sqrtPrice,
    currentTickIndex: whirlpool.tickCurrentIndex,
    currentLiquidity: whirlpool.liquidity
  });
  const otherAmountThreshold = adjustAmountForSlippage(amountIn, amountOut, slippageTolerance, amountSpecified);
  return {
    otherAmountThreshold,
    sqrtPriceLimitX64,
    amountIn,
    amountOut,
    aToB: swapDirection === "Swap A to B" /* AtoB */,
    fixedInput: isInput
  };
}
function simulateSwap(baseInput, input) {
  const { amountSpecified, swapDirection } = baseInput;
  let {
    currentTickIndex,
    currentLiquidity,
    amount: specifiedAmountLeft,
    currentSqrtPriceX64
  } = input;
  invariant9(!specifiedAmountLeft.eq(ZERO), "amount must be nonzero");
  let otherAmountCalculated = ZERO;
  let tickArraysCrossed = 0;
  let sqrtPriceLimitX64;
  while (specifiedAmountLeft.gt(ZERO)) {
    if (tickArraysCrossed > MAX_TICK_ARRAY_CROSSINGS) {
      throw Error("Crossed the maximum number of tick arrays");
    }
    const swapStepSimulationOutput = simulateSwapStep(baseInput, {
      sqrtPriceX64: currentSqrtPriceX64,
      amountRemaining: specifiedAmountLeft,
      tickIndex: currentTickIndex,
      liquidity: currentLiquidity,
      tickArraysCrossed
    });
    const { input: input2, output, nextSqrtPriceX64, nextTickIndex, hasReachedNextTick } = swapStepSimulationOutput;
    const [specifiedAmountUsed, otherAmount] = resolveTokenAmounts(input2, output, amountSpecified);
    specifiedAmountLeft = specifiedAmountLeft.sub(specifiedAmountUsed);
    otherAmountCalculated = otherAmountCalculated.add(otherAmount);
    if (hasReachedNextTick) {
      const nextTick = pickTick(baseInput, nextTickIndex);
      currentLiquidity = calculateNewLiquidity(currentLiquidity, nextTick.liquidityNet, swapDirection);
      currentTickIndex = swapDirection == "Swap A to B" /* AtoB */ ? nextTickIndex - 1 : nextTickIndex;
    }
    currentSqrtPriceX64 = nextSqrtPriceX64;
    tickArraysCrossed = swapStepSimulationOutput.tickArraysCrossed;
    if (tickArraysCrossed > MAX_TICK_ARRAY_CROSSINGS) {
      sqrtPriceLimitX64 = tickIndexToSqrtPriceX645(nextTickIndex);
    }
  }
  const [inputAmount, outputAmount] = resolveTokenAmounts(input.amount.sub(specifiedAmountLeft), otherAmountCalculated, amountSpecified);
  if (!sqrtPriceLimitX64) {
    if (swapDirection === "Swap A to B" /* AtoB */) {
      sqrtPriceLimitX64 = new BN5(MIN_SQRT_PRICE3);
    } else {
      sqrtPriceLimitX64 = new BN5(MAX_SQRT_PRICE3);
    }
  }
  return {
    amountIn: inputAmount,
    amountOut: outputAmount,
    sqrtPriceLimitX64
  };
}
function pickTick(baseInput, tickIndex) {
  const tickArray = pickTickArray(baseInput, tickIndex);
  const {
    whirlpoolData: { tickSpacing }
  } = baseInput;
  return TickUtil.getTick(tickArray, tickIndex, tickSpacing);
}
function pickTickArray(baseInput, tickIndex) {
  const {
    poolAddress,
    whirlpoolData: { tickSpacing }
  } = baseInput;
  const tickArrayPk = TickUtil.getPdaWithTickIndex(tickIndex, tickSpacing, poolAddress, PROGRAM_ID).publicKey;
  const tickArray = baseInput.tickArrays.get(tickArrayPk.toBase58());
  invariant9(!!tickArray, "tickArray is null");
  return tickArray;
}
function simulateSwapStep(baseInput, input) {
  const { whirlpoolData, amountSpecified, swapDirection } = baseInput;
  const { feeRate } = whirlpoolData;
  const feeRatePercentage = PoolUtil.getFeeRate(feeRate);
  const { amountRemaining, liquidity, sqrtPriceX64, tickIndex, tickArraysCrossed } = input;
  const { tickIndex: nextTickIndex, tickArraysCrossed: tickArraysCrossedUpdate } = getNextInitializedTickIndex2(baseInput, tickIndex, tickArraysCrossed);
  const targetSqrtPriceX64 = tickIndexToSqrtPriceX645(nextTickIndex);
  let fixedDelta = getAmountFixedDelta(sqrtPriceX64, targetSqrtPriceX64, liquidity, amountSpecified, swapDirection);
  let amountCalculated = amountRemaining;
  if (amountSpecified == "Specified input amount" /* Input */) {
    amountCalculated = calculateAmountAfterFees(amountRemaining, feeRatePercentage);
  }
  const nextSqrtPriceX64 = amountCalculated.gte(fixedDelta) ? targetSqrtPriceX64 : getNextSqrtPrice(sqrtPriceX64, liquidity, amountCalculated, amountSpecified, swapDirection);
  const hasReachedNextTick = nextSqrtPriceX64.eq(targetSqrtPriceX64);
  const unfixedDelta = getAmountUnfixedDelta(sqrtPriceX64, nextSqrtPriceX64, liquidity, amountSpecified, swapDirection);
  if (!hasReachedNextTick) {
    fixedDelta = getAmountFixedDelta(sqrtPriceX64, nextSqrtPriceX64, liquidity, amountSpecified, swapDirection);
  }
  let [inputDelta, outputDelta] = resolveTokenAmounts(fixedDelta, unfixedDelta, amountSpecified);
  if (amountSpecified == "Specified output amount" /* Output */ && outputDelta.gt(amountRemaining)) {
    outputDelta = amountRemaining;
  }
  if (amountSpecified == "Specified input amount" /* Input */ && !hasReachedNextTick) {
    inputDelta = amountRemaining;
  } else {
    inputDelta = inputDelta.add(calculateFeesFromAmount(inputDelta, feeRatePercentage));
  }
  return {
    nextTickIndex,
    nextSqrtPriceX64,
    input: inputDelta,
    output: outputDelta,
    tickArraysCrossed: tickArraysCrossedUpdate,
    hasReachedNextTick
  };
}
function getNextInitializedTickIndex2(baseInput, currentTickIndex, tickArraysCrossed) {
  const {
    whirlpoolData: { tickSpacing },
    swapDirection
  } = baseInput;
  let nextInitializedTickIndex = void 0;
  while (nextInitializedTickIndex === void 0) {
    const currentTickArray = pickTickArray(baseInput, currentTickIndex);
    let temp;
    if (swapDirection == "Swap A to B" /* AtoB */) {
      temp = TickUtil.getPrevInitializedTickIndex(currentTickArray, currentTickIndex, tickSpacing);
    } else {
      temp = TickUtil.getNextInitializedTickIndex(currentTickArray, currentTickIndex, tickSpacing);
    }
    if (temp) {
      nextInitializedTickIndex = temp;
    } else if (tickArraysCrossed === MAX_TICK_ARRAY_CROSSINGS) {
      if (swapDirection === "Swap A to B" /* AtoB */) {
        nextInitializedTickIndex = currentTickArray.startTickIndex;
      } else {
        nextInitializedTickIndex = currentTickArray.startTickIndex + TICK_ARRAY_SIZE4 * tickSpacing;
      }
      tickArraysCrossed++;
    } else {
      if (swapDirection === "Swap A to B" /* AtoB */) {
        currentTickIndex = currentTickArray.startTickIndex - 1;
      } else {
        currentTickIndex = currentTickArray.startTickIndex + TICK_ARRAY_SIZE4 * tickSpacing;
      }
      tickArraysCrossed++;
    }
  }
  return {
    tickIndex: nextInitializedTickIndex,
    tickArraysCrossed
  };
}
function getTickArrayPks(poolAddress, whirlpool) {
  const tickArrayPks = [];
  for (let tickArrayOffset = -3; tickArrayOffset <= 2; tickArrayOffset++) {
    const pk = TickUtil.getPdaWithTickIndex(whirlpool.tickCurrentIndex, whirlpool.tickSpacing, poolAddress, PROGRAM_ID, tickArrayOffset).publicKey;
    tickArrayPks.push(pk);
  }
  return tickArrayPks;
}
function getTickArrayPublicKeysForSwap(tickCurrentIndex, targetSqrtPriceX64, tickSpacing, poolAddress, tickArrays, programId, aToB) {
  const nextInitializableTickIndex = TickUtil.getNextInitializableTickIndex(tickCurrentIndex, tickSpacing, aToB);
  const targetTickIndex = sqrtPriceX64ToTickIndex4(targetSqrtPriceX64);
  let currentStartTickIndex = TickUtil.getStartTickIndex(nextInitializableTickIndex, tickSpacing);
  const targetStartTickIndex = TickUtil.getStartTickIndex(targetTickIndex, tickSpacing);
  const offset = nextInitializableTickIndex < targetTickIndex ? 1 : -1;
  let count = 1;
  const tickArrayAddresses = [
    getTickArrayPda4(programId, poolAddress, currentStartTickIndex).publicKey,
    PublicKey11.default,
    PublicKey11.default
  ];
  while (currentStartTickIndex != targetStartTickIndex && count < 3) {
    const nextStartTickIndex = TickUtil.getStartTickIndex(nextInitializableTickIndex, tickSpacing, offset * count);
    const nextTickArrayAddress = getTickArrayPda4(programId, poolAddress, nextStartTickIndex).publicKey;
    const nextTickArray = tickArrays.get(nextTickArrayAddress.toBase58());
    if (!nextTickArray) {
      break;
    }
    tickArrayAddresses[count] = nextTickArrayAddress;
    count++;
    currentStartTickIndex = nextStartTickIndex;
  }
  while (count < 3) {
    tickArrayAddresses[count] = getTickArrayPda4(programId, poolAddress, currentStartTickIndex).publicKey;
    count++;
  }
  return tickArrayAddresses;
}
function getDefaultSqrtPriceLimit(aToB) {
  return new BN5(aToB ? MIN_SQRT_PRICE3 : MAX_SQRT_PRICE3);
}
export {
  DecimalUtil,
  MultiTransactionBuilder,
  OrcaNetwork,
  OrcaWhirlpoolClient,
  Percentage,
  PositionStatus,
  PositionUtil,
  ZERO_APR,
  ZERO_SLIPPAGE,
  adjustAmountForSlippage,
  adjustForSlippage,
  defaultNetwork,
  defaultSlippagePercentage,
  estimateAprsForPriceRange,
  getAmountFixedDelta,
  getAmountUnfixedDelta,
  getDefaultConnection,
  getDefaultOffchainDataURI,
  getDefaultSqrtPriceLimit,
  getInitFeeTierConfigTx,
  getInitWhirlpoolConfigsTx,
  getLiquidityFromTokenA,
  getLiquidityFromTokenB,
  getNearestValidTickIndex,
  getNearestValidTickIndexFromTickIndex,
  getNextSqrtPrice,
  getNextValidTickIndex,
  getPrevValidTickIndex,
  getRemoveLiquidityQuote,
  getStartTickIndex,
  getSwapQuote,
  getTickArrayPks,
  getTickArrayPublicKeysForSwap,
  getTokenAFromLiquidity,
  getTokenBFromLiquidity,
  isQuoteByPrice,
  isQuoteByTickIndex,
  parseTickArray2 as parseTickArray,
  parseWhirlpool2 as parseWhirlpool,
  priceToSqrtX64,
  priceToTickIndex,
  sqrtPriceX64ToPrice,
  tickIndexToPrice
};
