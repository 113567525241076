import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  BlockhashWithExpiryBlockHeight,
  Cluster,
  Connection,
  FeeCalculator,
  Keypair,
  PublicKey,
  Transaction,
  TransactionResponse,
  TransactionSignature,
} from '@solana/web3.js';

import { OpenOrders } from '@project-serum/serum';
import {
  computeInputRouteSegments,
  computeRouteMap,
  fetchMarketCache,
  getAllAmms,
  getTokenRouteSegments,
  isSplitSetupRequired,
  RouteInfo,
} from './routes';
import { MarketInfo } from './market';
import {
  DEVNET_SERUM_DEX_PROGRAM,
  JUPITER_WALLET,
  MAINNET_SERUM_DEX_PROGRAM,
  WRAPPED_SOL_MINT,
  MARKETS_URL,
  SWAP_PROTOCOL_TOKENS,
  INDEXED_ROUTE_MAP_URL,
} from '../constants';
import { getDepositAndFeeFromInstructions, NO_PLATFORM_FEE } from './fee';
import routeToInstructions, { routeAtaInstructions } from './routeToInstructions';
import { getOrCreateOpenOrdersAddress } from './serum/openOrders';
import { createAndCloseWSOLAccount } from '../utils/token';
import { getEmptyInstruction, Instruction } from '../utils/instruction';
import { TransactionBuilder } from '../utils/TransactionBuilder';
import { Owner } from '../utils/Owner';
import {
  getSignature,
  getTokenBalanceChangesFromTransactionResponse,
  transactionSenderAndConfirmationWaiter,
} from '../utils/transactionHelpers';
import { createInitializeTokenLedgerInstruction, TOKEN_LEDGER } from './jupiterInstruction';
import { fetchAccountInfos, processInputRouteSegmentToRoutesInfos } from './computeRouteInfos';
import type { SignerWalletAdapter } from '@solana/wallet-adapter-base';
import { TokenRouteSegments, PlatformFeeAndAccounts, QuoteMintToReferrer } from './types';
import { SerumAmm } from './serum/serumAmm';
import { SaberAmm } from './saber/saberAmm';
import { SplTokenSwapAmm } from './spl-token-swap/splTokenSwapAmm';
import { MercurialAmm } from './mercurial/mercurialAmm';
import { AldrinAmm } from './aldrin/aldrinAmm';
import { RaydiumAmm } from './raydium/raydiumAmm';
import { CropperAmm } from './cropper/cropperAmm';
import { SenchaAmm } from './sencha/senchaAmm';
import { SplitTradeAmm } from './split-trade/splitTradeAmm';
import { TokenMintAddress, SetupInstructions } from './types';
import { getPlatformFeeAccounts } from './fee';
import { Amm, SwapMode } from './amm';
import { validateTransactionResponse } from '../utils/tx/errors';
import { TransactionError } from '@mercurial-finance/optimist';
import { getSaberWrappedDecimalsAmms, SaberAddDecimalsAmm } from './saber/saberAddDecimalsAmm';
import { getTopTokens } from './getTopTokens';
import { WhirlpoolAmm } from './whirlpool/whirlpoolAmm';
import { CykuraAmm } from './cykura/cykuraAmm';
import JSBI from 'jsbi';
import { indexedRouteMapToRouteMap, IndexedRouteMap } from '../utils/indexedRouteMap';
import fetch from 'cross-fetch';

export type SerumOpenOrdersMap = Map<string, PublicKey>;
export { MarketInfo } from './market';
export { getPlatformFeeAccounts } from './fee';
export * from './types';
export { transactionSenderAndConfirmationWaiter } from '../utils/transactionHelpers';
export { routeMapToIndexedRouteMap, indexedRouteMapToRouteMap, IndexedRouteMap } from '../utils/indexedRouteMap';
export { RouteInfo, TransactionFeeInfo, getRouteInfoUniqueId } from './routes';
export { getSaberWrappedDecimalsAmms, TransactionError };
export {
  Amm,
  AldrinAmm,
  CykuraAmm,
  RaydiumAmm,
  SerumAmm,
  SaberAmm,
  SplTokenSwapAmm,
  MercurialAmm,
  CropperAmm,
  SenchaAmm,
  SaberAddDecimalsAmm,
  SplitTradeAmm,
  WhirlpoolAmm,
  SwapMode,
};

export type SwapResult =
  | {
      txid: string;
      inputAddress: PublicKey;
      outputAddress: PublicKey;
      inputAmount: number;
      outputAmount: number;
    }
  | {
      error?: TransactionError;
    };

type InputMintAndOutputMint = string;

export type JupiterLoadParams = {
  connection: Connection;
  cluster: Cluster;
  user?: PublicKey | Keypair;
  platformFeeAndAccounts?: PlatformFeeAndAccounts;
  /** See {@link Jupiter.quoteMintToReferrer} */
  quoteMintToReferrer?: Map<TokenMintAddress, PublicKey>;
  /** See {@link Jupiter.routeCacheDuration} */
  routeCacheDuration?: number;
  /** See {@link Jupiter.wrapUnwrapSOL} */
  wrapUnwrapSOL?: boolean;
  /** A markets cache URL, default to jupiter markets cache */
  marketUrl?: string;
  /**
   * On multi-leg trades, the intermediate tokens is restricted to X top tokens in volume and certain utility tokens (Saber wrapped decimal tokens)
   * This is to reduce the load by having to compute trades through routes that are not so liquid
   */
  restrictIntermediateTokens?: boolean;
  /** See {@link Jupiter.tokenLedger}, default to the standard Jupiter token ledger */
  tokenLedger?: PublicKey;
  /** See {@link Jupiter.shouldLoadSerumOpenOrders}, default to true */
  shouldLoadSerumOpenOrders?: boolean;
};

export type OnTransaction = (
  txid: TransactionSignature,
  totalTxs: number,
  txDescription: IConfirmationTxDescription,
  awaiter: Promise<TransactionResponse | TransactionError | null>,
) => void;

export type IConfirmationTxDescription = 'SETUP' | 'SWAP' | 'CLEANUP';
type ExecuteParams = {
  wallet?: Pick<SignerWalletAdapter, 'sendTransaction' | 'signAllTransactions' | 'signTransaction'>;
  /**
   * Allows to customize control of sending and awaiting confirmation in the single/multi transaction flow
   */
  onTransaction?: OnTransaction;
};

export class Jupiter {
  /* promise because we can choose not to await it when we dont need it */
  private serumOpenOrdersPromise: Promise<SerumOpenOrdersMap> | undefined = undefined;
  private user: Keypair | PublicKey | undefined;
  private routeCache = new Map<InputMintAndOutputMint, { fetchTimestamp: number }>();

  constructor(
    private connection: Connection,
    private cluster: Cluster,
    public tokenRouteSegments: TokenRouteSegments,
    private feeCalculator: FeeCalculator,
    private platformFeeAndAccounts: PlatformFeeAndAccounts,
    /** Referrer account to collect Serum referrer fees for each given quote mint, the referrer fee is 20% of the Serum protocol fee */
    private quoteMintToReferrer: QuoteMintToReferrer,
    /**
     * -1, it will not fetch when shouldFetch == false
     * 0, it will fetch everytime
     * A duration in ms, the time interval between AMM accounts refetch, recommendation for a UI 20 seconds,
     */
    private routeCacheDuration: number = 0,
    /** When set to true (default) native SOL is wrapped and wSOL unwrapped in each swap, otherwise it assumes wSOL is funded when it exists */
    private wrapUnwrapSOL: boolean = true,
    /** A token ledger which can be used to track volume as it can be made unique per platform, also alleviates write locks on a single token ledger account */
    private tokenLedger: PublicKey,
    private intermediateTokens: TokenMintAddress[] | undefined,
    /** Perform a getProgramAccounts on user's serum open orders. Recomended to turn off if RPC is slow to perform a gPA */
    private shouldLoadSerumOpenOrders: boolean,
  ) {}

  /**
   * load performs the necessary async scaffolding of the Jupiter object
   */
  static async load({
    connection,
    cluster,
    user,
    platformFeeAndAccounts = NO_PLATFORM_FEE,
    quoteMintToReferrer,
    routeCacheDuration = 0,
    wrapUnwrapSOL = true,
    // @internal,
    marketUrl,
    restrictIntermediateTokens = false,
    tokenLedger = TOKEN_LEDGER,
    shouldLoadSerumOpenOrders = true,
  }: JupiterLoadParams) {
    const [
      tokenRouteSegments,
      {
        value: { feeCalculator },
      },
      _quoteMintToReferrer,
      intermediateTokens,
    ] = await Promise.all([
      Jupiter.fetchTokenRouteSegments(connection, cluster, marketUrl),
      connection.getRecentBlockhashAndContext('processed'),
      quoteMintToReferrer ?? getPlatformFeeAccounts(connection, new PublicKey(JUPITER_WALLET)),
      restrictIntermediateTokens ? Jupiter.getIntermediateTokens() : undefined,
    ]);

    const jupiter = new Jupiter(
      connection,
      cluster,
      tokenRouteSegments,
      feeCalculator,
      platformFeeAndAccounts,
      _quoteMintToReferrer,
      routeCacheDuration,
      wrapUnwrapSOL,
      tokenLedger,
      intermediateTokens,
      shouldLoadSerumOpenOrders,
    );
    if (user) jupiter.setUserPublicKey(user);
    return jupiter;
  }

  getAccountToAmmMap() {
    const accountToAmmMap = new Map<string, Amm>();
    this.tokenRouteSegments.forEach((tokenRouteSegment) => {
      Array.from(tokenRouteSegment.values()).forEach((marketInfos) => {
        marketInfos.forEach((amm) => {
          amm.getAccountsForUpdate().forEach((account) => {
            accountToAmmMap.set(account.toBase58(), amm);
          });
        });
      });
    });
    return accountToAmmMap;
  }

  getAmmIdToAmmMap() {
    const ammIdToAmmMap = new Map<string, Amm>();

    this.tokenRouteSegments.forEach((tokenRouteSegment) => {
      Array.from(tokenRouteSegment.values()).forEach((marketInfos) => {
        marketInfos.forEach((amm) => {
          ammIdToAmmMap.set(amm.id, amm);
        });
      });
    });

    return ammIdToAmmMap;
  }

  public getDepositAndFees = async ({
    marketInfos,
    userPublicKey,
    /**
     * We can use Jupiter.findSerumOpenOrdersForOwner for this, if we want to reuse existing user serum open orders.
     */
    serumOpenOrdersPromise = Promise.resolve(new Map()),
  }: {
    marketInfos: MarketInfo[];
    userPublicKey: PublicKey;
    serumOpenOrdersPromise?: Promise<SerumOpenOrdersMap>;
  }) => {
    return getDepositAndFeeFromInstructions({
      connection: this.connection,
      feeCalculator: this.feeCalculator,
      inputMint: marketInfos[0].inputMint,
      marketInfos,
      serumOpenOrdersPromise,
      owner: new Owner(userPublicKey),
      wrapUnwrapSOL: this.wrapUnwrapSOL,
    });
  };

  private getDepositAndFeesForUser = ({ marketInfos }: { marketInfos: MarketInfo[] }) => {
    if (this.user && this.serumOpenOrdersPromise) {
      const user = new Owner(this.user);

      return this.getDepositAndFees({
        marketInfos,
        userPublicKey: user.publicKey,
        serumOpenOrdersPromise: this.serumOpenOrdersPromise,
      });
    }
    return Promise.resolve(undefined);
  };

  async computeRoutes({
    inputMint,
    outputMint,
    amount,
    slippage,
    feeBps = 0,
    forceFetch,
    onlyDirectRoutes,
    swapMode = SwapMode.ExactIn,
    filterTopNResult,
  }: {
    inputMint: PublicKey;
    outputMint: PublicKey;
    amount: JSBI;
    slippage: number;
    feeBps?: number;
    forceFetch?: boolean;
    onlyDirectRoutes?: boolean;
    swapMode?: SwapMode;
    /**
     * filter how many top individual route to be used to compared
     */
    filterTopNResult?: number;
  }) {
    const inputMintString = inputMint.toBase58();
    const outputMintString = outputMint.toBase58();

    // Platform fee can only be applied when fee account exists
    const platformFeeBps =
      feeBps ||
      (this.platformFeeAndAccounts.feeAccounts.get(outputMintString) ? this.platformFeeAndAccounts.feeBps : 0);

    const now = new Date().getTime();

    // do sort so that it's always the same order for the same inputMint and outputMint and vice versa
    const inputMintAndOutputMint = [inputMintString, outputMintString].sort((a, b) => a.localeCompare(b)).join('');

    const routeCache = this.routeCache.get(inputMintAndOutputMint);

    const inputRouteSegment = computeInputRouteSegments({
      inputMint: inputMintString,
      outputMint: outputMintString,
      tokenRouteSegments: this.tokenRouteSegments,
      intermediateTokens: this.intermediateTokens,
      onlyDirectRoutes,
      swapMode,
    });

    let shouldBustCache = false;
    // special -1 condition to not fetch
    if (this.routeCacheDuration === -1) {
      shouldBustCache = false;
    } else if (this.routeCacheDuration === 0) {
      shouldBustCache = true;
    } else {
      if (routeCache) {
        const { fetchTimestamp } = routeCache;
        if (now - fetchTimestamp > this.routeCacheDuration) {
          shouldBustCache = true;
        }
      } else {
        shouldBustCache = true;
      }
    }

    if (forceFetch || shouldBustCache) {
      await fetchAccountInfos(this.connection, inputRouteSegment);
      this.routeCache.set(inputMintAndOutputMint, {
        fetchTimestamp: new Date().getTime(),
      });
    }

    try {
      const routesInfos = processInputRouteSegmentToRoutesInfos({
        inputRouteSegment,
        inputMint,
        outputMint,
        amount,
        getDepositAndFeeForRoute: this.getDepositAndFeesForUser,
        onlyDirectRoutes,
        slippage,
        platformFeeBps,
        filterTopNResult,
        swapMode,
      });

      return {
        routesInfos,
        /* indicate if the result is fetched or get from cache */
        cached: !(forceFetch || shouldBustCache),
      };
    } catch (e) {
      throw e;
    } finally {
      // clear cache if it is expired
      this.routeCache.forEach(({ fetchTimestamp }, key) => {
        if (fetchTimestamp - now > this.routeCacheDuration) {
          this.routeCache.delete(key);
        }
      });
    }
  }

  setUserPublicKey(userPublicKey: Keypair | PublicKey) {
    this.user = userPublicKey;
    const owner = new Owner(this.user);
    this.serumOpenOrdersPromise = this.shouldLoadSerumOpenOrders
      ? Jupiter.findSerumOpenOrdersForOwner({
          connection: this.connection,
          cluster: this.cluster,
          userPublicKey: owner.publicKey,
        })
      : Promise.resolve(new Map());
  }

  /**
   * The token route segments contains all the routes and the market meta information.
   */
  static async fetchTokenRouteSegments(connection: Connection, cluster: Cluster, marketUrl?: string) {
    const marketCaches = await fetchMarketCache(marketUrl || MARKETS_URL[cluster]);
    const amms = await getAllAmms(connection, marketCaches);

    const tokenRouteSegments = getTokenRouteSegments(amms);

    return tokenRouteSegments;
  }

  /**
   * This generate a routeMap which represents every possible output token mint for a given input token mint.
   * For example, we have SOL to USDC and this pairs have many routings like
   * SOL => USDT
   * USDT => USDC
   * SOL => USDC
   *
   * From here we know that we can have 2 different routing of SOL => USDC.
   * We do single level routing map but for all coins which result in the route map below:
   * SOL => USDT, USDC
   * USDT => SOL
   * USDC => SOL, USDT
   *
   * From this route map we can map out all possible route from one to another by checking the intersection.
   */
  getRouteMap(onlyDirectRoutes?: boolean) {
    return computeRouteMap(this.tokenRouteSegments, this.intermediateTokens, onlyDirectRoutes);
  }

  static async getRemoteRouteMap(
    {
      onlyDirectRoutes,
      restrictIntermediateTokens,
    }: { onlyDirectRoutes?: boolean; restrictIntermediateTokens?: boolean },
    indexedRouteMapUrl?: string,
  ) {
    let url = indexedRouteMapUrl || INDEXED_ROUTE_MAP_URL;
    if (onlyDirectRoutes) {
      url = url.concat('?onlyDirectRoutes=true');
    } else if (restrictIntermediateTokens) {
      url = url.concat('?restrictIntermediateTokens=true');
    }

    const indexedRouteMap = (await (await fetch(url)).json()) as IndexedRouteMap;

    return indexedRouteMapToRouteMap(indexedRouteMap);
  }

  /**
   * Query existing open order account, this query is slow.
   * We suggest to fetch this in the background.
   */
  static findSerumOpenOrdersForOwner = async ({
    userPublicKey,
    cluster,
    connection,
  }: {
    userPublicKey: PublicKey;
    cluster: Cluster;
    connection: Connection;
  }) => {
    const newMarketToOpenOrdersAddress: SerumOpenOrdersMap = new Map();

    if (userPublicKey) {
      const programId = cluster === 'mainnet-beta' ? MAINNET_SERUM_DEX_PROGRAM : DEVNET_SERUM_DEX_PROGRAM;

      const allOpenOrders = await OpenOrders.findForOwner(connection, userPublicKey, programId);

      allOpenOrders.forEach((openOrders) => {
        newMarketToOpenOrdersAddress.set(openOrders.market.toString(), openOrders.address);
      });
    }
    return newMarketToOpenOrdersAddress;
  };

  public exchange: (params: {
    routeInfo: RouteInfo;
    /**
     * This will overwrite the default Jupiter.setUser, useful for stateless usage like API
     */
    userPublicKey?: PublicKey;
    /**
     * This will overwrite the default token ledger, useful for stateless usage like API
     */
    tokenLedger?: PublicKey;
    /**
     * This will overwrite the default fee account, useful for stateless usage like API
     */
    feeAccount?: PublicKey;
    /**
     * This will overwrite the default wrapUnwrapSOL, useful for stateless usage like API
     */
    wrapUnwrapSOL?: boolean;
    /**
     * The transaction will use the blockhash and valid blockheight to create transaction
     */
    blockhashWithExpiryBlockHeight?: BlockhashWithExpiryBlockHeight;
  }) => Promise<{
    transactions: {
      setupTransaction?: Transaction;
      swapTransaction: Transaction;
      cleanupTransaction?: Transaction;
    };
    execute: (params?: ExecuteParams) => Promise<SwapResult>;
  }> = async ({ routeInfo, userPublicKey, feeAccount, wrapUnwrapSOL, tokenLedger, blockhashWithExpiryBlockHeight }) => {
    const { connection, serumOpenOrdersPromise } = this;
    const user: PublicKey | Keypair | undefined = userPublicKey || this.user;
    if (!user) {
      throw new Error('user not found');
    }

    const owner = new Owner(user);

    const lastMarketInfoIndex = routeInfo.marketInfos.length - 1;
    const inputMint = routeInfo.marketInfos[0].inputMint;
    const outputMint = routeInfo.marketInfos[lastMarketInfoIndex].outputMint;
    const _wrapUnwrapSOL = wrapUnwrapSOL ?? this.wrapUnwrapSOL;

    const [sourceInstruction, ataInstructions, openOrdersInstructions] = await Promise.all([
      inputMint.equals(WRAPPED_SOL_MINT) && _wrapUnwrapSOL
        ? createAndCloseWSOLAccount({
            connection,
            owner,
            amount: routeInfo.swapMode === SwapMode.ExactIn ? routeInfo.amount : routeInfo.otherAmountThreshold,
          })
        : Token.getAssociatedTokenAddress(
            ASSOCIATED_TOKEN_PROGRAM_ID,
            TOKEN_PROGRAM_ID,
            inputMint,
            owner.publicKey,
            true,
          ).then((address) => ({
            ...getEmptyInstruction(),
            address,
          })),
      routeAtaInstructions({ connection, marketInfos: routeInfo.marketInfos, owner, unwrapSOL: _wrapUnwrapSOL }),
      Promise.all(
        routeInfo.marketInfos.map(async ({ amm }) => {
          if (amm instanceof SerumAmm || amm instanceof SplitTradeAmm) {
            if (!amm.market) return;
            return await getOrCreateOpenOrdersAddress(
              connection,
              owner.publicKey,
              amm.market,
              await serumOpenOrdersPromise,
            );
          }
          return;
        }),
      ),
    ]);

    const instructions = {
      intermediate: ataInstructions.userIntermediaryTokenAccountResult,
      destination: ataInstructions.userDestinationTokenAccountResult,
      openOrders: openOrdersInstructions,
    };

    const hasOpenOrders = instructions.openOrders.filter(Boolean).length > 0;

    // Construct platform fee
    feeAccount = feeAccount || this.platformFeeAndAccounts.feeAccounts.get(outputMint.toBase58());

    const platformFee = feeAccount
      ? {
          feeBps:
            this.platformFeeAndAccounts.feeBps ||
            Math.floor(routeInfo.marketInfos[lastMarketInfoIndex].platformFee.pct * 100),
          feeAccount,
        }
      : undefined;

    const preparedInstructions = await routeToInstructions({
      user: owner,
      tokenLedger: tokenLedger || this.tokenLedger,
      openOrdersAddresses: instructions.openOrders.map((oo) => oo?.address),
      userSourceTokenAccountAddress: sourceInstruction.address,
      userIntermediaryTokenAccountAddress: instructions.intermediate?.address,
      userDestinationTokenAccountAddress: instructions.destination.address,
      routeInfo,
      platformFee,
      quoteMintToReferrer: this.quoteMintToReferrer,
    });

    const { needCleanup, needSetup } = isSplitSetupRequired(routeInfo.marketInfos, {
      hasSerumOpenOrderInstruction: hasOpenOrders,
    });

    const setupTransactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    const transactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    const cleanupTransactionBuilder = new TransactionBuilder(connection, owner.publicKey, owner);

    const ixs = [
      instructions.intermediate,
      sourceInstruction,
      // if source address the same as destination address, then we don't need to setup or cleanup twice, mainly SOL-SOL
      !instructions.destination.address.equals(sourceInstruction.address) && instructions.destination,
    ];

    // has more than 1 ata to setup, we split it into setup and cleanup
    if (needSetup || ixs.map((ix) => typeof ix === 'object' && ix.instructions.length).filter(Boolean).length > 1) {
      if (hasOpenOrders) {
        instructions.openOrders.forEach((openOrders) => {
          if (openOrders) {
            setupTransactionBuilder.addInstruction(openOrders);
          }
        });
      }

      ixs.forEach((instruction) => {
        if (instruction) {
          // we cannot put cleanup here because we cannot do cleanup in setupTransaction
          setupTransactionBuilder.addInstruction({
            ...instruction,
            cleanupInstructions: [],
          });

          if (instruction.cleanupInstructions.length) {
            const cleanupIx = {
              ...getEmptyInstruction(),
              cleanupInstructions: instruction.cleanupInstructions,
            };
            if (needCleanup) {
              cleanupTransactionBuilder.addInstruction(cleanupIx);
            } else {
              transactionBuilder.addInstruction(cleanupIx);
            }
          }
        }
      });
    } else {
      if (hasOpenOrders) {
        instructions.openOrders.forEach((openOrders) => {
          if (openOrders) {
            transactionBuilder.addInstruction(openOrders);
          }
        });
      }

      ixs.forEach((instruction) => {
        if (instruction) {
          transactionBuilder.addInstruction(instruction);
        }
      });
    }

    transactionBuilder.addInstruction(preparedInstructions);

    blockhashWithExpiryBlockHeight =
      blockhashWithExpiryBlockHeight || (await this.connection.getLatestBlockhash('confirmed'));

    const { transaction: setupTransaction } = await setupTransactionBuilder.build(blockhashWithExpiryBlockHeight);

    const { transaction } = await transactionBuilder.build(blockhashWithExpiryBlockHeight);

    const { transaction: cleanupTransaction } = await cleanupTransactionBuilder.build(blockhashWithExpiryBlockHeight);

    const [setupTransactionObject, swapTransactionObject, cleanupTransactionObject] = [
      setupTransaction.instructions.length ? setupTransaction : undefined,
      transaction,
      cleanupTransaction.instructions.length ? cleanupTransaction : undefined,
    ];

    const setupInstructions = instructions;
    return {
      transactions: {
        setupTransaction: setupTransactionObject,
        swapTransaction: swapTransactionObject,
        cleanupTransaction: cleanupTransactionObject,
      },
      execute: ({ wallet, onTransaction }: ExecuteParams = {}) =>
        this.executeInternal({
          wallet,
          onTransaction,
          inputMint,
          outputMint,
          sourceInstruction,
          setupInstructions,
          setupTransaction: setupTransactionObject,
          swapTransaction: swapTransactionObject,
          cleanupTransaction: cleanupTransactionObject,
          wrapUnwrapSOL: _wrapUnwrapSOL,
          owner,
        }),
    };
  };

  /** sign, send and await confirmation for an exchange */
  private async executeInternal({
    wallet,
    onTransaction,
    inputMint,
    outputMint,
    sourceInstruction,
    setupInstructions,
    setupTransaction,
    swapTransaction,
    cleanupTransaction,
    owner,
    wrapUnwrapSOL,
  }: {
    wallet: ExecuteParams['wallet'];
    onTransaction?: OnTransaction;
    inputMint: PublicKey;
    outputMint: PublicKey;
    sourceInstruction: Instruction & { address: PublicKey };
    setupInstructions: SetupInstructions;
    setupTransaction?: Transaction;
    swapTransaction: Transaction;
    cleanupTransaction?: Transaction;
    wrapUnwrapSOL?: boolean;
    owner: Owner;
  }): Promise<SwapResult> {
    let swapError: TransactionError | undefined = undefined;
    let swapResult: SwapResult | undefined = undefined;

    try {
      const transactions = [setupTransaction, swapTransaction, cleanupTransaction].filter(
        (tx): tx is Transaction => tx !== undefined,
      );

      const totalTxs = transactions.length;

      if (owner.signer) {
        const signer = owner.signer;
        transactions.forEach((transaction) => {
          transaction.sign(signer);
        });
      } else {
        if (!wallet) {
          throw new Error('Signer wallet not found');
        }
        if (totalTxs > 1) {
          const signedTransactions = await wallet.signAllTransactions(transactions);
          let i = 0;
          [setupTransaction, swapTransaction, cleanupTransaction] = [
            setupTransaction ? signedTransactions[i++] : undefined,
            signedTransactions[i++],
            cleanupTransaction ? signedTransactions[i++] : undefined,
          ];
        } else {
          swapTransaction = await wallet.signTransaction(swapTransaction);
        }
      }

      if (setupTransaction) {
        let setupTxid = getSignature(setupTransaction);
        const setupTransactionSender = async () => {
          return await validateTransactionResponse(
            await transactionSenderAndConfirmationWaiter(this.connection, setupTransaction!),
          );
        };
        const setupPromise = setupTransactionSender();
        onTransaction?.(setupTxid, totalTxs, 'SETUP', setupPromise);
        const setupResult = await setupPromise;
        if (setupResult instanceof Error) {
          throw swapResult;
        }
      }

      const swapTxid = getSignature(swapTransaction);

      try {
        const swapTransactionSender = async () => {
          return await validateTransactionResponse(
            await transactionSenderAndConfirmationWaiter(this.connection, swapTransaction),
          );
        };
        const swapPromise = swapTransactionSender();
        onTransaction?.(swapTxid, totalTxs, 'SWAP', swapPromise);
        const transactionResponse = await swapPromise;

        if (transactionResponse instanceof Error) {
          throw transactionResponse;
        }

        const [sourceTokenBalanceChange, destinationTokenBalanceChange] = getTokenBalanceChangesFromTransactionResponse(
          {
            txid: swapTxid,
            inputMint,
            outputMint,
            user: owner.publicKey,
            sourceAddress: sourceInstruction.address,
            destinationAddress: setupInstructions.destination.address,
            transactionResponse,
            hasWrappedSOL: Boolean(cleanupTransaction) || !wrapUnwrapSOL,
          },
        );

        swapResult = {
          txid: swapTxid,
          inputAddress: sourceInstruction.address,
          outputAddress: setupInstructions.destination.address,
          inputAmount: sourceTokenBalanceChange,
          outputAmount: destinationTokenBalanceChange,
        };
      } catch (e: any) {
        swapError = e;
      } finally {
        if (cleanupTransaction) {
          const cleanupTxid = getSignature(cleanupTransaction);
          const cleanupTransactionSender = async () => {
            return validateTransactionResponse(
              await transactionSenderAndConfirmationWaiter(this.connection, cleanupTransaction!),
            );
          };

          const cleanupPromise = cleanupTransactionSender();
          onTransaction?.(cleanupTxid, totalTxs, 'CLEANUP', cleanupPromise);
          await cleanupPromise;
        }
      }

      if (swapError || !swapResult) {
        throw swapError || new Error('Swap failed');
      }

      // return must be after `finally` clause to ensure we wait what we done in the `finally`
      return swapResult;
    } catch (error) {
      return { error: error as TransactionError };
    } finally {
      this.routeCache.clear();
    }
  }

  static async getIntermediateTokens() {
    const intermediateTokensSet = await getTopTokens();
    for (const swapProtocolToken of SWAP_PROTOCOL_TOKENS) {
      intermediateTokensSet.add(swapProtocolToken);
    }
    const saberDecimalAmms = getSaberWrappedDecimalsAmms();

    saberDecimalAmms.forEach((item) => {
      intermediateTokensSet.add(item.wrappedToken.addDecimals.mint.toBase58());
    });

    return Array.from(intermediateTokensSet);
  }

  static createInitializeTokenLedgerInstruction = createInitializeTokenLedgerInstruction;
}
