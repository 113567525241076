import React, { useEffect, useState, useMemo, useCallback, useRef, createContext, useContext } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { Errors } from './error';
import useDebounce from './utils/useDebounce';
import {
  RouteInfo,
  Jupiter,
  SwapResult,
  TOKEN_LIST_URL,
  MARKETS_URL,
  JUPITER_ERRORS,
  JupiterLoadParams,
  SwapMode,
  INDEXED_ROUTE_MAP_URL,
} from '@jup-ag/core';
import JSBI from 'jsbi';

export type JupiterError = typeof Errors[keyof typeof Errors];

interface UseJupiterResult {
  /** routes that are possible, sorted decending based on outAmount */
  routes?: RouteInfo[];
  /** exchange function to submit transaction */
  exchange: (
    params: Parameters<Jupiter['exchange']>[0] & Parameters<Awaited<ReturnType<Jupiter['exchange']>>['execute']>[0],
  ) => Promise<SwapResult>;
  /** refresh function to refetch the prices */
  refresh: () => void;
  /** last refresh timestamp */
  lastRefreshTimestamp: number;
  /** all possible token mints to be chosen from */
  allTokenMints: string[];
  /** route map input mint with output mints */
  routeMap: Map<string, string[]>;
  /** loading state */
  loading: boolean;
  error: JupiterError | undefined;
}

interface JupiterProps extends Omit<JupiterLoadParams, 'user'> {
  onlyDirectRoutes?: boolean;
  userPublicKey?: PublicKey;
  routeCacheDuration?: number;
  children?: React.ReactNode;
}

const JupiterContext = createContext<
  | (Pick<UseJupiterResult, 'allTokenMints' | 'routeMap'> & {
      connection: Connection;
      cluster: string;
      jupiter: Jupiter | undefined;
      error: JupiterError | undefined;
      setError: (error?: JupiterError) => void;
      routeCacheDuration?: number;
      onlyDirectRoutes?: boolean;
    })
  | null
>(null);

export const JupiterProvider: React.FC<JupiterProps> = ({
  onlyDirectRoutes,
  userPublicKey,
  children,
  ...jupiterLoadProps
}) => {
  const [jupiter, setJupiter] = useState<Jupiter>();
  const [routeMap, setRouteMap] = useState(new Map<string, string[]>());
  const [error, setError] = useState<JupiterError>();

  useEffect(() => {
    (async () => {
      try {
        setError(undefined);
        const _jupiter = await Jupiter.load({
          ...jupiterLoadProps,
        });

        setJupiter(_jupiter);
      } catch (e) {
        console.error(e);
        setError(Errors.INITIALIZE_ERROR);
        throw e;
      }
    })();
  }, Object.values(jupiterLoadProps));

  useEffect(() => {
    if (jupiter && userPublicKey) {
      jupiter.setUserPublicKey(userPublicKey);
    }
  }, [jupiter, userPublicKey]);

  useEffect(() => {
    async function update() {
      let routeMap = new Map<string, string[]>();

      // so that we follow the marketUrl host name and get preprod and prod
      let _url = new URL(INDEXED_ROUTE_MAP_URL);
      if (jupiterLoadProps.marketUrl) {
        _url.hostname = new URL(jupiterLoadProps.marketUrl).hostname;
      }

      let url = _url.toString();

      routeMap = await Jupiter.getRemoteRouteMap(
        { onlyDirectRoutes, restrictIntermediateTokens: jupiterLoadProps.restrictIntermediateTokens },
        url,
      );

      setRouteMap(routeMap);
    }
    update();
  }, [jupiterLoadProps.restrictIntermediateTokens, onlyDirectRoutes]);

  const allTokenMints = useMemo(() => {
    return Array.from(routeMap.keys());
  }, [routeMap]);

  return (
    <JupiterContext.Provider
      value={{
        jupiter,
        allTokenMints,
        connection: jupiterLoadProps.connection,
        cluster: jupiterLoadProps.cluster,
        routeCacheDuration: jupiterLoadProps.routeCacheDuration,
        routeMap,
        error,
        setError,
        onlyDirectRoutes,
      }}
    >
      {children}
    </JupiterContext.Provider>
  );
};

interface UseJupiterProps {
  amount: JSBI;
  inputMint: PublicKey | undefined;
  outputMint: PublicKey | undefined;
  slippage: number;
  /* inputAmount is being debounced, debounceTime 0 to disable debounce */
  debounceTime?: number;
  swapMode?: SwapMode;
}

export const useJupiterRouteMap = () => {
  const context = useContext(JupiterContext);
  if (!context) {
    throw new Error('JupiterProvider is required');
  }
  return context.routeMap;
};

export const useJupiter = ({
  amount,
  inputMint,
  outputMint,
  slippage,
  debounceTime = 250,
  swapMode = SwapMode.ExactIn,
}: UseJupiterProps): UseJupiterResult => {
  const context = useContext(JupiterContext);
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState<RouteInfo[]>();
  const [refreshCount, setRefreshCount] = useState<number>(0);
  // lastRefreshCount indicate when the last refresh was triggered on which refreshCount
  const lastRefreshCount = useRef<number>(refreshCount);

  const [debouncedAmount, debouncedInputMint, debouncedOutputMint] = useDebounce(
    React.useMemo(
      () => [amount, inputMint, outputMint],
      [amount.toString(), inputMint?.toBase58(), outputMint?.toBase58()],
    ),
    debounceTime,
  );

  const lastRefreshTimestamp = useRef<number>(0);
  const lastQueryTimestamp = useRef<number>(0);

  if (!context) {
    throw new Error('JupiterProvider is required');
  }

  const { routeMap, allTokenMints, jupiter, error, setError, routeCacheDuration = 0, onlyDirectRoutes } = context;

  // lastRefreshCount to determine when the last refresh was triggered, reset this to -1 to trigger a re-fetch
  useEffect(() => {
    lastRefreshCount.current = -1;
  }, [[debouncedInputMint?.toString(), debouncedOutputMint?.toString()].sort().join('-'), slippage]);

  useEffect(() => {
    // if now - lastRefreshTimestamp > routeCacheDuration, then we need to refresh
    if (lastRefreshTimestamp.current && new Date().getTime() - lastRefreshTimestamp.current >= routeCacheDuration) {
      lastRefreshCount.current = -1;
    }

    if (JSBI.greaterThan(debouncedAmount, JSBI.BigInt(0)) && refreshCount !== lastRefreshCount.current) {
      // don't set loading if there is no input amount
      setLoading(true);
    }
  }, [refreshCount, debouncedAmount, slippage, debouncedInputMint, debouncedOutputMint, onlyDirectRoutes]);

  useEffect(() => {
    if (!jupiter) {
      return;
    }

    if (JSBI.equal(debouncedAmount, JSBI.BigInt(0)) || error === Errors.INITIALIZE_ERROR) {
      setRoutes(undefined);
    } else if (debouncedAmount) {
      if (!debouncedInputMint || !debouncedOutputMint || !routeMap) return;
      let lastUpdatedTime = new Date().getTime();
      lastQueryTimestamp.current = lastUpdatedTime;

      jupiter
        .computeRoutes({
          inputMint: debouncedInputMint,
          outputMint: debouncedOutputMint,
          amount: debouncedAmount,
          slippage,
          forceFetch: refreshCount !== lastRefreshCount.current,
          onlyDirectRoutes,
          swapMode,
        })
        .then(({ routesInfos, cached }) => {
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          setRoutes(routesInfos);
          setError(undefined);

          if (!cached) {
            lastRefreshTimestamp.current = new Date().getTime();
          }
        })
        .catch((e) => {
          console.error(e);
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          // Clear routes when erring to avoid bad pricing
          setRoutes(undefined);
          setError(Errors.ROUTES_ERROR);
        })
        .finally(() => {
          if (lastQueryTimestamp.current !== lastUpdatedTime) {
            return;
          }
          lastRefreshCount.current = refreshCount;
          setLoading(false);
        });
    }
  }, [jupiter, debouncedAmount, debouncedInputMint, debouncedOutputMint, slippage, refreshCount, onlyDirectRoutes]);

  const exchange: UseJupiterResult['exchange'] = useCallback(
    async ({ wallet, routeInfo, onTransaction, ...restExchangeProps }): Promise<SwapResult> => {
      if (error) {
        throw new Error(error);
      }

      if (!jupiter) {
        throw new Error('Jupiter not initialized');
      }

      if (!routeInfo) {
        throw new Error('Invalid state, impossible to build transaction');
      }

      const { execute } = await jupiter.exchange({ routeInfo, ...restExchangeProps });

      const result = await execute({ wallet, onTransaction });

      return result;
    },
    [jupiter],
  );

  return {
    allTokenMints,
    routeMap,
    exchange,
    refresh: () => {
      if (!loading && lastRefreshTimestamp.current) {
        setRefreshCount((refreshCount) => refreshCount + 1);
      }
    },
    lastRefreshTimestamp: lastRefreshTimestamp.current,
    loading,
    routes,
    error,
  };
};

export { TOKEN_LIST_URL, JUPITER_ERRORS, MARKETS_URL, Errors };
export * from '@jup-ag/core';
