import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';

const SOL_MINT = 'So11111111111111111111111111111111111111112';

async function getWSolATA(connection: Connection, publicKey: PublicKey) {
  const wSolAddress = await Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    new PublicKey(SOL_MINT),
    publicKey,
  );
  const accountInfo = await connection.getAccountInfo(wSolAddress);
  return { address: wSolAddress, accountInfo };
}

async function createAndTransferWSOLInstructions(
  connection: Connection,
  publicKey: PublicKey,
  amountToTransferInLamports: number,
) {
  const wSolAccount = await getWSolATA(connection, publicKey);
  const instructions = [];

  // If no accountInfo, create one
  if (!wSolAccount.accountInfo) {
    // Create ATA account
    instructions.push(
      Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        new PublicKey(SOL_MINT),
        wSolAccount.address,
        publicKey,
        publicKey,
      ),
    );
  }

  // Fund account and sync
  instructions.push(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: wSolAccount.address,
      lamports: amountToTransferInLamports,
    }),
  );
  instructions.push(
    // This is not exposed by the types, but indeed it exists
    (Token as any).createSyncNativeInstruction(TOKEN_PROGRAM_ID, wSolAccount.address),
  );

  const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
  const transaction = new Transaction({ feePayer: publicKey, blockhash, lastValidBlockHeight });
  transaction.add(...instructions);
  return transaction;
}

async function createUnwrapSolInstructions(connection: Connection, publicKey: PublicKey) {
  const wSolAccount = await getWSolATA(connection, publicKey);
  const instructions = [];

  if (!wSolAccount.accountInfo) {
    return;
  }
  // Close account
  instructions.push(
    Token.createCloseAccountInstruction(TOKEN_PROGRAM_ID, wSolAccount.address, publicKey, publicKey, []),
  );

  const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
  const transaction = new Transaction({ feePayer: publicKey, blockhash, lastValidBlockHeight });
  transaction.add(...instructions);
  return transaction;
}

export { createAndTransferWSOLInstructions, createUnwrapSolInstructions };
