var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY, Transaction, TransactionInstruction } from '@solana/web3.js';
import { Metadata } from '@metaplex-foundation/mpl-token-metadata';
import { decodeProfilePictureAccount, generateUrl, getMetadataFromUrl, getProfilePicturePDA } from './utils';
import { Buffer } from 'buffer';
export * from './types';
export var PROFILE_PICTURE_PROGRAM = new PublicKey('6UQLqKYWqErHqdsX6WtANQsMmvfKtWNuSSRj6ybg5in3');
var DEFAULT_CONFIG = {
    fallback: true,
    resize: {
        width: 100
    }
};
export function getProfilePicture(connection, publicKey, config) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return __awaiter(this, void 0, void 0, function () {
        var finalConfig, profilePictureAccountPublicKey, profilePictureAccount, profilePictureData, tokenAccount, nftMetadataAccountPublicKey, nftMetadata, metadata, err_1;
        return __generator(this, function (_t) {
            switch (_t.label) {
                case 0:
                    finalConfig = __assign(__assign({}, DEFAULT_CONFIG), config);
                    _t.label = 1;
                case 1:
                    _t.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, getProfilePicturePDA(publicKey)];
                case 2:
                    profilePictureAccountPublicKey = _t.sent();
                    return [4 /*yield*/, connection.getAccountInfo(profilePictureAccountPublicKey)];
                case 3:
                    profilePictureAccount = _t.sent();
                    if (!profilePictureAccount) {
                        throw new Error('PDA is empty');
                    }
                    profilePictureData = decodeProfilePictureAccount(profilePictureAccount);
                    return [4 /*yield*/, connection.getParsedAccountInfo(profilePictureData.nftTokenAccount)];
                case 4:
                    tokenAccount = _t.sent();
                    if (!tokenAccount) {
                        throw new Error('No token account');
                    }
                    // @ts-ignore
                    if (((_e = (_d = (_c = (_b = (_a = tokenAccount === null || tokenAccount === void 0 ? void 0 : tokenAccount.value) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.parsed) === null || _c === void 0 ? void 0 : _c.info) === null || _d === void 0 ? void 0 : _d.tokenAmount) === null || _e === void 0 ? void 0 : _e.uiAmount) < 1) {
                        throw new Error('No NFT token in the token account');
                    }
                    // @ts-ignore
                    if (((_j = (_h = (_g = (_f = tokenAccount === null || tokenAccount === void 0 ? void 0 : tokenAccount.value) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.parsed) === null || _h === void 0 ? void 0 : _h.info) === null || _j === void 0 ? void 0 : _j.owner) !== publicKey.toString()) {
                        throw new Error('Invalid token account owner');
                    }
                    // @ts-ignore
                    if (((_o = (_m = (_l = (_k = tokenAccount === null || tokenAccount === void 0 ? void 0 : tokenAccount.value) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.parsed) === null || _m === void 0 ? void 0 : _m.info) === null || _o === void 0 ? void 0 : _o.mint) !== profilePictureData.nftMint.toString()) {
                        throw new Error('Invalid token account mint');
                    }
                    return [4 /*yield*/, Metadata.getPDA(profilePictureData.nftMint)];
                case 5:
                    nftMetadataAccountPublicKey = _t.sent();
                    return [4 /*yield*/, Metadata.load(connection, nftMetadataAccountPublicKey)];
                case 6:
                    nftMetadata = _t.sent();
                    if (!((_q = (_p = nftMetadata === null || nftMetadata === void 0 ? void 0 : nftMetadata.data) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.uri)) {
                        throw new Error('No metadata URL');
                    }
                    return [4 /*yield*/, getMetadataFromUrl(nftMetadata.data.data.uri)];
                case 7:
                    metadata = _t.sent();
                    if (!metadata) {
                        throw new Error('No metadata');
                    }
                    return [2 /*return*/, {
                            isAvailable: true,
                            url: generateUrl((metadata === null || metadata === void 0 ? void 0 : metadata.image) || null, publicKey, finalConfig),
                            name: ((_s = (_r = nftMetadata === null || nftMetadata === void 0 ? void 0 : nftMetadata.data) === null || _r === void 0 ? void 0 : _r.data) === null || _s === void 0 ? void 0 : _s.name) || '',
                            metadata: metadata,
                            tokenAccount: profilePictureData.nftTokenAccount,
                            mintAccount: profilePictureData.nftMint
                        }];
                case 8:
                    err_1 = _t.sent();
                    return [2 /*return*/, {
                            isAvailable: false,
                            url: generateUrl(null, publicKey, finalConfig)
                        }];
                case 9: return [2 /*return*/];
            }
        });
    });
}
export function createSetProfilePictureTransaction(ownerPublicKey, mintPublicKey, tokenAccountPublicKey) {
    return __awaiter(this, void 0, void 0, function () {
        var profilePictureAccountPublicKey, nftMetadataAccountPublicKey, transaction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getProfilePicturePDA(ownerPublicKey)];
                case 1:
                    profilePictureAccountPublicKey = _a.sent();
                    return [4 /*yield*/, Metadata.getPDA(mintPublicKey)];
                case 2:
                    nftMetadataAccountPublicKey = _a.sent();
                    transaction = new Transaction();
                    transaction.add(new TransactionInstruction({
                        keys: [
                            { pubkey: ownerPublicKey, isSigner: true, isWritable: false },
                            { pubkey: profilePictureAccountPublicKey, isSigner: false, isWritable: true },
                            { pubkey: mintPublicKey, isSigner: false, isWritable: false },
                            { pubkey: tokenAccountPublicKey, isSigner: false, isWritable: false },
                            { pubkey: nftMetadataAccountPublicKey, isSigner: false, isWritable: false },
                            { pubkey: SYSVAR_CLOCK_PUBKEY, isSigner: false, isWritable: false },
                            { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
                        ],
                        programId: PROFILE_PICTURE_PROGRAM,
                        data: Buffer.from([0])
                    }));
                    return [2 /*return*/, transaction];
            }
        });
    });
}
export function createRemoveProfilePictureTransaction(ownerPublicKey) {
    return __awaiter(this, void 0, void 0, function () {
        var profilePictureAccountPublicKey, transaction;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getProfilePicturePDA(ownerPublicKey)];
                case 1:
                    profilePictureAccountPublicKey = _a.sent();
                    transaction = new Transaction();
                    transaction.add(new TransactionInstruction({
                        keys: [
                            { pubkey: ownerPublicKey, isSigner: true, isWritable: false },
                            { pubkey: profilePictureAccountPublicKey, isSigner: false, isWritable: true }
                        ],
                        programId: PROFILE_PICTURE_PROGRAM,
                        data: Buffer.from([1])
                    }));
                    return [2 /*return*/, transaction];
            }
        });
    });
}
