"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WARNING_TYPES = exports.LATEST_IDL_FILE_VERSION = exports.Constants = void 0;
const web3_js_1 = require("@solana/web3.js");
/**
 * Constants
 */
class Constants {
}
exports.Constants = Constants;
// DEVNET MSP program address: MSPdQo5ZdrPh6rU1LsvUv5nRhAnj1mj6YQEqBUq8YwZ
// MAINNET MSP program address: MSPCUMbLfy2MeT6geLMMzrUkv1Tx88XRApaVRdyxTuu
Constants.FEE_TREASURY = new web3_js_1.PublicKey('3TD6SWY9M1mLY2kZWJNavPLhwXvcRsWdnZLRaMzERJBw');
Constants.TREASURY_SIZE = 300;
Constants.STREAM_SIZE = 500;
Constants.CLIFF_PERCENT_NUMERATOR = 10000;
Constants.CLIFF_PERCENT_DENOMINATOR = 1000000;
Constants.MAX_TX_SIZE = 1200;
// This is an internal convention to identify the intention to use NATIVE sol and not SPL wSOL
Constants.SOL_MINT = new web3_js_1.PublicKey('11111111111111111111111111111111');
Constants.READONLY_PUBKEY = new web3_js_1.PublicKey('3KmMEv7A8R3MMhScQceXBQe69qLmnFfxSM3q8HyzkrSx');
exports.LATEST_IDL_FILE_VERSION = 4;
var WARNING_TYPES;
(function (WARNING_TYPES) {
    WARNING_TYPES[WARNING_TYPES["NO_WARNING"] = 0] = "NO_WARNING";
    WARNING_TYPES[WARNING_TYPES["INVALID_ADDRESS"] = 1] = "INVALID_ADDRESS";
    WARNING_TYPES[WARNING_TYPES["WARNING"] = 2] = "WARNING";
})(WARNING_TYPES = exports.WARNING_TYPES || (exports.WARNING_TYPES = {}));
