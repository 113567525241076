"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeUnit = exports.SubCategory = exports.Category = exports.AllocationType = exports.STREAM_STATUS = exports.TreasuryType = exports.VestingTreasuryActivityAction = exports.MSP_ACTIONS = void 0;
/**
 * MSP Instructions types
 */
var MSP_ACTIONS;
(function (MSP_ACTIONS) {
    MSP_ACTIONS[MSP_ACTIONS["scheduleOneTimePayment"] = 1] = "scheduleOneTimePayment";
    MSP_ACTIONS[MSP_ACTIONS["createStream"] = 2] = "createStream";
    MSP_ACTIONS[MSP_ACTIONS["createStreamWithFunds"] = 3] = "createStreamWithFunds";
    MSP_ACTIONS[MSP_ACTIONS["addFunds"] = 4] = "addFunds";
    MSP_ACTIONS[MSP_ACTIONS["withdraw"] = 5] = "withdraw";
    MSP_ACTIONS[MSP_ACTIONS["pauseStream"] = 6] = "pauseStream";
    MSP_ACTIONS[MSP_ACTIONS["resumeStream"] = 7] = "resumeStream";
    MSP_ACTIONS[MSP_ACTIONS["proposeUpdate"] = 8] = "proposeUpdate";
    MSP_ACTIONS[MSP_ACTIONS["answerUpdate"] = 9] = "answerUpdate";
    MSP_ACTIONS[MSP_ACTIONS["createTreasury"] = 10] = "createTreasury";
    MSP_ACTIONS[MSP_ACTIONS["closeStream"] = 11] = "closeStream";
    MSP_ACTIONS[MSP_ACTIONS["closeTreasury"] = 12] = "closeTreasury";
    MSP_ACTIONS[MSP_ACTIONS["transferStream"] = 13] = "transferStream";
    MSP_ACTIONS[MSP_ACTIONS["treasuryWithdraw"] = 14] = "treasuryWithdraw";
})(MSP_ACTIONS = exports.MSP_ACTIONS || (exports.MSP_ACTIONS = {}));
var VestingTreasuryActivityAction;
(function (VestingTreasuryActivityAction) {
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["TreasuryCreate"] = 0] = "TreasuryCreate";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["TreasuryModify"] = 1] = "TreasuryModify";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["TreasuryAddFunds"] = 2] = "TreasuryAddFunds";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["TreasuryWithdraw"] = 3] = "TreasuryWithdraw";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamCreate"] = 4] = "StreamCreate";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamPause"] = 5] = "StreamPause";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamResume"] = 6] = "StreamResume";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamClose"] = 7] = "StreamClose";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamAllocateFunds"] = 8] = "StreamAllocateFunds";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["StreamWithdraw"] = 9] = "StreamWithdraw";
    VestingTreasuryActivityAction[VestingTreasuryActivityAction["TreasuryRefresh"] = 10] = "TreasuryRefresh";
})(VestingTreasuryActivityAction = exports.VestingTreasuryActivityAction || (exports.VestingTreasuryActivityAction = {}));
/**
 * Treasury type
 */
var TreasuryType;
(function (TreasuryType) {
    TreasuryType[TreasuryType["Open"] = 0] = "Open";
    TreasuryType[TreasuryType["Lock"] = 1] = "Lock";
})(TreasuryType = exports.TreasuryType || (exports.TreasuryType = {}));
/**
 * Stream states
 */
var STREAM_STATUS;
(function (STREAM_STATUS) {
    STREAM_STATUS[STREAM_STATUS["Schedule"] = 1] = "Schedule";
    STREAM_STATUS[STREAM_STATUS["Running"] = 2] = "Running";
    STREAM_STATUS[STREAM_STATUS["Paused"] = 3] = "Paused";
})(STREAM_STATUS = exports.STREAM_STATUS || (exports.STREAM_STATUS = {}));
/**
 * Allocation type
 */
var AllocationType;
(function (AllocationType) {
    AllocationType[AllocationType["All"] = 0] = "All";
    AllocationType[AllocationType["Specific"] = 1] = "Specific";
    AllocationType[AllocationType["None"] = 2] = "None";
})(AllocationType = exports.AllocationType || (exports.AllocationType = {}));
// Primary category of tresury accounts
var Category;
(function (Category) {
    Category[Category["default"] = 0] = "default";
    Category[Category["vesting"] = 1] = "vesting";
})(Category = exports.Category || (exports.Category = {}));
// Sub categories of vesting accounts
var SubCategory;
(function (SubCategory) {
    SubCategory[SubCategory["default"] = 0] = "default";
    SubCategory[SubCategory["advisor"] = 1] = "advisor";
    SubCategory[SubCategory["development"] = 2] = "development";
    SubCategory[SubCategory["foundation"] = 3] = "foundation";
    SubCategory[SubCategory["investor"] = 4] = "investor";
    SubCategory[SubCategory["marketing"] = 5] = "marketing";
    SubCategory[SubCategory["partnership"] = 6] = "partnership";
    SubCategory[SubCategory["seed"] = 7] = "seed";
    SubCategory[SubCategory["team"] = 8] = "team";
    SubCategory[SubCategory["community"] = 9] = "community";
})(SubCategory = exports.SubCategory || (exports.SubCategory = {}));
// Preferred Time Unit
var TimeUnit;
(function (TimeUnit) {
    TimeUnit[TimeUnit["Second"] = 0] = "Second";
    TimeUnit[TimeUnit["Minute"] = 60] = "Minute";
    TimeUnit[TimeUnit["Hour"] = 3600] = "Hour";
    TimeUnit[TimeUnit["Day"] = 86400] = "Day";
    TimeUnit[TimeUnit["Week"] = 604800] = "Week";
    TimeUnit[TimeUnit["Month"] = 2629750] = "Month";
    TimeUnit[TimeUnit["Year"] = 31557000] = "Year";
})(TimeUnit = exports.TimeUnit || (exports.TimeUnit = {}));
